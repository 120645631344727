<div class="alerts fixed bottom-0 left-0 w-full pointer-events-none">
  <div class="container mx-auto max-w-4xl">
    <div class="flex justify-center p-8">
      <app-alert
        *ngFor="
          let alert of alerts | slice: 0:1;
          let index = index;
          trackBy: tracking
        "
        [alert]="alert"
        class="pointer-events-auto"
        (onDismiss)="dismiss(index, alert)"
        (onPrompt)="respond(alert, true)"
        [type]="alert.type"
      >
      </app-alert>
    </div>
  </div>
</div>
