import { ImageComponent } from "../../media/image/image.component";
import { NgClass, NgIf, NgTemplateOutlet } from "@angular/common";
import { RouterLink } from "@angular/router";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-profile-image",
  templateUrl: "./profile-image.component.html",
  styleUrls: ["./profile-image.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, RouterLink, NgTemplateOutlet, ImageComponent],
})
export class ProfileImageComponent implements OnInit {
  /**
   * The display state of the border.
   */
  @Input() border = false;

  /**
   * Size of the image to use in component.
   */
  @Input() imageSize = "thumbnail";

  /**
   * The img element of the component.
   */
  @ViewChild("img") img?: ElementRef;

  /**
   * The loaded event of the image.
   */
  @Output() loaded: EventEmitter<void> = new EventEmitter();

  /**
   * The model of the image.
   */
  _model: any;

  @Input()
  get model() {
    return this._model;
  }

  set model(value: any) {
    this._model = value;
    this.setProperties();
  }

  /**
   * The image of the component.
   */
  profileImage: any;

  /**
   * The route to the model profile.
   */
  route?: any;

  /**
   * If the component should link to the model profile.
   */
  @Input() shouldLink = false;

  /**
   * The sizes of the image.
   */
  sizes = ["(min-width: 1280px) 25vw", "(min-width: 640px) 51vw", "2vw"].join(
    ", "
  );

  /**
   * The source of the image.
   */
  src1?: string;

  /**
   * The source of the image.
   */
  src2?: string | void;

  /**
   * The source of the image.
   */
  srcset?: string | void;

  /**
   * The type of model of the image.
   */
  @Input() type: "brands" | "users" = "users";

  /**
   * The upload event.
   */
  @Output() upload: EventEmitter<void> = new EventEmitter();

  /**
   * The upload active state.
   */
  @Input() uploadActive = false;

  /**
   * The uploadable state of the component.
   */
  @Input() uploadable = false;

  /**
   * A url to use for the image.
   */
  @Input() url?: string;

  /**
   * Width of the image component.
   */
  @Input() width: any = "75";

  /**
   * Create a new instance of the component.
   */
  constructor(private cd: ChangeDetectorRef) {}

  /**
   * On component init.
   */
  ngOnInit(): void {
    this.setProperties();
  }

  /**
   * Handle the image loaded event.
   */
  onLoaded(event: any) {
    this.loaded.emit(event);
  }

  /**
   * Set the profile image property of the component.
   */
  setProfileImage() {
    if (!this.model?.media) {
      return;
    }

    this.profileImage = this.model.media.find((media: any) => {
      return media.type === "profile";
    });
  }

  /**
   * Set the properties of the component.
   */
  setProperties() {
    this.setProfileImage();
    this.setRoute();
    this.setSource();
    this.setSrcset();
    this.cd.detectChanges();
  }

  /**
   * Set the route of the component.
   */
  setRoute() {
    if (!this.model?.username || !this.shouldLink) {
      return;
    }

    this.route = ["/", this.model?.username];
  }

  /**
   * Set source one of the image.
   */
  setSource() {
    if (this.url) {
      this.src1 = this.url;
      return;
    }

    if (this.profileImage) {
      this.src1 = this.profileImage?.sizes
        ? this.profileImage?.sizes?.[this.imageSize]
        : this.profileImage?.url;
    }
  }

  /**
   * Set the srcset of the image.
   */
  setSrcset() {
    if (
      this.url ||
      !this.profileImage?.sizes ||
      this.imageSize === "thumbnail"
    ) {
      return;
    }

    const sizes = [];

    if (this.profileImage?.sizes?.large) {
      sizes.push(this.profileImage.sizes.large + " 1920w");
    }

    if (this.profileImage?.sizes?.medium) {
      sizes.push(this.profileImage.sizes.medium + " 1280w");
    }

    if (this.profileImage?.sizes?.small) {
      sizes.push(this.profileImage.sizes.small + " 640w");
    }

    if (sizes.length) {
      this.srcset = sizes.join(",");
    }
  }
}
