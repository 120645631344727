import { LoaderDirective } from '../../../directives/loader/loader';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Params, QueryParamsHandling, RouterLink } from '@angular/router';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LoaderDirective, NgClass, NgTemplateOutlet, RouterLink],
})
export class ButtonComponent implements OnInit {
  /**
   * The border radius of the button
   */
  @Input() borderRadius: "none" | "rounded" | "rounded-full" = "rounded";

  /**
   * The classes of the button.
   */
  buttonClasses: { [key: string]: boolean } = {};

  /**
   * The color of the button.
   */
  _color:
    | "clear"
    | "danger"
    | "light"
    | "primary"
    | "reverb-orange"
    | "secondary" = "primary";

  @Input()
  get color() {
    return this._color;
  }

  set color(value) {
    this._color = value;
    this.setButtonClasses();
  }

  /**
   * The disabled state of the button.
   */
  @Input() disabled: boolean | undefined = false;

  /**
   * The expand state of the button.
   */
  @Input() expand: "" | "block" = "";

  /**
   * The fill of the button.
   */
  _fill: "solid" | "outline" | "transparent" = "solid";
  @Input()
  get fill() {
    return this._fill;
  }

  set fill(value) {
    this._fill = value;
    this.setButtonClasses();
  }

  /**
   * The hidden state of the button.
   */
  @HostBinding("class.hidden")
  @Input()
  hidden: boolean = false;

  /**
   * Display the button as a block element.
   */
  @HostBinding("class.block") get hostBlock() {
    return this.hidden !== true && this.expand === "block";
  }

  /**
   * Display the button as an inline block element.
   */
  @HostBinding("class.inline-block") get hostInlineBlock() {
    return this.hidden !== true && this.expand === "";
  }

  /**
   * The href of the button.
   */
  @Input() href?: string;

  /**
   * If the button only has an icon.
   */
  @Input() iconOnly: boolean = false;

  /**
   * The id of the button.
   */
  @Input() id?: string;

  /**
   * The loading state of the button.
   */
  _loading: boolean = false;
  @Input()
  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;

    if (this.fill === "solid" && this.color === "primary") {
      this.loaderColor = "light";
    }
  }

  /**
   * The loading state of the button.
   */
  @Input() loaderColor: "dark" | "light" = "light";

  /**
   * The click event emitter.
   */
  @Output() onClick: EventEmitter<Event> = new EventEmitter();

  /**
   * The query params of the button.
   */
  @Input() queryParams?: Params | null | undefined;

  /**
   * How to handle query parameters in a router link
   */
  @Input() queryParamsHandling: QueryParamsHandling | null = null;

  /**
   * The router link of the button.
   */
  @Input() route: string | string[] | null = null;

  /**
   * The size of the button.
   */
  @Input() size: "medium" | "large" | "small" | "x-small" = "medium";

  /**
   * The target of the button link.
   */
  @Input() target: string = "";

  /**
   * The type of the button.
   */
  @Input() type: "" | "button" | "submit" = "button";

  /**
   * If the text of the button should be uppercased.
   */
  @Input() uppercase: boolean = false;

  /**
   * Create a new instance of the component.
   */
  constructor(public elementRef: ElementRef) {}

  /**
   * On component init.
   */
  ngOnInit() {
    this.setButtonClasses();
  }

  /**
   * Returns the styling classes for the button.
   */
  getColorClasses(): string {
    if (this.color === "danger" && this.fill === "solid") {
      return "bg-red-600 active:bg-red-700 focus:bg-red-700 hover:bg-red-700 text-white hover:text-white";
    }

    if (this.color === "danger" && this.fill === "outline") {
      return "bg-transparent active:bg-red-600 focus:active:bg-red-600 hover:bg-red-600 text-red-600 active:text-white hover:text-white ring ring-inset ring-1 ring-red-600";
    }

    if (this.color === "danger" && this.fill === "transparent") {
      return "bg-transparent active:bg-red-600 hover:bg-red-600 text-black active:text-white hover:text-white";
    }

    if (this.color === "light" && this.fill === "transparent") {
      return "bg-transparent text-white active:text-neutral-50 hover:text-neutral-50";
    }

    if (this.color === "clear") {
      return "bg-transparent text-black active:text-neutral-800 hover:text-neutral-800";
    }

    if (this.color === "light" && this.fill === "solid") {
      return "bg-neutral-200 text-neutral-700 active:text-neutral-900 hover:text-neutral-900";
    }

    if (this.color === "light" && this.fill === "outline") {
      return "bg-white active:bg-neutral-200 focus:bg-neutral-200 hover:bg-neutral-200 text-neutral-700 active:text-neutral-900 hover:text-neutral-900 ring ring-inset ring-1 ring-gray-200";
    }

    if (this.color === "reverb-orange" && this.fill === "solid") {
      return "bg-reverb-orange active:bg-reverb-orange-400 focus:bg-reverb-orange-400 hover:bg-reverb-orange-400 text-white hover:text-white";
    }

    if (this.color === "reverb-orange" && this.fill === "outline") {
      return "bg-white active:bg-reverb-orange focus:bg-reverb-orange hover:bg-reverb-orange tex-reverb-orange hover:text-white ring ring-inset ring-1 ring-reverb-orange";
    }

    if (this.color === "secondary" && this.fill === "solid") {
      return "bg-neutral-700 hover:bg-neutral-700 text-white hover:text-white";
    }

    if (this.color === "secondary" && this.fill === "outline") {
      return "bg-white active:bg-neutral-700 hover:bg-neutral-700 text-neutral-700 active:text-white hover:text-white ring ring-inset ring-1 ring-gray-700";
    }

    if (this.color === "secondary" && this.fill === "transparent") {
      return "bg-transparent active:bg-neutral-700 hover:bg-neutral-700 text-neutral-700 active:text-white hover:text-white";
    }

    if (this.color === "primary" && this.fill === "outline") {
      return "bg-white active:bg-black hover:bg-black text-black active:text-white hover:text-white ring ring-inset ring-1 ring-black";
    }

    return "bg-black text-white hover:text-white hover:bg-neutral-900 active:bg-neutral-900";
  }

  /**
   * The button click handler.
   */
  handleClick($event: Event) {
    this.onClick.next($event);
  }

  /**
   * Set the classes of the button.
   */
  setButtonClasses() {
    this.buttonClasses = {
      "text-center font-medium cursor-pointer disabled:opacity-80 m-0 h-auto focus:outline-none appearance-none":
        true,
      "leading-snug": !this.iconOnly,
      "leading-none": this.iconOnly,
      "w-full": this.expand === "block",
      "text-sm": this.size === "small" || this.size === "x-small",
      "px-3": this.size === "x-small" && this.iconOnly === false,
      "p-1": this.size === "x-small",
      "px-4": this.size === "small" && this.iconOnly === false,
      "p-2": this.size === "small",
      "px-6": this.size === "medium" && this.iconOnly === false,
      "p-3 text-base": this.size === "medium",
      "px-8": this.size === "large" && this.iconOnly === false,
      "p-4 text-lg": this.size === "large",
      rounded: this.borderRadius === "rounded",
      "rounded-full": this.borderRadius === "rounded-full",
      "rounded-none": this.borderRadius === "none",
      uppercase: this.uppercase,
      [this.getColorClasses()]: true,
    };
  }
}
