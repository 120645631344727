import { APP_INITIALIZER } from '@angular/core';
import { ChatService } from 'rigshare-common';
import { SystemService } from 'rigshare-common';
import { AnalyticsService } from 'rigshare-common';
import { UserAuthorizationService } from 'rigshare-common';

export function init() {
  return () => {};
}

export function initProvider() {
  return {
    provide: APP_INITIALIZER,
    useFactory: init,
    deps: [
      AnalyticsService,
      UserAuthorizationService,
      ChatService,
      SystemService,
    ],
    multi: true,
  };
}
