<style>
  :host {
    -webkit-appearance: none;
    appearance: none;
  }
</style>
<ng-template #buttonContent>
  <ng-content></ng-content>
</ng-template>
<a
  *ngIf="href"
  class="inline-block"
  [ngClass]="buttonClasses"
  (click)="handleClick($event)"
  [href]="href"
  [id]="id"
  appLoader
  [loading]="loading"
  [loaderColor]="loaderColor"
  [target]="target"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</a>
<a
  *ngIf="route"
  class="block"
  [ngClass]="buttonClasses"
  (click)="handleClick($event)"
  [id]="id"
  [routerLink]="route"
  [queryParams]="queryParams"
  appLoader
  [loading]="loading"
  [loaderColor]="loaderColor"
  [target]="target"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</a>
<button
  *ngIf="!href && !route"
  [ngClass]="buttonClasses"
  (click)="handleClick($event)"
  [disabled]="disabled"
  [id]="id"
  [type]="type"
  appLoader
  [loading]="loading"
  [loaderColor]="loaderColor"
>
  <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
</button>
