import { AlertsComponent } from './components/layout/alerts/alerts.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { HeadDirective } from './directives/head/head.directive';
import { StickyTopDirective } from './directives/sticky-top/sticky-top';
import { environment } from '../environments/environment';
import { NgIf } from '@angular/common';
import { Component, HostBinding } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  standalone: true,
  imports: [
    AlertsComponent,
    FooterComponent,
    HeaderComponent,
    HeadDirective,
    NgIf,
    RouterOutlet,
    StickyTopDirective,
  ],
})
export class AppComponent {
  /**
   * The classes of the component.
   */
  @HostBinding("class") classes = "flex justify-between flex-col";

  /**
   * Check if the current environment is production.
   */
  get production(): boolean {
    return environment.production === true;
  }

  constructor(private meta: Meta) {
    if (!this.production) {
      this.meta.addTag({
        name: "robots",
        content: "noindex, nofollow",
      });
    }
  }
}
