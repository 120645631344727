export class AlertModel {
  /**
   * An alert that is of the type "error".
   */
  static TYPE_ERROR = "danger";

  /**
   * An alert that is of the type "info".
   */
  static TYPE_INFO = "info";

  /**
   * An alert that is of the type "success".
   */
  static TYPE_SUCCESS = "success";

  /**
   * An alert that is of the type "warning".
   */
  static TYPE_WARNING = "warning";

  /**
   * The action that should be perfomed when the alert is displayed.
   */
  action?: string = "";

  /**
   * The activated state of the alert.
   */
  activated?: boolean;

  /**
   * The dismissable state of the component.
   */
  dismiss: boolean | number = 2000;

  /**
   * The identifier of the alert.
   */
  id: string;

  /**
   * The link of the alert.
   */
  link: { url?: string; text?: string } = {};

  /**
   * The message to display in the alert.
   */
  message?: string = "";

  /**
   * The type of alert.
   */
  type: "danger" | "info" | "success" | "warning" | "" = "";

  /**
   * Create a new instance of the model.
   */
  constructor(attributes: any = {}) {
    this.id = new Date().getTime() + Math.random().toString(11).substr(2);

    for (let key in attributes) {
      if (this.hasOwnProperty(key)) {
        (this as any)[key] = attributes[key];
      }
    }
  }

  /**
   * Set the value of the dismiss property.
   */
  setDismiss(value: boolean | number) {
    this.dismiss = value;
  }
}
