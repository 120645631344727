<style>
  :host {
    position: relative;
  }

  :host-context(app-input) :host,
  /* :host-context(app-input) :host > div, */
  :host-context(app-input) :host ::ng-deep app-button,
  :host-context(app-input) :host ::ng-deep app-button button,
  :host-context(input-group-help) :host,
  :host-context(input-group-help) :host > span,
  :host-context(input-group-help) :host ::ng-deep app-button,
  :host-context(input-group-help) :host ::ng-deep app-button button {
    height: 100% !important;
  }

  :host-context(app-input) ::ng-deep app-button button,
  :host-context(input-group-help) ::ng-deep app-button button {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
</style>
<app-button
  #trigger
  [color]="buttonColor"
  [disabled]="disabled"
  [expand]="fullWidth ? 'block' : buttonExpand"
  [fill]="buttonFill"
  [hidden]="!displayButton"
  [iconOnly]="buttonIconOnly"
  (onClick)="setOpened(!opened)"
  [size]="buttonSize"
  type="button"
>
  <ng-content select="[text]"></ng-content>
  <span *ngIf="displayToggle" class="inline-block align-middle">
    <svg
      class="text-base w-[1em] text-current"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        d="M98 190.06l139.78 163.12a24 24 0 0036.44 0L414 190.06c13.34-15.57 2.28-39.62-18.22-39.62h-279.6c-20.5 0-31.56 24.05-18.18 39.62z"
        fill="currentColor"
      />
    </svg>
  </span>
</app-button>

<div
  #itemContainer
  class="absolute z-30 flex flex-col bg-white shadow-md border border-gray-100 rounded overflow-hidden"
  [ngClass]="{
    hidden: !opened,
    'left-0': direction === 'left',
    'right-0': direction === 'right',
    'max-w-xs': !fullWidth,
    'min-w-[160px]': !fullWidth && size === 'small',
    'min-w-[320px]': !fullWidth && size === 'medium',
    'min-w-[480px]': !fullWidth && size === 'large',
    'w-full': fullWidth,
    'flex flex-col divide-y': displayDividers,
    'mt-1': true,
    'mr-1': direction === 'right',
    'lr-1': direction === 'left'
  }"
  [ngStyle]="offsetStyle"
>
  <ng-content select="[items]"></ng-content>
</div>
