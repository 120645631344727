import { AuthenticationService } from '../services/authentication/authentication.service';
import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
class AuthResolveGuard {
  /**
   * Create a new instance.
   */
  constructor(public auth: AuthenticationService) {}

  /**
   * Determine if the user can activate a route.
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.guard();
  }

  /**
   * Determine if the user can activate children of a route.
   */
  canActivateChild(): Promise<boolean> {
    return this.guard();
  }

  /**
   * The method to apply to guard.
   */
  async guard(): Promise<boolean> {
    if (this.auth.user()) {
      return true;
    }

    try {
      await this.auth.check();
    } catch (error) {
    } finally {
      return true;
    }
  }
}

export const authResolveGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  return inject(AuthResolveGuard).canActivate(route, state);
};
