<label class="block">
  <div *ngIf="label" class="text-sm font-medium mb-1 block">
    {{ label }}
  </div>

  <div class="flex group overflow-hidden_" [ngClass]="classes">
    <div class="empty:hidden border-r border-gray-300 p-px">
      <ng-content select="[prepend]"></ng-content>
    </div>

    <div class="grow rounded overflow-hidden">
      <ng-content></ng-content>
    </div>

    <div class="empty:hidden border-l border-gray-300 p-px">
      <ng-content select="[append]"></ng-content>
    </div>
  </div>

  <div *ngIf="errors?.length" class="mt-2 text-sm text-red-600">
    <span *ngFor="let error of errors | slice: 0:1">{{ error }}</span>
  </div>
</label>
