import { Model } from './model';
import { formatDistanceToNow } from 'date-fns';

export class NotificationModel extends Model {
  /**
   * The created at timestamp.
   */
  created_at?: string;

  /**
   * The data of the notification.
   */
  _data: any;

  get data() {
    return this._data;
  }

  set data(value) {
    this._data = typeof value === "string" ? JSON.parse(value) : value;
  }

  /**
   * The id of the model.
   */
  id?: string;

  /**
   * The id of the notifiable.
   */
  notifiable_id?: number;

  /**
   * The type of the notifiable.
   */
  notifiable_type?: string;

  /**
   * The read state of the model.
   */
  read_at?: string;

  /**
   * The route of the model.
   */
  route?: any[];

  /**
   * The routes associated with differnt types of notifications.
   */
  routes: any = {
    FollowRequest: "[sender_username]",
    FollowRequestAccepted: "[sender_username]",
    NewComment: "[root_type]:[root_id]",
    NewFollower: "[sender_username]",
    NewLike: "[root_type]:[root_id]",
    NewMention: "[root_type]:[root_id]",
    NewReview: "one-liners:[review_id]",
    NewShare: "posts:[post_id]",
  };

  /**
   * The sender of the model.
   */
  sender?: any;

  /**
   * The time ago string.
   */
  get timeAgo(): any {
    if (this.created_at) {
      return formatDistanceToNow(new Date(this.created_at));
    }
  }

  /**
   * The type of notification.
   */
  _type?: string;

  get type(): any {
    return this._type;
  }

  set type(type) {
    type = type.split("\\").pop();

    this._type = type;
  }

  /**
   * The user of the model.
   */
  user: any;

  /**
   * Handle model booted.
   */
  booted() {
    setTimeout(() => (this.route = this.getRoute()));
  }

  /**
   * Retrieve the route for the notification.
   */
  getRoute(): any {
    if (this.type) {
      let route;

      if ((route = this.routes[this.type])) {
        route = route.split(":");

        route = route.map((value: string) => {
          if (value === "[recipient_username]" && this.user) {
            return this.user.username;
          }

          if (value === "[sender_username]" && this.sender) {
            return this.sender?.username;
          }

          if (this.data) {
            if (value === "[root_type]") {
              if (this.data.root_type === "user_products") {
                if (this.data.root_user_id) {
                  return [
                    this.user?.id === this.data.root_user_id
                      ? `${this.user?.username}`
                      : `${this.sender?.username}`,
                    "gear",
                  ];
                }

                return [`${this.user.username}`, "gear"];
              }

              if (this.data.root_type === "rigs") {
                if (this.data.root_user_id) {
                  return [
                    this.user.id === this.data.root_user_id
                      ? `${this.user.username}`
                      : `${this.sender?.username}`,
                    "rigs",
                  ];
                }

                return [`${this.user.username}`, "gear"];
              }

              return this.data.root_type;
            }

            if (value === "[root_id]") {
              return this.data.root_id;
            }

            let matches;

            if ((matches = value.match(/\[(.*?)\]/)) && matches.length) {
              const id = this.data[matches[1]];
              return typeof id !== "undefined" ? id : matches[1];
            }
          }

          return value;
        });

        return ["/"].concat(...route);
      }

      return null;
    }
  }
}
