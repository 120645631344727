import { MessagesDropdownComponent } from "../../messages/messages-dropdown/messages-dropdown.component";
import { NgFor, NgIf } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { AuthenticationService } from "rigshare-common";
import { fromEvent, Subscription } from "rxjs";
import {
  ChatService,
  MessageGroupService,
  NotificationService,
  ViewPortService,
} from "rigshare-common";
import {
  BadgeComponent,
  DropdownDividerComponent,
  DropdownItemComponent,
  DropdownHeaderComponent,
  ProfileImageComponent,
  DropdownComponent,
  NotificationsDropdownComponent,
  ButtonComponent,
} from "rigshare-common";

@Component({
  selector: "app-header-menu",
  templateUrl: "./header-menu.component.html",
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    ButtonComponent,
    MessagesDropdownComponent,
    NotificationsDropdownComponent,
    DropdownComponent,
    ProfileImageComponent,
    DropdownHeaderComponent,
    DropdownItemComponent,
    DropdownDividerComponent,
    NgFor,
    BadgeComponent,
  ],
})
export class HeaderMenuComponent implements OnInit {
  /**
   * Get the message unread count.
   */
  get messageUnreadCount(): number | void {
    if (this.auth.user()) {
      return this.messageGroupService.unreadCount;
    }
  }

  /**
   * Get the notification unread count.
   */
  get notificationUnreadCount(): number {
    return this.notificationService.unreadCount;
  }

  /**
   * The opened state of the menu.
   */
  opened: boolean = false;

  /**
   * The subscriptions of the component.
   */
  subs: Map<string, Subscription> = new Map();

  /**
   * Create a new instance of the component.
   */
  constructor(
    public auth: AuthenticationService,
    public chatService: ChatService,
    public messageGroupService: MessageGroupService,
    protected notificationService: NotificationService,
    protected router: Router,
    public viewportService: ViewPortService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.close();
  }

  /**
   * Close the menu.
   */
  close() {
    this.opened = false;
    if (typeof window !== "undefined") {
      document.body.style.overflow = "";
    }

    this.subs.get("keyboard")?.unsubscribe();
    this.subs.delete("keyboard");
  }

  /**
   * Logout the user.
   */
  async logout(event: Event): Promise<void> {
    event.preventDefault();
    await this.auth.logout();
    this.router.navigate(["/"]);
  }

  /**
   * Open the menu.
   */
  open() {
    this.opened = true;
    if (typeof window !== "undefined") {
      document.body.style.overflow = "hidden";

      this.subs.set(
        "keyboard",
        fromEvent(document, "keyup").subscribe({
          next: (e: Event) => {
            if ((e as KeyboardEvent).code === "Esc") {
              this.close();
            }
          },
        })
      );
    }
  }

  /**
   * Open the chat.
   */
  openChat(event: any): void {
    event.preventDefault();
    this.chatService.open();
  }

  /**
   * Report a problem.
   */
  reportProblem(event: any): void {
    event.preventDefault();
    this.chatService.reportProblem();
  }

  /**
   * Toggle the opened state of the menu.
   */
  toggle() {
    if (this.opened) {
      this.close();
    } else {
      this.open();
    }
  }
}
