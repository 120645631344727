import { AuthenticationService } from './authentication/authentication.service';
import { CloudFlareAnalytics } from './cloudflare-analytics';
import { TrackingService } from './tracking.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';

import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from "@angular/router";
import {
  Injectable,
  Inject,
  PLATFORM_ID,
  OnDestroy,
  Injector,
} from "@angular/core";

@Injectable()
export class AnalyticsService implements OnDestroy {
  /**
   * The initialized state of the service.
   */
  initialized = false;

  /**
   * The router instance of the serivce.
   */
  public get router(): Router {
    return this.injector.get(Router);
  }

  /**
   * The subscriptions of the service.
   */
  subs: any = {};

  /**
   * Create a new instance of the service.
   */
  constructor(
    private auth: AuthenticationService,
    private injector: Injector,
    private cloudFlareAnalytics: CloudFlareAnalytics,
    private location: Location,
    private title: Title,
    private tracking: TrackingService,
    @Inject(PLATFORM_ID) protected platformId: Object
  ) {
    this.init();
  }

  /**
   * On service destroy.
   */
  ngOnDestroy(): void {
    Object.keys(this.subs).forEach((k) => this.subs[k]?.unsubscribe());
  }

  /**
   * Initialize the service.
   */
  async init(): Promise<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      if (!this.initialized) {
        await this.cloudFlareAnalytics.init();
        this.initialized = true;
        this.trackPageViews();
      }

      return true;
    }

    return false;
  }

  /**
   * Track a screen view.
   */
  async page(
    title: string = "",
    location: string = "",
    path: string = "",
    properties: any = {}
  ): Promise<void> {
    const initialized = await this.init();
    if (!initialized) {
      return;
    }

    // this.googleAnalytics.page(title, path);

    this.tracking.page({
      title: title,
      location: location,
      path: path,
      ...properties,
    });
  }

  /**
   * Get a pages set analytics key.
   */
  pageAnalyticsKey(route: ActivatedRoute): boolean {
    if (!route.component || typeof route?.component !== "function") {
      return false;
    }

    return new (<any>route.component)().analyticsKey || null;
  }

  /**
   * Check if a page should be automatically tracked.
   */
  shouldTrackPageView(route: ActivatedRoute): boolean {
    const tree = this.router.parseUrl(this.router.url);

    if (
      !route.component ||
      typeof route.component !== "function" ||
      tree.root.children.modal ||
      typeof new (<any>route.component)().trackView === "function"
    ) {
      return false;
    }

    return true;
  }

  /**
   * Track an event.
   */
  async track(name: string, data: any = {}): Promise<void> {
    const initialized = await this.init();
    if (!initialized) {
      return;
    }

    this.cloudFlareAnalytics.event(name, data);

    this.tracking.event({ name: name, data: data });
  }

  /**
   * Track page views.
   */
  trackPageViews(): void {
    this.subs["router"] = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.router.routerState.snapshot.root))
      .subscribe((route) => {
        let params: any = {};
        let child: any;
        let lastRoute: ActivatedRouteSnapshot | null = route;

        do {
          params = Object.assign(params, route.params);
          lastRoute = lastRoute.firstChild || null;

          if (lastRoute) {
            child = lastRoute;
          }
        } while (lastRoute);

        if (child && this.shouldTrackPageView(child)) {
          this.page(
            this.title.getTitle(),
            window.location.href,
            this.location.path(),
            {
              brand_id: params["brandId"] ? params["brandId"] : null,
              key: this.pageAnalyticsKey(child),
              product_id: params["productId"] ? params["productId"] : null,
              user_id: this.auth.user() ? this.auth.user().id : null,
            }
          );
        }
      });
  }
}
