import * as Types from '../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UnreadNotificationsCountQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UnreadNotificationsCountQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationConnection', pageInfo: { __typename?: 'PageInfo', total: number } } };

export const UnreadNotificationsCountDocument = gql`
    query UnreadNotificationsCount {
  notifications(first: 1, unread: true) {
    pageInfo {
      total
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnreadNotificationsCountGQL extends Apollo.Query<UnreadNotificationsCountQuery, UnreadNotificationsCountQueryVariables> {
    document = UnreadNotificationsCountDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }