export class PolicyModel {
  /**
   * Name of the policy.
   */
  name?: string;

  /**
   * The objects of the defined policy.
   */
  objects: any[] = [];

  /**
   * Create a new policy model.
   */
  constructor(policy: any) {
    Object.assign(this, policy);
  }
}
