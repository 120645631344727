import {
  Directive,
  ElementRef,
  Input,
  Renderer2
  } from '@angular/core';

@Directive({
  selector: "[appLoader]",
  standalone: true,
})
export class LoaderDirective {
  /**
   * The color of the loading icon.
   */
  @Input() loaderColor: "dark" | "light" = "dark";

  /**
   * The loading state of the directive.
   */
  _loading: boolean = false;

  @Input()
  get loading(): boolean {
    return this._loading;
  }

  set loading(value) {
    this._loading = value;
    this.toggleLoading();
  }

  /**
   * The text of the component.
   */
  text?: string;

  /**
   * The width of the component.
   */
  @Input() loaderWidth = 16;

  /**
   * Create a new instance of the directive.
   */
  constructor(private element: ElementRef, private renderer: Renderer2) {}

  /**
   * The laoder html of he component.
   */
  loader(): Element {
    const div = this.renderer.createElement("div");

    this.renderer.setProperty(div, "innerHTML", this.svg(this.loaderColor));
    this.renderer.setStyle(div, "width", `${this.loaderWidth}px`);
    this.renderer.setStyle(div, "height", `${this.loaderWidth}px`);
    this.renderer.setStyle(div, "display", "inline-block");
    this.renderer.setStyle(div, "vertical-align", "middle");

    return div;
  }

  /**
   * Return the svg for the dark loader.
   */
  svg(color: string) {
    const hex = color === "dark" ? "#000000" : "#FFFFFF";

    return `
      <svg  class="w-full h-full" width="100%" height="100%" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="${hex}">
          <g fill="none" fill-rule="evenodd" stroke-width="2">
            <circle cx="22" cy="22" r="1">
                <animate attributeName="r"
                    begin="0s" dur="1.8s"
                    values="1; 20"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.165, 0.84, 0.44, 1"
                    repeatCount="indefinite" />
                <animate attributeName="stroke-opacity"
                    begin="0s" dur="1.8s"
                    values="1; 0"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.3, 0.61, 0.355, 1"
                    repeatCount="indefinite" />
            </circle>
            <circle cx="22" cy="22" r="1">
                <animate attributeName="r"
                    begin="-0.9s" dur="1.8s"
                    values="1; 20"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.165, 0.84, 0.44, 1"
                    repeatCount="indefinite" />
                <animate attributeName="stroke-opacity"
                    begin="-0.9s" dur="1.8s"
                    values="1; 0"
                    calcMode="spline"
                    keyTimes="0; 1"
                    keySplines="0.3, 0.61, 0.355, 1"
                    repeatCount="indefinite" />
            </circle>
        </g>
    </svg>
  `;
  }

  /**
   * Toggles the loading state of the component.
   */
  toggleLoading(): void {
    if (this.loading) {
      this.text = this.element.nativeElement.innerHTML;
      this.renderer.setProperty(this.element.nativeElement, "innerHTML", "");
      this.renderer.appendChild(this.element.nativeElement, this.loader());
      this.renderer.setAttribute(
        this.element.nativeElement,
        "disabled",
        "true"
      );

      this.renderer.setAttribute(
        this.element.nativeElement,
        "disabled",
        "true"
      );
    } else if (this.text) {
      this.renderer.setProperty(
        this.element.nativeElement,
        "innerHTML",
        this.text
      );

      this.renderer.removeAttribute(this.element.nativeElement, "disabled");
    }
  }
}
