import { CommonConfig } from '../../../common-config';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgClass } from '@angular/common';
import { InputContainerComponent } from '../input-container/input-container.component';

@Component({
    selector: "app-input",
    templateUrl: "./input.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => InputComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        InputContainerComponent,
        FormsModule,
        NgClass,
    ],
})
export class InputComponent implements ControlValueAccessor, OnInit {
  @Input() autocapitalize?: string | boolean = "";

  @Input() autocomplete?: string | boolean = "off";

  /**
   * The css classes of the input element.
   */
  classes: {
    group: { [klass: string]: boolean };
    input: { [klass: string]: boolean };
  } = {
    group: {},
    input: {},
  };

  /**
   * Set the size of the input element.
   */
  _controlSize: "small" | "medium" | "large" = "medium";

  @Input()
  get controlSize() {
    return this._controlSize;
  }

  set controlSize(value) {
    this._controlSize = value;
    this.setClasses();
  }
  @Input() disabled: boolean = false;
  @ViewChild("element", { static: true })
  element?: ElementRef<HTMLInputElement>;
  @Input() enterkeyhint: string = "";
  @Input() errors?: string[];
  @Input() id: string | null = null;
  @Input() label: string = "";
  @Output() onBlur: EventEmitter<Event> = new EventEmitter();
  onChange = (value: string) => {};
  @Output() onFocus: EventEmitter<Event> = new EventEmitter();
  @Output() onKeydown: EventEmitter<KeyboardEvent> = new EventEmitter();
  @Output() onKeyup: EventEmitter<KeyboardEvent> = new EventEmitter();
  onTouched = () => {};
  @Input() name: string = "";
  @Input() placeholder: string = "";
  @Input() readonly: boolean | string = "";
  @Input() required: boolean | string = "false";
  @Input() type: string = "text";
  value: any;

  /**
   * Create a new instance of the component.
   */
  constructor(
    @Inject("RIGSHARE_COMMON_CONFIG") protected config: CommonConfig,
    private elementRef: ElementRef
  ) {}

  /**
   * On component init.
   */
  ngOnInit() {
    this.setClasses();
    this.setId();
    this.registerIonInput();
  }

  /**
   * On component destroy.
   */
  ngOnDestroy() {
    this.unregisterIonInput();
  }

  /**
   * Blur the input element.
   */
  blur() {
    this.element?.nativeElement.blur();
  }

  /**
   * Focus on the input element.
   */
  focus() {
    this.element?.nativeElement.focus();
  }

  onInput() {
    this.onTouched();
    this.onChange(this.value);
  }

  /**
   * Register the input with ionic to take advantage of input shim.
   *
   * @see https://github.com/ionic-team/ionic-framework/blob/main/core/src/utils/input-shims/input-shims.ts#L82
   */
  registerIonInput() {
    if (this.config.environment.platform === "mobile") {
      document.dispatchEvent(
        new CustomEvent("ionInputDidLoad", {
          detail: this.elementRef?.nativeElement,
        })
      );
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Set the classes of the input element.
   */
  setClasses() {
    this.classes = {
      group: {
        "border border-gray-300 rounded": true,
        "bg-white": !this.readonly,
        "bg-neutral-100": !!this.readonly,
        "focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-opacity-75 transition":
          true,
      },
      input: {
        "appearance-none border-none block w-full focus:outline-none focus:ring-0":
          true,
        "leading-6 font-normal text-neutral-700": true,
        "!px-3 !py-2 text-base": this.controlSize === "medium",
        "!px-5 !py-3 text-lg": this.controlSize === "large",
        "!px-2 !py-1 text-sm": this.controlSize === "small",
        "border-red-500 focus-visible:ring-red-500":
          (this.errors?.length || 0) > 0,
      },
    };
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Set the id of the input element.
   */
  setId() {
    if (!this.id) {
      this.id = `input-${Math.random().toString(36).substring(7)}`;
    }
  }

  /**
   * Unregister the input with ionic to turn off input shim.
   *
   * @see https://github.com/ionic-team/ionic-framework/blob/main/core/src/utils/input-shims/input-shims.ts#L86
   */
  unregisterIonInput() {
    if (this.config.environment.platform === "mobile") {
      document.dispatchEvent(
        new CustomEvent("ionInputDidUnload", {
          detail: this.elementRef?.nativeElement,
        })
      );
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }
}
