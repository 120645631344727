import * as Types from '../types';

import { gql } from 'apollo-angular';
export type MessageFragment = { __typename?: 'Message', content?: string | null, created_at?: any | null, id: string, message_group_id: string, user_id: string, media?: Array<{ __typename?: 'Media', id: string, mimetype?: string | null, mediable_id?: string | null, type?: string | null, url?: string | null, meta?: { __typename?: 'MediaMetaData', id: string, height?: number | null, width?: number | null } | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null, small?: string | null, medium?: string | null, large?: string | null, xLarge?: string | null } | null }> | null, user?: { __typename?: 'User', id: string, name?: string | null, username?: string | null, verified?: boolean | null, media?: Array<{ __typename?: 'Media', id: string, type?: string | null, url?: string | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null } | null }> | null } | null };

export const MessageFragmentDoc = gql`
    fragment Message on Message {
  content
  created_at
  id
  media {
    id
    mimetype
    mediable_id
    meta {
      id
      height
      width
    }
    type
    url
    sizes {
      id
      thumbnail
      small
      medium
      large
      xLarge
    }
  }
  message_group_id
  user_id
  user {
    id
    media {
      id
      type
      url
      sizes {
        id
        thumbnail
      }
    }
    name
    username
    verified
  }
}
    `;