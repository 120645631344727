import { Model } from "./model";

export class HashtagModel extends Model {
  /**
   * The id of the model.
   */
  id: any;

  /**
   * The name of the model.
   */
  name?: string;

  /**
   * The number of posts associated to the hashtag.
   */
  posts_count?: number;
}
