import * as Types from '../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type MarkNotificationsReadMutationVariables = Types.Exact<{
  ids?: Types.InputMaybe<Array<Types.Scalars['ID']> | Types.Scalars['ID']>;
}>;


export type MarkNotificationsReadMutation = { __typename?: 'Mutation', markNotificationsRead?: { __typename?: 'NotificationsSummary', unread_count?: number | null } | null };

export const MarkNotificationsReadDocument = gql`
    mutation MarkNotificationsRead($ids: [ID!]) {
  markNotificationsRead(ids: $ids) {
    unread_count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MarkNotificationsReadGQL extends Apollo.Mutation<MarkNotificationsReadMutation, MarkNotificationsReadMutationVariables> {
    document = MarkNotificationsReadDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }