import { ButtonComponent } from '../buttons/button/button.component';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { fromEvent, Subscription } from 'rxjs';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-dropdown",
  templateUrl: "./dropdown.component.html",
  styles: [],
  host: {
    class: "block",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, NgIf, NgClass, NgStyle],
})
export class DropdownComponent implements OnInit, OnDestroy {
  /**
   * The index of the active item.
   */
  activeItemIndex: number | null = null;

  /**
   * The color of the button.
   */
  @Input() buttonColor: "clear" | "danger" | "light" | "primary" = "clear";

  /**
   * The icon only state of the button.
   */
  @Input() buttonIconOnly: boolean = false;

  /**
   * The expansion of the button.
   */
  @Input() buttonExpand: "" | "block" = "";

  /**
   * The fill of the button.
   */
  @Input() buttonFill: "solid" | "outline" | "transparent" = "solid";

  /**
   * The size of the button.
   */
  @Input() buttonSize: "medium" | "small" | "large" = "medium";

  /**
   * The changed event emitter of the component.
   */
  @Output()
  changed: EventEmitter<boolean> = new EventEmitter();

  /**
   * The css classes of the button.
   */
  @Input() defaultButtonClasses: string =
    "block rounded focus:ring-2 h-full px-3 py-2 empty:h-0 empty:p-0";

  /**
   * The direction of the dropdown.
   */
  @Input() direction: "left" | "right" = "left";

  /**
   * If the button should be displayed.
   */
  @Input() displayButton: boolean = true;

  /**
   * If dividers for the dropdown items should be displayed.
   */
  @Input() displayDividers: boolean = false;

  /**
   * The disabled state of the component.
   */
  @Input() disabled: boolean = false;

  /**
   * The display state of the toggle icon.
   */
  @Input() displayToggle: boolean = false;

  /**
   * The full width state of the component.
   */
  @Input() fullWidth: boolean = false;

  /**
   * The items container of the component.
   */
  @ViewChild("itemContainer")
  itemContainer?: ElementRef<HTMLElement>;

  /**
   * The offset style the dropdown.
   */
  offsetStyle: any = {};

  /**
   * The opened state of the dropdown.
   */
  opened = false;

  /**
   * The size of the button.
   */
  @Input() size: "medium" | "small" | "large" = "small";

  /**
   * The subscriptions of the component.
   */
  subs: Subscription = new Subscription();

  /**
   * The css classes of the toggle icon.
   */
  @Input() toggleClass: string = "";

  /**
   * The trigger of the component.
   */
  @ViewChild(ButtonComponent) trigger?: ButtonComponent;

  /**
   * Create a new instance of the component.
   */
  constructor(private cd: ChangeDetectorRef, private elementRef: ElementRef) {}

  /**
   * On component init.
   */
  ngOnInit() {}

  /**
   * On component destroy.
   */
  ngOnDestroy() {
    this.unsubscribeFromEvents(true);
  }

  /**
   * Navigate to a dropdown item.
   */
  navigateToDropdownItem(event: Event, direction: "next" | "prev" = "next") {
    event.preventDefault();
    event.stopPropagation();

    const clickables =
      this.itemContainer?.nativeElement.querySelectorAll("a, button");

    if (clickables?.length) {
      let activeIndex = Math.max(
        0,
        Math.min(
          clickables.length,
          direction === "next"
            ? (this.activeItemIndex === null ? -1 : this.activeItemIndex) + 1
            : (this.activeItemIndex === null ? 0 : this.activeItemIndex) - 1
        )
      );

      if (direction === "next" && activeIndex === clickables.length) {
        activeIndex = 0;
      }

      if (direction === "prev" && activeIndex === this.activeItemIndex) {
        activeIndex = clickables.length - 1;
      }

      this.activeItemIndex = activeIndex;

      Array.from(clickables).forEach((element: any, index) => {
        if (index === activeIndex) {
          element.focus();
        }
      });
    }
  }

  /**
   * Set the opened state of the component.
   */
  setOpened(opened: boolean) {
    if (opened) {
      this.setPosition();
    }

    this.opened = opened;

    this.changed.next(this.opened);
    this.cd.detectChanges();

    if (this.opened) {
      this.subscribeToEvents();
    } else {
      this.unsubscribeFromEvents();
    }
  }

  setPosition() {
    const trigger = this.trigger?.elementRef.nativeElement;
    const bottom = trigger.clientHeight + trigger.offsetTop;
    const screenHeight = window.innerHeight;
    // if the trigger is closer to the bottom of the screen, flip the dropdown
    if (bottom > screenHeight - screenHeight / 3) {
      this.offsetStyle = {
        bottom: `${this.elementRef.nativeElement.clientHeight}px`,
      };
    } else {
      this.offsetStyle = {
        top: `${this.elementRef.nativeElement.clientHeight}px`,
      };
    }
  }

  /**
   * Subscribe to events of the component.
   */
  subscribeToEvents() {
    const overlayOutsideClick = fromEvent(window, "click").subscribe((e) => {
      if (
        this.trigger?.elementRef.nativeElement.contains(e.target as Node) ===
        false
      ) {
        this.setOpened(false);
      }
    });

    const keyDownEvent = fromEvent(window, "keydown").subscribe(
      (event: Event) => {
        if (
          (event as KeyboardEvent).key === "Tab" ||
          (event as KeyboardEvent).key === "ArrowDown"
        ) {
          this.navigateToDropdownItem(event);
        }

        if ((event as KeyboardEvent).key === "ArrowUp") {
          this.navigateToDropdownItem(event, "prev");
        }
      }
    );

    const keyUpEvent = fromEvent(window, "keyup").subscribe((event: Event) => {
      if ((event as KeyboardEvent).key === "Enter") {
        this.setOpened(false);
      }
    });

    requestAnimationFrame(() => {
      if (this.itemContainer) {
        this.subs.add(
          fromEvent(this.itemContainer?.nativeElement, "click").subscribe({
            next: (e: Event) => {
              // Don't close the dropdown if the user clicked on an element that is an input element.
              if (e.target instanceof HTMLInputElement) {
                return;
              }

              this.setOpened(false);
            },
          })
        );
      }
    });

    this.subs.add(overlayOutsideClick);
    this.subs.add(keyDownEvent);
    this.subs.add(keyUpEvent);
  }

  /**
   * Unsubscribe from all the events of the component.
   */
  unsubscribeFromEvents(destroyed: boolean = false) {
    this.subs.unsubscribe();

    if (!destroyed) {
      this.subs = new Subscription();
    }
  }
}
