import { environment } from "../../environments/environment";
import { ErrorHandler, Injectable } from "@angular/core";
import { createErrorHandler } from "@sentry/angular-ivy";

@Injectable({
  providedIn: "root",
})
export class AppErrorHandler implements ErrorHandler {
  /**
   * The sentry error handler.
   */
  sentryErrorHandler?: ErrorHandler;

  /**
   * Create a new intance of the error handler.
   */
  constructor() {
    if (this.shouldHandleWithSentry()) {
      this.sentryErrorHandler = createErrorHandler();
    }
  }

  /**
   * Handle the error.
   */
  handleError(error: any): void {
    const chunkFailedMessages = [
      /Loading chunk (.*) failed/,
      /ChunkLoadError: Loading chunk/,
    ];

    if (chunkFailedMessages.some((message) => message.test(error.message))) {
      return window.location.reload();
    }

    if (error.constructor.name === "HttpErrorResponse") {
      error.error.message = error.message + " (" + error.error.message + ")";
      error = error.error;
    }

    if (this.shouldHandleWithSentry()) {
      this.sentryErrorHandler?.handleError(
        error.originalError || error.error || error
      );
    } else {
      console.error(error);
    }
  }

  /**
   * Determine if the errors should be handled with sentry.
   */
  shouldHandleWithSentry(): boolean {
    return environment.production || environment.staging;
  }
}
