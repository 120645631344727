import { MediaModel } from './media.model';
import { Model } from './model';
import { ModelsModule } from './models.module';
import { MarkMessageReadGQL } from '../graphql/messages/mark-message-read.generated';
import { User } from '../graphql/types';
import { AuthenticationService } from '../services/authentication/authentication.service';
import { firstValueFrom } from 'rxjs';

export class MessageModel extends Model {
  /**
   * The graphql mutation that marks messages read.
   */
  _auth = ModelsModule.injector.get(AuthenticationService);

  /**
   * The graphql mutation that marks messages read.
   */
  _markMessageReadGQL = ModelsModule.injector.get(MarkMessageReadGQL);

  /**
   * The content of the model.
   */
  content?: string;

  /**
   * The created at timestamp of the model.
   */
  created_at?: string;

  /**
   * The id of the message.
   */
  id: any;

  /**
   * Check if the current user is the author of the message.
   */
  get isAuthor(): boolean {
    if (!this.user?.id) {
      return false;
    }

    return +this._auth.user()?.id === +this.user?.id;
  }

  /**
   * The state of the message being a succesor to the previous message.
   */
  isSuccessor: boolean = false;

  /**
   * The marked read state of the model.
   */
  markingRead?: boolean;

  /**
   * The media of the model.
   */
  media?: MediaModel[];

  /**
   * The message group id of the model.
   */
  message_group_id: any;

  /**
   * The timestamp of when the message was read.
   */
  read_at?: string;

  /**
   * The id of the user that wrote the message.
   */
  user_id: any;

  /**
   * The user of the message.
   */
  user?: User;

  /**
   * Mark the message as read.
   */
  async markRead() {
    if (this.read_at || this.markingRead) {
      return;
    }

    this.markingRead = true;

    try {
      const { data } = await firstValueFrom(
        this._markMessageReadGQL.mutate({ id: this.id })
      );

      this.read_at = data?.markMessageRead?.read_at;
    } catch (error) {}
  }
}
