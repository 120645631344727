import { isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  OnDestroy,
  PLATFORM_ID
  } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ModalService implements OnDestroy {
  /**
   * The time to delay close of modals.
   */
  static CLOSE_DELAY = 500;

  /**
   * The closing promise;
   */
  closing?: Promise<void> | null;

  /**
   * The modals of the service.
   */
  modals: Map<string, { callback?: Function | null; data?: any }> = new Map();

  /**
   * The opened state of the service.
   */
  opened: boolean = false;

  /**
   * The opening promise of the service.
   */
  openingPromise?: Promise<boolean>;

  /**
   * Create a new instance of the service.
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router
  ) {
    this.init();
  }

  /**
   * The stored scroll method of the service.
   */
  scrollMethod: any;

  /**
   * The subscriptions of the service.
   */
  subs: Subscription = new Subscription();

  /**
   * On service destroy.
   */
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Hide the current modal.
   */
  async close(modalId: string, data: any = null): Promise<void> {
    this.closing = new Promise(async (resolve) => {
      this.onClose(modalId, data);

      setTimeout(async () => {
        await this.router.navigate([{ outlets: { modal: null } }], {
          replaceUrl: true,
        });

        this.closing = null;

        // Check if any other modals are opened.
        if (this.modals.size) {
          return resolve();
        }

        document.body.classList.remove("modal-open");
        this.opened = false;

        resolve();
      }, ModalService.CLOSE_DELAY);
    });
  }

  /**
   * Get data by a modal id.
   */
  getData(modalId: string): any | undefined {
    return this.modals.get(modalId)?.data;
  }

  /**
   * Get the id of the current modal.
   */
  getModalId(): string {
    const keys = Array.from(this.modals.keys());

    return keys[keys.length - 1];
  }

  /**
   * Hide the current modal.
   */
  async hide(): Promise<any> {
    return this.router.navigate([{ outlets: { modal: null } }]);
  }

  /**
   * Initialize the service.
   */
  init() {
    this.subs.add(
      this.router.events.subscribe({
        next: async (event) => {
          if (this.opened && event instanceof NavigationStart) {
            await this.waitForOpening();
            // this.close();
          }
        },
      })
    );
  }

  /**
   * On close callback
   */
  onClose(modalId: string, data: any): void {
    this.modals.get(modalId)?.callback?.(data);
    this.modals.delete(modalId);
  }

  /**
   * Display a modal.
   */
  async open(
    key: string,
    data: any = {},
    callback: ((data: any) => void) | null = null
  ): Promise<any> {
    this.openingPromise = new Promise(async (resolve) => {
      const modalId = key + "-" + Date.now();
      this.modals.set(modalId, { callback, data });

      await this.rejectScroll();

      if (this.closing) {
        await this.closing;
      }

      await this.router.navigate(
        [
          {
            outlets: {
              modal: ["modals", key],
            },
          },
        ],
        {
          skipLocationChange: true,
          state: { modalId },
        }
      );

      this.opened = true;

      resolve(false);
    });
  }

  /**
   * Disable scrolling on the window while the modal is opened.
   */
  rejectScroll(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    document.body.classList.add("modal-open");
    this.scrollMethod = window.scrollTo;
    window.scrollTo = () => {};
  }

  /**
   * Restore window scrolling capabilites.
   */
  restoreScroll(): void {
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.scrollMethod) {
      window.scrollTo = this.scrollMethod;
      this.scrollMethod = null;
    }
  }

  /**
   * Wait for the service to open a modal.
   */
  async waitForOpening(): Promise<any> {
    if (this.openingPromise) {
      await this.openingPromise;
    }

    return true;
  }
}
