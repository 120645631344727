import { AlertModel } from '../models/alerts/alert.model';
import { Injectable } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class AlertService {
  /**
   * The alerts of the service.
   */
  alerts: AlertModel[] = [];

  /**
   * The subject published to when new alerts are pushed.
   */
  new: Subject<AlertModel> = new Subject();

  /**
   * A response from an alert.
   */
  response: Subject<any> = new Subject();

  /**
   * Create a new instance of the service.
   */
  constructor() {}

  /**
   * Clear all alerts.
   */
  async clear(): Promise<void> {
    this.alerts = [];
  }

  /**
   * Display an error alert.
   */
  async error(message: string, options: any = {}): Promise<any> {
    const alert = new AlertModel({
      message: message,
      type: AlertModel.TYPE_ERROR,
      dismiss: 5000,
      ...options,
    });

    return this.push(alert);
  }

  /**
   * Add a prompt alert.
   */
  async prompt(message: string, options: any = {}): Promise<boolean> {
    const alert = new AlertModel({
      action: "prompt",
      message: message,
      type: AlertModel.TYPE_INFO,
      ...options,
    });

    await this.push(alert);

    const response = await firstValueFrom(
      this.response.pipe(
        filter((r) => r.alert.id === alert.id),
        first()
      )
    );

    return response.value;
  }

  /**
   * Push an alert unto the stack.
   */
  async push(alert: AlertModel): Promise<void> {
    this.alerts.push(alert);
    this.new.next(alert);
  }

  /**
   * Remve an alert from the stack.
   */
  async remove(alert: AlertModel): Promise<any> {
    const index = this.alerts.findIndex((a) => a.id === alert.id);

    if (index >= 0) {
      this.alerts.splice(index, 1);
    }
  }

  /**
   * Display a success alert.
   */
  async success(message: string, options: any = {}): Promise<any> {
    const alert = new AlertModel({
      message: message,
      type: AlertModel.TYPE_SUCCESS,
      ...options,
    });

    return this.push(alert);
  }

  /**
   * Display a warning alert.
   */
  async warning(message: string, options: any = {}): Promise<any> {
    const alert = new AlertModel({
      message: message,
      type: AlertModel.TYPE_WARNING,
      ...options,
    });

    return this.push(alert);
  }
}
