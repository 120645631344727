import { StorageDriver } from './storage-driver';
import { ConfigSerivce } from '../config.service';
import { isPlatformServer } from '@angular/common';
import {
  Inject,
  Injectable,
  Injector,
  PLATFORM_ID
  } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import * as Cookies from 'es-cookie';

@Injectable()
export class CookieStorageService implements StorageDriver {
  /**
   * Create a new instance of the service.
   */
  constructor(
    public config: ConfigSerivce,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  all(): { [key: string]: string } {
    if (isPlatformServer(this.platformId)) {
      const request = this.injector.get(REQUEST);
      const cookies = this.getCookies(request.headers);

      return cookies;
    } else {
      return Cookies.getAll();
    }
  }

  /**
   * Get item from local storage.
   */
  async get(key: string): Promise<any> {
    if (isPlatformServer(this.platformId)) {
      const request = this.injector.get(REQUEST);
      const cookies = this.getCookies(request.headers);

      if (cookies && cookies.hasOwnProperty(key)) {
        return Promise.resolve(cookies[key]);
      }
    } else {
      return Promise.resolve(Cookies.get(key));
    }
  }

  /**
   * Get item from local storage.
   */
  async has(key: string): Promise<boolean> {
    return new Boolean(await this.get(key)).valueOf();
  }

  /**
   * Get cookies from the request.
   */
  getCookies(headers: any): any {
    let cookies;

    for (var header in headers) {
      if (headers.hasOwnProperty(header) && "cookie" == header.toLowerCase()) {
        cookies = headers[header];
        break;
      }
    }

    if (cookies) {
      return this.parseCookies(cookies);
    }
  }

  /**
   * Set an item to local storage.
   */
  async set(key: string, value: any, options = {}): Promise<any> {
    if (typeof window === "undefined") {
      return;
    }

    return await Cookies.set(key, value, {
      path: this.config.get("cookies.path"),
      sameSite: this.config.get("cookies.sameSite"),
      secure: this.config.get("cookies.secure"),
      ...options,
    });
  }

  /**
   * Remove an item from local storage.
   */
  async remove(key: string): Promise<any> {
    if (typeof window === "undefined") {
      return;
    }

    return await Cookies.remove(key);
  }

  /**
   * Clear local storage.
   */
  async clear(): Promise<any> {
    if (typeof window === "undefined") {
      return;
    }

    this.config
      .get("cookies.internalKeys")
      .forEach((k: string) => Cookies.remove(k));

    return;
  }

  /**
   * Parse cookies from a string.
   */
  protected parseCookies(s: string): { [key: string]: string } {
    if (s.length === 0) return {};
    const parsed: { [key: string]: string } = {};
    const pattern = new RegExp("\\s*;\\s*");
    s.split(pattern).forEach((i) => {
      const [encodedKey, encodedValue] = i.split("=");
      const key = decodeURIComponent(encodedKey);
      const value = decodeURIComponent(encodedValue);
      parsed[key] = value;
    });

    return parsed;
  }

  toString(): string {
    const tokens = [];
    const cookies = this.all();

    for (const key in cookies) {
      const value = cookies[key];
      tokens.push(Cookies.encode(key, value, {}));
    }

    return tokens.join("; ");
  }
}
