import * as Types from '../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SearchUsersQueryVariables = Types.Exact<{
  perPage?: Types.InputMaybe<Types.Scalars['Int']>;
  query?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SearchUsersQuery = { __typename?: 'Query', users: { __typename?: 'UserConnection', edges: Array<{ __typename?: 'UserEdge', node: { __typename?: 'User', id: string, name?: string | null, username?: string | null, media?: Array<{ __typename?: 'Media', id: string, type?: string | null, url?: string | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null, small?: string | null } | null }> | null } }> } };

export const SearchUsersDocument = gql`
    query SearchUsers($perPage: Int = 15, $query: String) {
  users(search: $query, first: $perPage) {
    edges {
      node {
        id
        name
        username
        media {
          id
          type
          url
          sizes {
            id
            thumbnail
            small
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchUsersGQL extends Apollo.Query<SearchUsersQuery, SearchUsersQueryVariables> {
    document = SearchUsersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }