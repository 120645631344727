<ng-template #content>
  <div class="leading-tight w-full whitespace-nowrap">
    <ng-content></ng-content>
  </div>
</ng-template>
<a *ngIf="href" [ngClass]="classes" [href]="href" [target]="target">
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</a>
<a
  *ngIf="route"
  [ngClass]="classes"
  (click)="handleClick($event)"
  [routerLink]="route"
  [queryParams]="queryParams"
>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</a>
<button
  *ngIf="!href && !route"
  [ngClass]="classes"
  (click)="handleClick($event)"
  [disabled]="disabled"
  type="button"
>
  <ng-container [ngTemplateOutlet]="content"></ng-container>
</button>
