import { ConfigSerivce } from '../config.service';
import { IDBStorageService } from '../storage/idb-storage.service';
import { StorageService } from '../storage/storage.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class TokenService {
  /**
   * Create a new instance of the service.
   */
  constructor(
    public config: ConfigSerivce,
    public idbStoragService: IDBStorageService,
    public storageService: StorageService
  ) {
    this.init();
  }

  /**
   * Name of token stored in local storage.
   */
  protected _token: string = "_token";

  /**
   * The intialized state of the service.
   */
  initialized: boolean = false;

  /**
   * The load promise.
   */
  load?: Promise<void>;

  /**
   * The storage key to use for tokens.
   */
  static storageKey = "_ngktk";

  /**
   * The tokens that have been loaded in-memory.
   */
  protected tokens: Map<string, string> = new Map();

  /**
   * Destroy the resources of the service.
   */
  async destroy(): Promise<void> {
    this.tokens.forEach((v, k) => this.remove(k));
  }

  /**
   * Get the token from storage.
   */
  async get(tokenName: string = ""): Promise<any> {
    await this.load;
    tokenName = tokenName || this.config.get("token.name", this._token);

    if (this.tokens.has(tokenName)) {
      return this.tokens.get(tokenName);
    }

    try {
      let token = await this.retrieveToken(tokenName);

      if (!token) {
        return;
      }

      return token;
    } catch (error) {
      throw error;
    }
  }

  /**
   * Initialize the serivce.
   */
  init(): void {
    this.load = new Promise(async (resolve) => {
      if (this.initialized) {
        resolve();
      }

      this.initialized = true;
      resolve();
    });
  }

  /**
   * Remove token from local storage.
   */
  async remove(tokenName: string = ""): Promise<boolean> {
    await this.load;
    tokenName = tokenName || this.config.get("token.name", this._token);
    await this.idbStoragService.remove(tokenName);
    await this.tokens.delete(tokenName);

    return true;
  }

  /**
   * Read a token from a response object.
   */
  read(response: any = null, key: string = ""): string | null {
    if (response) {
      let tokenKey = this.config.get("token.access", key);

      return tokenKey.split(".").reduce((o: any, i: string) => o[i], response);
    }

    return null;
  }

  /**
   * Request token from another browser source.
   */
  private async requestSessionTokenForOthersource(): Promise<void> {
    return new Promise((resolve) => {
      window.localStorage.setItem("_ngktkGetSession", `${Date.now()}`);
      window.localStorage.removeItem("_ngktkGetSession");
      resolve();
    });
  }

  /**
   * Retrieve a token by name from stroage.
   */
  private async retrieveToken(
    tokenName: string
  ): Promise<ArrayBuffer | string | undefined> {
    let token;

    if ((token = await this.idbStoragService.get(tokenName))) {
      return token;
    }

    if (window?.localStorage) {
      await this.requestSessionTokenForOthersource();
    }

    return;
  }

  /**
   * Store the token in local storage.
   */
  async set(
    token: any,
    tokenName: string = "",
    storageType: string = "local"
  ): Promise<any> {
    await this.load;
    tokenName = tokenName || this.config.get("token.name", this._token);

    if (!token) {
      throw new Error("Error: No token provided.");
    }

    try {
      this.tokens.set(tokenName, token);
      await this.idbStoragService.set(tokenName, token);

      return true;
    } catch (error) {
      console.error(error);
      throw new Error("Error: Could not store token.");
    }
  }

  /**
   * Determine if in memory tokens should be stored in cookies
   * in plain-text before the window is closed so they may be
   * retrieved later.
   */
  shouldRotateTokensWithCookies(): boolean {
    return this.config.get("token.rotateCookies");
  }
}
