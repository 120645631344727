import { ProductModel } from "../../../models";
import { ImageComponent } from "../../media/image/image.component";
import { NgClass, NgIf } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-product-image",
  templateUrl: "./product-image.component.html",
  standalone: true,
  imports: [NgClass, NgIf, ImageComponent],
})
export class ProductImageComponent implements OnInit {
  /**
   * Accessor for the the alt tag of the image.
   */
  get alt(): string | void {
    if (this.userProduct?.product) {
      return `${this.userProduct?.product?.brand?.name} ${this.userProduct?.product?.name} on RigShare`;
    }

    if (this.product?.brand) {
      return `${this.product?.brand?.name} ${this.product?.name} on RigShare`;
    }

    if (this.product?.name) {
      return `${this.product?.name} on RigShare`;
    }
  }

  /**
   * The aspect ratio of the image.
   */
  @Input() aspect = "1:1";

  /**
   * The background color of the image.
   */
  @Input() backgroundColor = "white";

  /**
   * The contain state of the image.
   */
  @Input() contain = true;

  /**
   * If the product image should be used even when there is a user product image.
   */
  _forceProductImage: boolean = false;
  @Input()
  get forceProductImage(): boolean {
    return this._forceProductImage;
  }

  set forceProductImage(value: boolean) {
    this._forceProductImage = value;
    this.setProperties();
  }

  /**
   * If the image should have a border.
   */
  @Input() hasBorder = false;

  /**
   * The height of the image of the component.
   */
  @Input() height?: number | string = 320;

  /**
   * The id of the image.
   */
  id?: string;

  /**
   * The index of the image to use in component.
   */
  @Input() imageIndex = 0;

  /**
   * Size of the image to use in component.
   */
  @Input() imageSize = "medium";

  /**
   * The img element of the component.
   */
  @ViewChild("img", { static: true }) img?: ElementRef;

  /**
   * The loaded state of the image.
   */
  isLoaded: boolean = false;

  /**
   * The loaded event of the image.
   */
  @Output() loaded: EventEmitter<void> = new EventEmitter();

  /**
   * Product object.
   */
  _product?: ProductModel | undefined;

  @Input()
  get product(): ProductModel | undefined {
    return this._product;
  }

  set product(value) {
    this._product = new ProductModel(value);
    this.setProperties();
  }

  /**
   * A url to use for the image.
   */
  @Input() url?: string;

  /**
   * User product of the component.
   */
  _userProduct: any;

  @Input()
  get userProduct(): any {
    return this._userProduct;
  }

  set userProduct(userProduct) {
    if (userProduct) {
      this._userProduct = userProduct;
      this.product = new ProductModel(userProduct.product);
      this.setProperties();
    }
  }

  /**
   * Width of the image component.
   */
  @Input() width?: number | string = 320;

  /**
   * The source of the image.
   */
  src?: string;

  /**
   * The source of the image.
   */
  srcset?: string;

  /**
   * Create a new instance of the component.
   */
  constructor() {}

  /**
   * On component init.
   */
  ngOnInit(): void {
    this.setProperties();
  }

  /**
   * On loaded event handler.
   */
  onLoaded(event: any): void {
    this.loaded.emit(event);
  }

  /**
   * Set the properties of the component.
   */
  setProperties() {
    this.setSource();
    this.setSrcset();
  }

  /**
   * Set source one of the image.
   */
  setSource() {
    if (this.url) {
      this.src = this.url;
      return;
    }

    if (this.userProduct?.media?.[this.imageIndex] && !this.forceProductImage) {
      this.src = this.userProduct?.media?.[this.imageIndex]?.sizes
        ? this.userProduct?.media?.[this.imageIndex]?.sizes?.[this.imageSize]
        : this.userProduct?.media?.[this.imageIndex]?.url;

      return;
    }

    if (this.product?.images?.[this.imageIndex]) {
      this.src = this.product?.images?.[this.imageIndex]?.sizes
        ? this.product?.images?.[this.imageIndex]?.sizes?.[this.imageSize]
        : this.product?.images?.[this.imageIndex]?.url;
    }
  }

  /**
   * Set the srcset of the image.
   */
  setSrcset() {
    if (this.url || this.imageSize === "thumbnail") {
      return;
    }

    const sizes = [];

    if (
      this.userProduct?.media?.[this.imageIndex]?.sizes &&
      ["thumbnail", "small"].indexOf(this.imageSize) === -1 &&
      !this.forceProductImage
    ) {
      if (this.userProduct?.media?.[this.imageIndex]?.sizes?.small) {
        sizes.push(
          `${this.userProduct?.media?.[this.imageIndex]?.sizes?.small} 320w`
        );
      }

      if (this.userProduct?.media?.[this.imageIndex]?.sizes?.medium) {
        sizes.push(
          `${this.userProduct?.media?.[this.imageIndex]?.sizes?.medium} 1280w`
        );
      }

      if (this.userProduct?.media?.[this.imageIndex]?.sizes?.large) {
        sizes.push(
          `${this.userProduct?.media?.[this.imageIndex]?.sizes?.large} 1920w`
        );
      }

      if (this.userProduct?.media?.[this.imageIndex]?.sizes?.xLarge) {
        sizes.push(
          `${this.userProduct?.media?.[this.imageIndex]?.sizes?.xLarge} 3840w`
        );
      }

      this.srcset = sizes.join(", ");

      return;
    }

    if (
      this.product?.images?.[this.imageIndex]?.sizes &&
      ["thumbnail", "small"].indexOf(this.imageSize) < 0
    ) {
      if (this.product?.images?.[this.imageIndex]?.sizes?.small) {
        sizes.push(
          `${this.product?.images?.[this.imageIndex]?.sizes?.small} 320w`
        );
      }

      if (this.product?.images?.[this.imageIndex]?.sizes?.medium) {
        sizes.push(
          `${this.product?.images?.[this.imageIndex]?.sizes?.medium} 640w`
        );
      }

      if (this.product?.images?.[this.imageIndex]?.sizes?.large) {
        sizes.push(
          `${this.product?.images?.[this.imageIndex]?.sizes?.large} 1280w`
        );
      }

      if (this.product?.images?.[this.imageIndex]?.sizes?.xLarge) {
        sizes.push(
          `${this.product?.images?.[this.imageIndex]?.sizes?.xLarge} 1920w`
        );
      }

      this.srcset = sizes.join(", ");
    }
  }
}
