import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  OnInit,
  OnDestroy,
  PLATFORM_ID,
} from "@angular/core";

@Directive({
    selector: "[appStickyTop]",
    standalone: true,
})
export class StickyTopDirective implements OnInit, OnDestroy, AfterViewInit {
  /**
   * The sticky class.
   */
  @HostBinding("class.sticky")
  @HostBinding("class.top-0")
  sticky = true;

  /**
   * The offset to add to the element.
   */
  @Input() stickyOffset?: string;

  /**
   * The known sticky elements.
   */
  stickyElements?: HTMLCollectionOf<any>;

  /**
   * The top position of the component.
   */
  @HostBinding("style.top") top = "0px";

  /**
   * The z-index value of the element.
   */
  @HostBinding("style.zIndex") zIndex = 400;

  /**
   * Create a new instance of the directive.
   */
  constructor(
    private element: ElementRef,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  /**
   * On init of the directive.
   */
  ngOnInit(): void {}

  /**
   * On destroy of the directive.
   */
  ngOnDestroy(): void {}

  /**
   * After view init.
   */
  ngAfterViewInit(): void {
    this.setStyles();

    // TODO: Set styles on window resize
  }

  /**
   * Get the top value of all preceding sticky items.
   */
  getTopOfPreceding(index: number): string {
    let top = 0;

    if (!this.stickyElements) {
      return "";
    }

    Array.from(this.stickyElements).forEach((e, i) => {
      if (i >= index) {
        return;
      }
      const rect = e.getBoundingClientRect();

      top += Math.trunc(rect.height);
    });

    if (this.stickyOffset) {
      top = top + +this.stickyOffset;
    }

    return `${top}px`;
  }

  /**
   * Set the styles of the element.
   */
  setStyles(): void {
    if (isPlatformBrowser(this.platformId)) {
      let zIndex = this.zIndex;

      this.stickyElements = document.getElementsByClassName("sticky");

      Array.from(this.stickyElements).forEach((e, i) => {
        zIndex--;

        if (this.element && e === this.element.nativeElement) {
          setTimeout(() => {
            this.top = this.getTopOfPreceding(i);
            this.zIndex = zIndex;
          });
        }
      });
    }
  }
}
