<div
  class="flex items-start rounded border px-4 py-2 shadow"
  [ngClass]="styleClass"
  [class.text-sm]="size === 'small'"
  [class.text-lg]="size === 'large'"
  role="alert"
>
  <div class="grow">
    <div class="flex items-center">
      <div class="grow">
        {{ alert?.message }}
        <ng-content></ng-content>
      </div>

      <div *ngIf="alert?.link" class="ml-2">
        <a [href]="alert?.link?.url">{{ alert?.link?.text }}</a>
      </div>

      <div *ngIf="alert?.action === 'prompt'" class="ml-2">
        <app-button aria-label="OK" (onClick)="onPrompt.next()" color="clear">
          <span>Ok</span>
        </app-button>
      </div>
    </div>
  </div>

  <div>
    <button
      *ngIf="!alert?.action && alert?.dismiss !== false"
      type="button"
      class="ml-2 px-1 align-middle text-xl leading-none outline-none focus:outline-none"
      aria-label="Close"
      (click)="dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
