<app-dropdown-item *ngIf="notification?.route" [route]="notification?.route">
  <app-notification-item
    class="block"
    #notificationComponent
    [notification]="notification"
    [isFirst]="isFirst"
    [isLast]="isLast"
    (action)="action.emit($event)"
  >
  </app-notification-item>
</app-dropdown-item>

<style>
  :host {
    display: block;
  }
</style>
