import { AlertModel } from '../../models';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
  } from '@angular/core';
import { ButtonComponent } from '../buttons/button/button.component';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: "app-alert",
    templateUrl: "./alert.component.html",
    styles: [],
    host: {
        "[class.block]": "!dismissed",
        "[class.hidden]": "dismissed",
    },
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        ButtonComponent,
    ],
})
export class AlertComponent implements OnInit {
  /**
   * The alert of the component.
   */
  @Input() alert?: AlertModel;

  /**
   * The classes that control the apperance of the alert.
   */
  classes: { [type: string]: string } = {
    danger: "border-red-300 bg-red-100 text-red-700",
    info: "border-blue-300 bg-blue-100 text-blue-700",
    success: "border-green-300 bg-green-100 text-green-700",
    warning: "border-yellow-300 bg-yellow-100 text-yellow-700",
  };

  /**
   * The dismissed state of the alert.
   */
  dismissed: boolean = false;

  /**
   * The dismissible state of the alert.
   */
  @Input() dismissible: boolean = false;

  /**
   * Event emitter for the dismiss event.
   */
  @Output() onDismiss: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Event emitter for the prompt.
   */
  @Output() onPrompt: EventEmitter<void> = new EventEmitter<void>();

  /**
   * If the component is in the small state.
   */
  @Input() size: "large" | "medium" | "small" = "medium";

  /**
   * Return the style class for the alert.
   */
  styleClass: string = "";

  /**
   * The type of the alert.
   */
  @Input() type?: "danger" | "info" | "success" | "warning" | "";

  /**
   * On component init.
   */
  ngOnInit() {
    if (this.type) {
      this.styleClass = this.classes[this.type];
    }
  }

  /**
   * Dismiss the alert.
   */
  dismiss() {
    this.dismissed = true;
    this.onDismiss.next();
  }
}
