import { environment, options } from '../../environments/environment';
import { NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';
import { GraphQLModule as BaseGraphQLModule } from 'rigshare-common';

@NgModule({
  imports: [ApolloModule],
  exports: [ApolloModule],
  providers: BaseGraphQLModule.providers(),
})
export class GraphQLModule extends BaseGraphQLModule {
  /**
   * The environment of the platform.
   */
  environment() {
    return environment;
  }

  /**
   * The options of the platform.
   */
  options() {
    return options;
  }
}
