import { NotificationInterface } from './notification.interface';
import { ButtonComponent } from '../../buttons/button/button.component';
import { ProfileImageComponent } from '../../profile/profile-image/profile-image.component';
import { NgFor, NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
  } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: "app-follow-request-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.css"],
  standalone: true,
  imports: [ProfileImageComponent, NgIf, NgFor, ButtonComponent],
})
export class FollowRequestNotificationComponent
  implements OnInit, NotificationInterface
{
  /**
   * Create a new instance of the component.
   */
  constructor(private http: HttpClient) {}

  /**
   * The action event of the notification.
   */
  @Output() action: EventEmitter<any> = new EventEmitter();

  /**
   * The actions of the notification.
   */
  actions: any[] = [
    {
      onClick: (e: any) => this.accept(e),
      title: "Accept",
    },
    {
      onClick: (e: any) => this.decline(e),
      title: "Decline",
    },
  ];

  /**
   * The has action state of the component.
   */
  hasAction = true;

  /**
   * The index of the notification.
   */
  @Input() index?: number;

  /**
   * The loading state of the component.
   */
  loading: boolean = false;

  /**
   * Mark the notification read on view.
   */
  markReadOn = "action";

  /**
   * The notification of the component.
   */
  @Input() notification: any = null;

  /**
   * On component init.
   */
  ngOnInit(): void {}

  /**
   * Accept the notification.
   */
  async accept(event: Event): Promise<void> {
    this.loading = true;
    event.preventDefault();
    event.stopPropagation();
    const id = this.notification.data.follow_id;

    try {
      await firstValueFrom(
        this.http.post(`follows/requests/${id}`, { accept: true })
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.action.emit(this.notification);
      this.loading = false;
    }
  }

  /**
   * Decline the notification.
   */
  async decline(event: Event): Promise<void> {
    this.loading = true;
    event.preventDefault();
    event.stopPropagation();

    const id = this.notification.data.follow_id;

    try {
      await firstValueFrom(
        this.http.post(`follows/requests/${id}`, { accept: false })
      );
    } catch (error) {
      console.error(error);
    } finally {
      this.action.emit(this.notification);
      this.loading = false;
    }
  }
}
