import * as Types from '../types';

import { gql } from 'apollo-angular';
import { MessageFragmentDoc } from './message.fragment.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type NewMessageSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type NewMessageSubscription = { __typename?: 'Subscription', newMessage?: { __typename?: 'Message', read_at?: any | null, content?: string | null, created_at?: any | null, id: string, message_group_id: string, user_id: string, media?: Array<{ __typename?: 'Media', id: string, mimetype?: string | null, mediable_id?: string | null, type?: string | null, url?: string | null, meta?: { __typename?: 'MediaMetaData', id: string, height?: number | null, width?: number | null } | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null, small?: string | null, medium?: string | null, large?: string | null, xLarge?: string | null } | null }> | null, user?: { __typename?: 'User', id: string, name?: string | null, username?: string | null, verified?: boolean | null, media?: Array<{ __typename?: 'Media', id: string, type?: string | null, url?: string | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null } | null }> | null } | null } | null };

export const NewMessageDocument = gql`
    subscription NewMessage {
  newMessage {
    ...Message
    read_at
  }
}
    ${MessageFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NewMessageGQL extends Apollo.Subscription<NewMessageSubscription, NewMessageSubscriptionVariables> {
    document = NewMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }