import { routes } from './app.routes';
import { AppErrorHandler } from './errors/app-error-handler';
import { GraphQLModule } from './graphql/graphql.module';
import { XsrfInterceptor } from './http/xsrf.interceptor';
import { initProvider } from './init';
import { environment, options } from '../environments/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { ModelsModule, RigShareCommonModule } from 'rigshare-common';
import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
} from "@angular/core";
import {
  Router,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from "@angular/router";
import {
  BrowserTracing,
  TraceService,
  init,
  instrumentAngularRouting,
} from "@sentry/angular-ivy";

if (environment.production || environment.staging) {
  init({
    dsn: "https://6523f97d3d064d3d997b619a81d0f01c@sentry.io/1227263",
    environment: environment.production ? "production" : "staging",
    integrations: [
      new BrowserTracing({
        tracingOrigins: [
          "https://api.staging.rigshare.com",
          "https://api.rigshare.com",
        ],
        routingInstrumentation: instrumentAngularRouting,
      }),
    ],
    tracesSampleRate: 0.25,
  });
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    provideServiceWorker("ngsw-worker.js", {
      enabled: !isDevMode(),
      registrationStrategy: "registerWhenStable:30000",
    }),
    {
      provide: TraceService,
      deps: [Router],
    },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    initProvider(),
    importProvidersFrom(GraphQLModule),
    importProvidersFrom(
      RigShareCommonModule.forRoot({
        ...options,
        environment,
        wordpressBaseUrl: environment.wordpressBaseUrl,
      }),
      ModelsModule
    ),
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        anchorScrolling: "enabled",
        scrollPositionRestoration: "enabled",
      }),
      withRouterConfig({
        paramsInheritanceStrategy: "always",
        // scrollOffset: () => {
        //   if (typeof document === "undefined") {
        //     return [0, 0];
        //   }

        //   const stickyElements = document.getElementsByClassName("sticky");
        //   let height = 0;

        //   Array.from(stickyElements).forEach((e: Element, i) => {
        //     if (!e.attributes.getNamedItem("stickyoffset")) {
        //       height += e.clientHeight;
        //     }
        //   });

        //   return [0, height];
        // },
      })
    ),
    { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
  ],
};
