<style>
  :host {
    display: block;
    width: 100%;
    height: 100%;
  }
</style>
<div
  class="image h-full"
  [ngClass]="{
    'aspect-w-16 aspect-h-9': !static && !ratio,
    'aspect-w-1 aspect-h-1':
      !static && ratio && ratio >= 100 && viewport.is('md'),
    'aspect-w-3 aspect-h-4':
      !static && ratio && ratio >= 133 && !viewport.is('md')
  }"
>
  <div class="flex h-full items-center justify-center">
    <img
      #img
      class="h-full w-full max-w-full"
      [ngClass]="{
        'opacity-0': !src
      }"
      (error)="onError($event)"
      loading="lazy"
      (load)="onLoaded($event)"
      [src]="src"
      [attr.alt]="alt"
      [id]="id"
      [style.objectFit]="objectFit"
      [attr.sizes]="sizes"
      [attr.srcset]="srcset"
      [height]="height"
      [width]="width"
    />
  </div>
</div>
