<div
  class="profile-image bg-neutral-200 rounded-full inline-block bg-cover bg-center relative overflow-hidden max-w-full"
  [ngClass]="{ 'has-image': model?.image, ' ring-white ring-2': border }"
  [class.upload-active]="uploadActive"
  [style.width]="width + 'px'"
  [style.height]="width + 'px'"
  [title]="model?.name"
>
  <a *ngIf="shouldLink" class="block" [routerLink]="route">
    <ng-container *ngTemplateOutlet="image"></ng-container>
  </a>

  <div *ngIf="!shouldLink">
    <ng-container *ngTemplateOutlet="image"></ng-container>
  </div>

  <a
    *ngIf="uploadable"
    class="upload-hover text-neutral-500-dark"
    href="#"
    (click)="$event.preventDefault(); upload.next()"
  >
    <div class="flex h-full w-full justify-center items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        [attr.width]="width * 0.5"
        [attr.height]="width * 0.5"
        viewBox="0 0 32 32"
        class="fill-current stroke-current"
      >
        <g>
          <path
            d="M0 9.2C0 7.44 1.44 6 3.2 6H8l3.2-3.2h9.6L24 6h4.8A3.2 3.2 0 0 1 32 9.2v16a3.2 3.2 0 0 1-3.2 3.2H3.2A3.2 3.2 0 0 1 0 25.2v-16zm16 16a8 8 0 1 0 0-16 8 8 0 1 0 0 16zm0-3.2a4.8 4.8 0 1 1 0-9.6 4.8 4.8 0 1 1 0 9.6z"
          ></path>
        </g>
      </svg>
    </div>
  </a>
</div>

<ng-template #image>
  <div *ngIf="src1" class="aspect-w-1 aspect-h-1">
    <app-image
      [alt]="model?.name"
      (loaded)="onLoaded($event)"
      [sizes]="sizes"
      [src1]="src1"
      [srcset]="srcset"
      [static]="true"
      [height]="width"
      [width]="width"
    >
    </app-image>
  </div>

  <div
    *ngIf="type === 'brands' && (!model || !profileImage || !profileImage?.url)"
    class="icon"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="1.4"
      clip-rule="evenodd"
      viewBox="0 0 640 640"
      [attr.width]="width"
    >
      <path fill="#ddd" d="M0 0h640v640H0z" />
      <path
        fill="#fff"
        fill-rule="nonzero"
        d="M320 413l-111 58 21-124-90-87 124-18 56-113 56 113 124 18-90 87 21 124-111-58z"
      />
    </svg>
  </div>

  <div
    class="icon"
    *ngIf="(type == 'users' || !type) && (!model || !profileImage?.url)"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill-rule="evenodd"
      stroke-linejoin="round"
      stroke-miterlimit="1.4"
      clip-rule="evenodd"
      viewBox="0 0 640 640"
      [attr.width]="width"
    >
      <path
        fill="#4e5158"
        fill-rule="nonzero"
        d="M320 640a320 320 0 110-640 320 320 0 010 640zm-96-448v64a96 96 0 00192 0v-64a96 96 0 00-192 0zM107 462a256 256 0 00425 1l1-1a502 502 0 00-429 1l3-1z"
      />
    </svg>
  </div>
</ng-template>
