import { MediaModel } from "../../../models";
import { ViewPortService } from "../../../services/viewport";
import { NgClass } from "@angular/common";
import { merge } from "rxjs";
import { tap } from "rxjs/operators";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-image",
  templateUrl: "./image.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
})
export class ImageComponent implements OnInit {
  /**
   * The alt tag of the image.
   */
  @Input() alt?: string;

  /**
   * Display the alt tag of the image.
   */
  @Input() displayAlt = true;

  /**
   * The error event of the image.
   */
  @Output() error: EventEmitter<void> = new EventEmitter();

  /**
   * Height of the image component.
   */
  _height?: string | number | void;

  @Input() get height(): string | number | void {
    if (this._height) {
      return this._height;
    }

    if (this.image?.meta?.height) {
      return this.image.meta.height;
    }
  }

  set height(value) {
    this._height = value;
  }

  /**
   * The id of the image.
   */
  @Input() id?: string;

  /**
   * The loaded state of the image.
   */
  isLoaded: boolean = false;

  /**
   * The img element of the component.
   */
  @ViewChild("img", { static: true }) img?: ElementRef;

  /**
   * The image of the component.
   */
  @Input() image?: MediaModel;

  /**
   * Size of the image to use in component.
   */
  @Input() imageSize = "medium";

  /**
   * The loaded event of the image.
   */
  @Output() loaded: EventEmitter<boolean> = new EventEmitter();

  /**
   * The model of the component.
   */
  @Input() model?: any;

  /**
   * The object fit of the image.
   */
  @Input() objectFit: string = "cover";

  /**
   * The image aspect ratio.
   */
  ratio?: number;

  /**
   * The sizes of the image.
   */
  @Input() sizes?: string;

  /**
   * The source of the image.
   */
  src?: string;

  /**
   * The source of the image.
   */
  @Input() src1?: string;

  /**
   * The source of the image.
   */
  @Input() src2?: string;

  /**
   * The source of the image.
   */
  @Input() srcset?: string | void;

  /**
   * The static state of the image's responive sizing.
   */
  @Input() static: boolean = false;

  /**
   * Width of the image component.
   */
  _width?: string | number | void;

  @Input() get width(): string | number | void {
    if (this._width) {
      return this._width;
    }

    if (this.image?.meta?.width) {
      return this.image.meta.width;
    }
  }

  set width(value) {
    this._width = value;
  }

  /**
   * Create a new instance of the component.
   */
  constructor(
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: string,
    public viewport: ViewPortService
  ) {}

  /**
   * On component init.
   */
  ngOnInit(): void {
    this.setId();
    this.setProperties();
  }

  /**
   * After view init.
   */
  ngAfterViewInit(): void {
    this.register();
  }

  /**
   * Load the image.
   */
  async load(): Promise<boolean> {
    return new Promise((resolve) => {
      if (this?.isLoaded) {
        return resolve(true);
      }

      const sub = merge(
        this.loaded.pipe(
          tap(() => {
            this.isLoaded = true;
            this.cd.detectChanges();
          })
        ),
        this.error
      ).subscribe((e) => {
        sub.unsubscribe();
        resolve(e === true);
        this.cd.detectChanges();
      });

      this.setSource();
      this.cd.detectChanges();
    });
  }

  /**
   * On loaded event handler.
   */
  onError(event: any): void {
    this.error.emit(event);
  }

  /**
   * On loaded event handler.
   */
  onLoaded(event: any): void {
    this.setAspectRatio();
    this.loaded.emit(event);
  }

  /**
   * Register the image with the image service.
   */
  register(): void {
    this.load();
  }

  /**
   * Set the alt attribute of the component.
   */
  setAlt() {
    if (!this.alt && this.displayAlt) {
      this.alt =
        this.model && this.model.user
          ? "Image posted by @" + this.model.user.username + " on RigShare"
          : "Image posted on RigShare";
    }
  }

  /**
   * Set the aspect ratio of the image.
   */
  setAspectRatio(): void {
    if (this.image?.meta?.height && this.image?.meta?.width) {
      this.ratio = this.image.meta.height / this.image.meta.width;
      return;
    }

    if (this.static) {
      return;
    }

    const image = this.img?.nativeElement;
    this.ratio = Math.round((image.clientHeight / image.clientWidth) * 100);
    this.cd.detectChanges();
  }

  /**
   * Set the id of the image.
   */
  setId(): void {
    this.id =
      "image-" +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
  }

  /**
   * Set the properties of the image.
   */
  setProperties() {}

  /**
   * Set source one of the image.
   */
  setSource() {
    if (this.src1) {
      this.src = this.src1;
      return;
    }

    if (this.image) {
      this.src =
        this.image.sizes && this.image.sizes[this.imageSize]
          ? this.image.sizes[this.imageSize]
          : this.image.url;
    }
  }

  /**
   * Set the srcset of the image.
   */
  setSrcset() {
    if (this.srcset) {
      return;
    }

    if (this.image?.sizes) {
      const sizes = [];

      if (this.image.sizes.small) {
        sizes.push(`${this.image.sizes.small} 320w`);
      }

      if (this.image.sizes.medium) {
        sizes.push(`${this.image.sizes.medium} 1280w`);
      }

      if (this.image.sizes.large) {
        sizes.push(`${this.image.sizes.large} 1920w`);
      }

      if (this.image.sizes.xLarge) {
        sizes.push(`${this.image.sizes.xLarge} 3840w`);
      }

      this.srcset = sizes.join(", ");
    }
  }
}
