<div
  class="flex items-start justify-start"
  [class.mr-2]="actions ? actions.length : null"
>
  <div class="mr-2">
    <app-profile-image
      [model]="notification?.sender"
      imageSize="thumbnail"
      width="30"
    >
    </app-profile-image>
  </div>

  <div class="whitespace-normal text-sm">
    <div *ngIf="notification?.data">
      {{ notification?.data?.message }}
    </div>

    <div class="text-neutral-500">
      <span class="unread" [class.hidden]="notification?.read_at">
        <div class="dot"></div>
      </span>
      <span>{{ notification?.timeAgo }} ago</span>
    </div>
  </div>

  <div
    *ngIf="actions && actions.length"
    class="pl-2 text-right"
    [hidden]="notification.read_at"
  >
    <app-button
      *ngFor="let action of actions"
      aria-label="Accept"
      class="mr-2 p-1"
      (click)="action.onClick($event)"
      size="small"
    >
      {{ action?.title }}
    </app-button>
  </div>
</div>
