import { Model } from "./model";
import { FollowPaginator } from "../graphql/types";

export class UserModel extends Model {
  /**
   * The categories of the user.
   */
  categories?: any[];

  /**
   * The id of the user.
   */
  id: any;

  /**
   * The following state of the user.
   */
  is_following?: string;

  /**
   * The following back state of the user.
   */
  is_following_back?: string;

  /**
   * The first name of the use.r
   */
  first_name?: string;

  /**
   * The number of followers of the user.
   */
  followers_count?: number;

  /**
   * Followers that are similar to the authenticated user.
   */
  followersYouKnow?: FollowPaginator;

  /**
   * The last name of the user.
   */
  last_name?: string;

  /**
   * The media of the model.
   */
  media?: any[];

  /**
   * The name of the user.
   */
  name?: string;

  /**
   * The profile of the user.
   */
  profile?: any;

  /**
   * Similar user products with the current user.
   */
  similarUserProducts: any;

  /**
   * The username of the user.
   */
  username?: string;
}
