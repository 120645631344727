<!-- Display conditional link -->
<ng-container *ngIf="displayLink">
  <a [routerLink]="notification?.route" class="block p-2 text-neutral-800">
    <ng-container *ngTemplateOutlet="notificationTemplate"></ng-container>
  </a>
</ng-container>

<ng-container *ngIf="!displayLink">
  <ng-container *ngTemplateOutlet="notificationTemplate"></ng-container>
</ng-container>

<ng-template #notificationTemplate>
  <ng-container [ngSwitch]="notification?.type">
    <app-follow-request-notification
      *ngSwitchCase="'FollowRequest'"
      #notificationComponent
      class="block"
      [notification]="notification"
      (action)="action.emit($event)"
    >
    </app-follow-request-notification>

    <app-notification
      *ngSwitchDefault
      #notificationComponent
      class="block"
      [notification]="notification"
    >
    </app-notification>
  </ng-container>
</ng-template>

<style>
  :host {
    display: block;
  }
</style>
