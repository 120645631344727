import { NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnInit,
  Output
  } from '@angular/core';
import { MessageGroupService } from 'rigshare-common';
import { BadgeComponent, ButtonComponent } from 'rigshare-common';

@Component({
  selector: "app-messages-dropdown",
  templateUrl: "./messages-dropdown.component.html",
  styleUrls: ["./messages-dropdown.component.css"],
  standalone: true,
  imports: [ButtonComponent, NgIf, BadgeComponent],
})
export class MessagesDropdownComponent implements OnInit {
  /**
   * The click event of the component.
   */
  @Output() click: EventEmitter<void> = new EventEmitter();

  /**
   * Return the route to view messages.
   */
  get route(): any[] {
    return ["/messages"];
  }

  /**
   * The unread count of the component.
   */
  get unreadCount(): number {
    return this.messageGroupService.unreadCount;
  }

  /**
   * Cretae a new instance of the component.
   */
  constructor(public messageGroupService: MessageGroupService) {}

  /**
   * On component init.
   */
  ngOnInit(): void {}
}
