import { EchoWebSocket } from '@spacestudio/laravel-echo-websocket';
import Echo from 'laravel-echo/dist/echo';
import { Observer } from 'rxjs';
import {
  ApolloLink,
  FetchResult,
  NextLink,
  Observable,
  Operation,
} from "@apollo/client/core";

export class WebSocketLink extends ApolloLink {
  /**
   * The Laravel Echo instance.
   */
  echo: any;

  /**
   * The subscriptions of the link.
   */
  subscriptions: Map<
    string,
    {
      channel: string | undefined;
      observer: Observer<any>;
    }
  > = new Map();

  /**
   * Create a new instance of the link.
   */
  constructor(url: string, options: any = {}) {
    super();

    this.echo = new Echo({
      broadcaster: EchoWebSocket.connector(url, options),
    });
  }

  /**
   * Get the channel name.
   */
  channel(data: any, operation?: any): string | void {
    if (!data?.extensions?.lighthouse_subscriptions?.channel) {
      return;
    }

    return data?.extensions?.lighthouse_subscriptions?.channel;
  }

  /**
   * Request data from the web socket server.
   */
  request(
    operation: Operation,
    forward?: NextLink | undefined
  ): Observable<FetchResult> {
    return new Observable((observer) => {
      if (!forward) {
        return;
      }

      forward(operation).subscribe({
        next: (data) => {
          const subscriptionChannel = this.channel(data, operation);

          if (subscriptionChannel) {
            this.subscribe(operation, subscriptionChannel, observer);
          } else {
            observer.next(data);
            observer.complete();
          }
        },
        error: (error) => {
          observer.error(error);
        },
      });
    });
  }

  /**
   * Create a websocket subscription.
   */
  subscribe(operation: Operation, subscriptionChannel: string, observer: any) {
    const privateChannelName = subscriptionChannel.split("private-").pop();
    const subscriptionKey = btoa(operation.operationName);

    if (this.subscriptions.has(subscriptionKey)) {
      this.echo.leave(this.subscriptions.get(subscriptionKey)?.channel);
      this.subscriptions.get(subscriptionKey)?.observer.complete();
    }

    if (!this.subscriptions.has(subscriptionChannel)) {
      this.subscriptions.set(subscriptionKey, {
        channel: privateChannelName,
        observer,
      });
    }

    this.echo
      .private(privateChannelName)
      .listen(".lighthouse-subscription", (payload: any) => {
        if (!payload.data.more || observer.closed) {
          this.unsubscribe(subscriptionKey);
          return;
        }

        if (payload.data.result) {
          observer.next({
            data: payload.data.result.data,
            extensions: payload.data.result.extensions,
          });
        }
      });
  }

  /**
   * Unsubscribe from the channel.
   */
  unsubscribe(subscriptionKey: string) {
    const subscription = this.subscriptions.get(subscriptionKey);
    subscription?.observer.complete();
    this.echo.leave(subscription?.channel);
    this.subscriptions.delete(subscriptionKey);
  }
}
