import { CommonConfig } from './common-config';
import { CacheService } from './services/cache/cache.service';
import { ConfigSerivce } from './services/config.service';
import { AuthInterceptor } from './services/http-auth-interceptor';
import { HttpInterceptor } from './services/http-interceptor';
import { HttpService } from './services/http.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  IDBStorageService,
  LocalStorageService,
  StorageService,
} from "./services/storage";
import {
  AnalyticsService,
  AuthenticationService,
  AuthorizationService,
  CookieStorageService,
  EventService,
  UserAuthorizationService,
} from "./services";

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ...[
      AnalyticsService,
      AuthenticationService,
      AuthorizationService,
      CacheService,
      ConfigSerivce,
      CookieStorageService,
      EventService,
      HttpService,
      IDBStorageService,
      LocalStorageService,
      StorageService,
      UserAuthorizationService,
    ],
  ],
})
export class RigShareCommonModule {
  public static forRoot(
    config: CommonConfig
  ): ModuleWithProviders<RigShareCommonModule> {
    return {
      ngModule: RigShareCommonModule,
      providers: [{ provide: "RIGSHARE_COMMON_CONFIG", useValue: config }],
    };
  }
}
