<style>
  :host {
    background: transparent;
    display: block;
    padding: 2px;
    border: none;
  }

  :host ::ng-deep app-button,
  :host ::ng-deep app-button button {
    height: 100%;
  }
</style>

<app-input-container
  [classes]="classes.group"
  [errors]="errors"
  [label]="label"
>
  <ng-container prepend>
    <ng-content select="[prepend]"></ng-content>
  </ng-container>

  <input
    [autocapitalize]="autocapitalize"
    [autocomplete]="autocomplete"
    (blur)="onBlur.next($event)"
    [ngClass]="classes.input"
    [disabled]="disabled"
    #element
    [attr.enterkeyhint]="enterkeyhint"
    [id]="id"
    (focus)="onFocus.next($event)"
    (keyup)="onKeyup.next($event)"
    (keydown)="onKeydown.next($event)"
    [(ngModel)]="value"
    (ngModelChange)="onInput()"
    [attr.name]="name"
    [placeholder]="placeholder"
    [required]="required"
    [readonly]="readonly"
    [type]="type"
  />

  <ng-container append>
    <ng-content select="[append]"></ng-content>
  </ng-container>
</app-input-container>
