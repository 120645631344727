import { Notification } from '../../../graphql';
import { NotificationModel } from '../../../models';
import { EventService, NotificationService } from '../../../services';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { ButtonComponent } from '../../buttons/button/button.component';
import { DropdownItemComponent } from '../../dropdown/dropdown-item/dropdown-item.component';
import { DropdownComponent } from '../../dropdown/dropdown.component';
import { NotificationDropdownItemComponent } from '../notification-dropdown-item/notification-dropdown-item.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Router } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";

@Component({
  selector: "app-notifications-dropdown",
  templateUrl: "./notifications-dropdown.component.html",
  styleUrls: ["./notifications-dropdown.component.css"],
  standalone: true,
  imports: [
    DropdownComponent,
    NgIf,
    NgFor,
    NotificationDropdownItemComponent,
    ButtonComponent,
    DropdownItemComponent,
    AsyncPipe,
  ],
})
export class NotificationsDropdownComponent implements OnInit, OnDestroy {
  /**
   * The click event of the component.
   */
  @Output() click: EventEmitter<void> = new EventEmitter();

  /**
   * The items of the list.
   */
  @ViewChildren(NotificationDropdownItemComponent)
  items?: QueryList<NotificationDropdownItemComponent>;

  /**
   * The notification list of the component.
   */
  @ViewChild("list", { static: false }) list?: ElementRef;

  /**
   * The loading state of the page.
   */
  get loading(): boolean {
    return this.notificationService.loading;
  }

  /**
   * The loading more state of the page.
   */
  get loadingMore(): boolean {
    return this.notificationService.loadingMore;
  }

  /**
   * The notifications observable of the page.
   */
  get notifications$(): Observable<Notification[] | undefined> | undefined {
    return this.notificationService.notifications$;
  }

  /**
   * The subscriotions of the page.
   */
  subs: Subscription = new Subscription();

  /**
   * The notification list of the component.
   */
  @ViewChild("toggle", { static: false }) toggle?: any;

  /**
   * The unread count of the component.
   */
  get unreadCount(): number {
    return this.notificationService.unreadCount;
  }

  $update: Subject<any> = new Subject();

  /**
   * Create a new instance of the component.
   */
  constructor(
    public auth: AuthenticationService,
    private cd: ChangeDetectorRef,
    public element: ElementRef,
    private event: EventService,
    public notificationService: NotificationService,
    public router: Router
  ) {}

  /**
   * On component init.
   */
  ngOnInit() {}

  /**
   * On component destroy.
   */
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Get more notifications.
   */
  async getMoreNotifications(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    if (!this.notificationService?.pageInfo?.hasNextPage) {
      return;
    }

    await this.notificationService.getMoreNotifications();
    this.cd.detectChanges();
  }

  /**
   * Mark all notifications as read.
   */
  markAllRead(event: Event): void {
    event.preventDefault();

    this.notificationService.markAllRead();
  }

  /**
   * Mark a notification as read.
   */
  markRead(notification: NotificationModel): NotificationModel {
    const date = new Date();
    notification.read_at = date.toString();
    this.notificationService.markRead(notification.id || "");
    this.cd.detectChanges();

    return notification;
  }

  /**
   * Mark a notification as read.
   */
  markReadOnScroll(element: any): void {
    this.markVisibleAsRead(element);
  }

  /**
   * Mark the visible list items as read.
   */
  markVisibleAsRead(event: any = null): void {
    const content = this.list?.nativeElement;
    const parentPosition = content.getBoundingClientRect();

    this.items?.toArray().forEach((item: any, index: number) => {
      if (!item?.notification?.read_at) {
        const element = this.list?.nativeElement.querySelectorAll(
          '[data-index="' + index + '"]'
        )[0];
        if (element) {
          const position = element.getBoundingClientRect();
          const top = position.top - parentPosition.top;

          if (
            top >= 0 &&
            top + element.clientHeight / 2 - content.clientHeight <=
              element.scrollHeight - element.clientHeight
          ) {
            const found = this.items
              ?.toArray()
              .find((_item: any, i: number) => i === index);

            if (
              found?.notificationItemComponent?.notificationComponent
                ?.markReadOn === "view"
            ) {
              if (found.notification && !found.notification?.read_at) {
                found.notification = this.markRead(found.notification);
              }
            }
          }
        }
      }
    });
  }

  /**
   * Click event handler.
   */
  onToggleClick(): void {
    const element = this.element.nativeElement;

    if (!element.classList.contains("show")) {
      setTimeout(() => this.markVisibleAsRead());
    }

    this.click.next();
  }

  trackByFn(index: number, item: any): number {
    return item.id;
  }
}
