import * as Types from '../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type UnreadMessagesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UnreadMessagesQuery = { __typename?: 'Query', messages: { __typename?: 'MessageConnection', pageInfo: { __typename?: 'PageInfo', total: number } } };

export const UnreadMessagesDocument = gql`
    query UnreadMessages {
  messages(first: 1, unread: true) {
    pageInfo {
      total
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnreadMessagesGQL extends Apollo.Query<UnreadMessagesQuery, UnreadMessagesQueryVariables> {
    document = UnreadMessagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }