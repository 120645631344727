import { AuthenticationService } from "./authentication/authentication.service";
import { EventService } from "./event.service";
import { NewMessageGQL } from "../graphql/messages/new-message.generated";
import { UnreadMessagesGQL } from "../graphql/messages/unread-messages.generated";
import { MessageModel } from "../models/message.model";
import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from "@angular/core";
import { Subject, Subscription } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MessageGroupService implements OnDestroy {
  /**
   * The initialized state of the service.
   */
  initialized?: boolean;

  /**
   * The listening state of messages.
   */
  listening = false;

  /**
   * The loading state of the service.
   */
  loading: boolean = false;

  /**
   * The new message subscription.
   */
  newMessageSub?: Subscription;

  /**
   * The subscriptions of the service.
   */
  subs: Subscription = new Subscription();

  /**
   * The count of unread messages.
   */
  unreadCount = 0;

  /**
   * The subject for message updates.
   */
  update: Subject<{ type: string; message: MessageModel }> = new Subject();

  /**
   * Create a new instance of the service.
   */
  constructor(
    private auth: AuthenticationService,
    private event: EventService,
    private newMessageGQL: NewMessageGQL,
    private unreadMessagesGQL: UnreadMessagesGQL,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.init();
    }
  }

  /**
   * On service destroy.
   */
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Clear the message groups of the sevice
   */
  clear(): void {
    this.initialized = false;
    this.unreadCount = 0;
    this.loading = false;
  }

  /**
   * Destroy the service.
   */
  destroy(): void {
    this.clear();
    this.newMessageSub?.unsubscribe();
    this.listening = false;
  }

  /**
   * Get the unread count of messages.
   */
  getUnreadCount() {
    this.unreadMessagesGQL.fetch().subscribe({
      next: ({ data }) => {
        this.unreadCount = data?.messages?.pageInfo?.total || 0;
      },
    });
  }

  /**
   * Initialize the service.
   */
  init(): void {
    if (this.auth.user()) {
      this.getUnreadCount();
      this.listenForNewMessages();
      this.initialized = true;
    }

    this.event.listen("auth:loggedIn").subscribe(() => {
      if (this.initialized) {
        return;
      }

      this.getUnreadCount();
      this.listenForNewMessages();
      this.initialized = true;
    });

    this.event.listen("auth:loggedOut").subscribe(() => {
      this.destroy();
    });
  }

  /**
   * Listen for new messages.
   */
  listenForNewMessages(): void {
    this.listening = true;

    this.newMessageSub = this.newMessageGQL.subscribe().subscribe({
      next: ({ data }) => {
        const message = new MessageModel(data?.newMessage);

        if (!message.read_at) {
          this.unreadCount++;
        }

        this.update.next({
          type: "new",
          message,
        });
      },
    });
  }

  /**
   * Reset the state of the serivce.
   */
  reset(): void {
    this.clear();
    this.init();
  }
}
