import * as Types from '../types';

import { gql } from 'apollo-angular';
import { NotificationFieldsFragmentDoc } from './notification-fields.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type NotificationsQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['String']>;
  perPage?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type NotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationConnection', edges: Array<{ __typename?: 'NotificationEdge', node: { __typename?: 'Notification', created_at?: any | null, data?: any | null, id?: string | null, read_at?: any | null, type?: string | null, sender?: { __typename?: 'User', id: string, name?: string | null, username?: string | null, media?: Array<{ __typename?: 'Media', id: string, type?: string | null, url?: string | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null } | null }> | null } | null, user?: { __typename?: 'User', id: string, name?: string | null, username?: string | null, media?: Array<{ __typename?: 'Media', id: string, type?: string | null, url?: string | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null } | null }> | null } | null } }>, pageInfo: { __typename?: 'PageInfo', currentPage: number, endCursor?: string | null, hasNextPage: boolean, lastPage: number } } };

export const NotificationsDocument = gql`
    query Notifications($after: String, $perPage: Int = 15) {
  notifications(after: $after, first: $perPage) {
    edges {
      node {
        ...NotificationFields
      }
    }
    pageInfo {
      currentPage
      endCursor
      hasNextPage
      lastPage
    }
  }
}
    ${NotificationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationsGQL extends Apollo.Query<NotificationsQuery, NotificationsQueryVariables> {
    document = NotificationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }