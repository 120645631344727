import * as Types from '../types';

import { gql } from 'apollo-angular';
export type NotificationFieldsFragment = { __typename?: 'Notification', created_at?: any | null, data?: any | null, id?: string | null, read_at?: any | null, type?: string | null, sender?: { __typename?: 'User', id: string, name?: string | null, username?: string | null, media?: Array<{ __typename?: 'Media', id: string, type?: string | null, url?: string | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null } | null }> | null } | null, user?: { __typename?: 'User', id: string, name?: string | null, username?: string | null, media?: Array<{ __typename?: 'Media', id: string, type?: string | null, url?: string | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null } | null }> | null } | null };

export const NotificationFieldsFragmentDoc = gql`
    fragment NotificationFields on Notification {
  created_at
  data
  id
  read_at
  sender {
    id
    media {
      id
      type
      url
      sizes {
        id
        thumbnail
      }
    }
    name
    username
  }
  type
  user {
    id
    media {
      id
      type
      url
      sizes {
        id
        thumbnail
      }
    }
    name
    username
  }
}
    `;