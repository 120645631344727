import { Model } from "./model";
import { Term } from "../graphql/types";

export class ProductModel extends Model {
  /**
   * The brand of the product.
   */
  brand: any;

  /**
   * The id of the brand.
   */
  brand_id?: string | undefined;

  /**
   * Get the breadcrumb list of the model.
   */
  get breadCrumbList(): any[] {
    return this.categories?.filter((category) => category.parent) || [];
  }

  /**
   * The categories of the model.
   */
  categories?: Term[];

  /**
   * The deleted at timestamp of the model.
   */
  deleted_at?: string;

  /**
   * Text that describes the product.
   */
  description?: string;

  /**
   * The features of the product.
   */
  features?: string;

  /**
   * The id of the model.
   */
  id?: string;

  /**
   * The images of the model.
   */
  get images(): any[] {
    return (
      this.media
        ?.filter((media) => media.type === "images")
        .sort((a, b) => (a.sequence || a.id) - (b.sequence || b.id)) || []
    );
  }

  /**
   * The saved state of the product.
   */
  is_saved?: boolean;

  /**
   * Links attached the product.
   */
  links?: any[];

  /**
   * The media of the model.
   */
  media?: any[];

  /**
   * The name of the product.
   */
  name?: string;

  /**
   * The price of teh product.
   */
  price?: number;

  /**
   * The purchase links of the product.
   */
  purchase_links?: any[];

  /**
   * The number of reviews on the product.
   */
  reviews_count?: number;

  /**
   * The value of all the reviews on the product.
   */
  reviews_value?: number;

  /**
   * The route of the model.
   */
  route?: any[];

  /**
   * The amount of times the product has been shared.
   */
  shares_count?: number;

  /**
   * The store keeping unit.
   */
  sku?: string;

  /**
   * The slug of the product.
   */
  slug?: string;

  /**
   * The specifications of the product.
   */
  specifications?: string;

  /**
   * The status of the product.
   */
  status?: string;

  /**
   * The updated at timestamp.
   */
  updated_at?: string;

  /**
   * Users that use the product.
   */
  users: any;

  /**
   * The total number of users of the product.
   */
  users_count?: number;

  /**
   * Handle model booted.
   */
  booted() {
    this.route = ["/products", this?.slug];
  }
}
