import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  Inject,
  Injectable,
  OnDestroy,
  PLATFORM_ID
  } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class TrackingService implements OnDestroy {
  /**
   * Create a new instance of the service.
   */
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.init();
  }

  /**
   * The event tracking queue.
   */
  eventQueue: any[] = [];

  /**
   * The event queue subject.
   */
  eventQueueSubject: Subject<void> = new Subject();

  /**
   * The initialized state of the service.
   */
  initialized = false;

  /**
   * The page tracking queue.
   */
  pageQueue: any[] = [];

  /**
   * The page queue subject.
   */
  pageQueueSubject: Subject<void> = new Subject();

  /**
   * The request observable.
   */
  requestObservable?: Observable<any>;

  /**
   * The subscriptions of the service.
   */
  subs: Subscription = new Subscription();

  /**
   * On service destroy.
   */
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Clear the event request queue.
   */
  clearEventQueue(): void {
    this.pageQueue = [];
  }

  /**
   * Clear the page request queue.
   */
  clearPageQueue(): void {
    this.pageQueue = [];
  }

  /**
   * Track an event.
   */
  event(event: any): void {
    this.eventQueue.push(event);
    this.eventQueueSubject.next();
  }

  /**
   * Initialize the service.
   */
  init(): void {
    if (!isPlatformBrowser(this.platformId) || this.initialized) {
      return;
    }

    this.initQueues();
  }

  /**
   * Initialize the queues of the service.
   */
  initQueues(): void {
    const eventQueue = this.eventQueueSubject
      .pipe(debounceTime(3000))
      .subscribe(() => this.postEventQueue());

    const pageQueue = this.pageQueueSubject
      .pipe(debounceTime(3000))
      .subscribe(() => this.postPageQueue());

    this.subs.add(eventQueue);
    this.subs.add(pageQueue);

    window.onbeforeunload = () => {
      this.postEventQueue();
      this.postPageQueue();
    };
  }

  /**
   * Track a page view.
   */
  page(page: any): void {
    this.pageQueue.push(page);
    this.pageQueueSubject.next();
  }

  /**
   * Post data to the api.
   */
  post(endpoint: string, data: any): Observable<any> {
    return this.http.post(endpoint, data);
  }

  /**
   * Post the event queue to the api.
   */
  postEventQueue(): void {
    if (this.eventQueue.length) {
      this.post("analytics/event", { event: this.eventQueue }).subscribe(() =>
        this.clearEventQueue()
      );
    }
  }

  /**
   * Post the page queue to the api.
   */
  postPageQueue(): void {
    if (this.pageQueue.length) {
      this.post("analytics/page", { page: this.pageQueue }).subscribe(() =>
        this.clearPageQueue()
      );
    }
  }
}
