<div
  class="fixed inset-0 z-50 h-screen w-screen bg-white p-3 opacity-0 transition-transform lg:relative lg:h-auto lg:w-auto lg:translate-x-0 lg:p-0 lg:opacity-100 lg:transition-none"
  [class.translate-x-0]="opened"
  [class.translate-x-full]="!opened"
  [class.opacity-100]="opened"
>
  <div class="flex justify-end lg:hidden">
    <button
      class="collapsed border-0 px-2 text-xl text-neutral-700"
      (click)="close()"
      type="button"
    >
      <i class="app-icon-close align-middle"></i>
    </button>
  </div>

  <ul class="my-0 ml-auto block justify-end font-bold tracking-wider lg:flex">
    <li *ngIf="!auth.user()" class="px-0 md:p-2">
      <a
        class="block items-center px-4 py-2 text-black hover:text-neutral-600 lg:inline-block"
        routerLink="/sign-up"
        (click)="close()"
      >
        Sign Up
      </a>
    </li>

    <li *ngIf="!auth.user()" class="px-0 md:p-2">
      <a
        class="block items-center px-4 py-2 text-black hover:text-neutral-600 lg:inline-block"
        routerLink="/sign-in"
        (click)="close()"
      >
        Log In
      </a>
    </li>

    <li
      *ngIf="auth.user() && viewportService.is('lg')"
      class="hidden items-center lg:flex"
    >
      <app-button
        color="clear"
        [route]="['/search']"
        size="small"
        title="Explore"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 31 31"
        >
          <g fill="currentColor">
            <path
              d="M15.36 13.92a1.44 1.44 0 1 0 0 2.88 1.44 1.44 0 1 0 0-2.88z"
            ></path>
            <path
              d="M15.36 2.88c-6.88 0-12.48 5.6-12.48 12.48s5.6 12.48 12.48 12.48 12.48-5.6 12.48-12.48-5.6-12.48-12.48-12.48z m6.3 6.8l-2.81 7.03a3.84 3.84 0 0 1-2.14 2.14l-7.03 2.81a0.48 0.48 0 0 1-0.62-0.62l2.81-7.03a3.84 3.84 0 0 1 2.14-2.14l7.03-2.81a0.48 0.48 0 0 1 0.62 0.62z"
            ></path>
          </g>
        </svg>
      </app-button>
    </li>

    <li
      *ngIf="auth.user() && viewportService.is('lg')"
      class="hidden items-center lg:flex"
    >
      <app-messages-dropdown class="flex h-full items-center">
      </app-messages-dropdown>
    </li>

    <li
      *ngIf="auth.user() && viewportService.is('lg')"
      class="hidden items-center lg:flex"
    >
      <app-notifications-dropdown (click)="close()">
      </app-notifications-dropdown>
    </li>

    <li *ngIf="auth.user()" class="hidden items-center px-0 lg:flex">
      <app-dropdown buttonSize="small" direction="right">
        <span class="h-full" text>
          <app-profile-image
            [model]="auth.user()"
            imageSize="thumbnail"
            width="30"
          >
          </app-profile-image>
        </span>

        <ng-container items>
          <app-dropdown-header>Account</app-dropdown-header>

          <app-dropdown-item [route]="['/', auth.user().username]">
            Profile
          </app-dropdown-item>

          <app-dropdown-item [route]="['/', auth.user().username, 'rigs']">
            My Rigs
          </app-dropdown-item>

          <app-dropdown-item [route]="['/connect']">
            Connect
          </app-dropdown-item>

          <app-dropdown-item [route]="['/settings/account']">
            Settings
          </app-dropdown-item>

          <ng-container *ngIf="auth.user().brands?.length">
            <app-dropdown-divider></app-dropdown-divider>

            <app-dropdown-header>Brand Listings</app-dropdown-header>

            <app-dropdown-item
              *ngFor="let brand of auth.user().brands"
              [route]="['/brands', brand.username]"
            >
              {{ brand.name }}
            </app-dropdown-item>
          </ng-container>

          <app-dropdown-divider></app-dropdown-divider>

          <app-dropdown-item (onClick)="logout($event)">
            <span class="font-medium"> Log Out </span>
          </app-dropdown-item>
        </ng-container>
      </app-dropdown>
    </li>

    <!-- <li
      *ngIf="auth.user()"
      class="btn-group hidden items-center border-l lg:flex"
    >
      <app-dropdown
        buttonSize="small"
        class="hidden lg:block"
        direction="right"
      >
        <span
          class="dropdown text-2xl"
          (onClick)="close()"
          href="#"
          title="Help & Suppot"
          text
        >
          <i class="app-icon-question align-middle"></i>
        </span>

        <ng-container items>
          <app-dropdown-header>Help & Support</app-dropdown-header>

          <app-dropdown-divider></app-dropdown-divider>

          <app-dropdown-item (onClick)="reportProblem($event)">
            Report a Problem
          </app-dropdown-item>
        </ng-container>
      </app-dropdown>
    </li> -->
  </ul>

  <ul
    *ngIf="auth.user()"
    class="my-0 ml-auto flex flex-col justify-end tracking-wider lg:hidden"
  >
    <li class="block lg:hidden">
      <a
        class="flex items-center p-2 text-neutral-700 lg:inline-block"
        [routerLink]="['/', auth.user().username]"
        (click)="close()"
      >
        <app-profile-image
          class="mr-2"
          [model]="auth.user()"
          imageSize="thumbnail"
          width="24"
        >
        </app-profile-image>
        Profile
      </a>
    </li>

    <li class="block lg:hidden">
      <a
        class="flex items-center p-2 text-neutral-700 lg:inline-block"
        [routerLink]="['/', auth.user().username, 'rigs']"
        (click)="close()"
      >
        <i class="app-icon-list mr-2 align-middle text-2xl"></i>My Rigs
      </a>
    </li>

    <li class="block lg:hidden">
      <a
        class="flex items-center p-2 text-neutral-700"
        [routerLink]="['/search']"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 31 31"
          class="mr-2"
        >
          <g fill="currentColor">
            <path
              d="M15.36 13.92a1.44 1.44 0 1 0 0 2.88 1.44 1.44 0 1 0 0-2.88z"
            ></path>
            <path
              d="M15.36 2.88c-6.88 0-12.48 5.6-12.48 12.48s5.6 12.48 12.48 12.48 12.48-5.6 12.48-12.48-5.6-12.48-12.48-12.48z m6.3 6.8l-2.81 7.03a3.84 3.84 0 0 1-2.14 2.14l-7.03 2.81a0.48 0.48 0 0 1-0.62-0.62l2.81-7.03a3.84 3.84 0 0 1 2.14-2.14l7.03-2.81a0.48 0.48 0 0 1 0.62 0.62z"
            ></path>
          </g>
        </svg>
        Explore
      </a>
    </li>

    <li class="block lg:hidden">
      <a
        class="flex items-center p-2 text-neutral-700 lg:inline-block"
        routerLink="/notifications"
        (click)="close()"
      >
        <div class="mr-auto">
          <i class="app-icon-notification mr-2 align-middle text-2xl"></i>
          Notifications
        </div>

        <div *ngIf="notificationUnreadCount">
          <span
            class="inline-block rounded-full bg-blue-500 px-2 py-1 text-xs text-white"
          >
            {{ notificationUnreadCount }}
          </span>
        </div>
      </a>
    </li>

    <li *ngIf="auth.user()" class="block">
      <a
        class="flex items-center p-2 text-neutral-700 lg:inline-block"
        routerLink="/messages"
        (click)="close()"
      >
        <div class="mr-auto">
          <i class="app-icon-envelope mr-2 align-middle text-2xl"></i>Messages
        </div>

        <div *ngIf="messageUnreadCount">
          <app-badge size="x-small">
            {{ messageUnreadCount }}
          </app-badge>
        </div>
      </a>
    </li>

    <li *ngIf="auth.user()" class="block">
      <a
        class="flex items-center p-2 text-neutral-700 lg:inline-block"
        routerLink="/connect"
        (click)="close()"
      >
        <div class="mr-auto">
          <i class="app-icon-add-user mr-2 align-middle text-2xl"></i>Connect
        </div>
      </a>
    </li>

    <li class="block">
      <a
        class="flex items-center p-2 text-neutral-700 lg:inline-block"
        [routerLink]="['/settings/account']"
        (click)="close()"
      >
        <i class="app-icon-cog mr-2 align-middle text-2xl"></i>Settings
      </a>
    </li>

    <ng-container *ngIf="auth.user().brands?.length">
      <li class="block">
        <hr class="my-2" />

        <h6 class="dropdown-header p-2">Brand Listings</h6>
      </li>

      <li *ngFor="let brand of auth.user().brands" class="block">
        <a
          class="flex items-center p-2"
          [routerLink]="['/brands', brand.username]"
          (click)="close()"
        >
          <i class="app-icon-star mr-2 align-middle text-2xl"></i>
          {{ brand.name }}
        </a>
      </li>
    </ng-container>

    <li class="block">
      <hr class="my-2" />

      <a
        class="p-2 font-medium text-neutral-700"
        href="#"
        (click)="logout($event); close()"
      >
        Log Out
      </a>
    </li>
  </ul>
</div>
