<app-header appStickyTop></app-header>

<div class="min-h-[calc(100vh-57px)] grow">
  <div class="page-container">
    <router-outlet></router-outlet>
  </div>
</div>

<app-footer></app-footer>
<router-outlet name="modal"></router-outlet>
<app-alerts></app-alerts>
