import { NgClass, NgIf } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { InputComponent } from "rigshare-common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "header-search",
  templateUrl: "./header-search.component.html",
  styleUrls: ["./header-search.component.css"],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [InputComponent, FormsModule, NgIf, NgClass],
})
export class HeaderSearchComponent implements OnInit, OnDestroy {
  /**
   * The blur event of the component.
   */
  @Output() blurred: EventEmitter<void> = new EventEmitter();

  /**
   * The close event of the component.
   */
  @Output() close: EventEmitter<void> = new EventEmitter();

  /**
   * The enter event of the component.
   */
  @Output() enter: EventEmitter<boolean> = new EventEmitter();

  /**
   * The input of the component.
   */
  @ViewChild("input", { static: false }) input?: InputComponent;

  /**
   * The focus event of the component.
   */
  @Output() focused: EventEmitter<void> = new EventEmitter();

  /**
   * The large state of the component.
   */
  @Input() large = false;

  /**
   * The typing event of the component.
   */
  @Output() navigation: EventEmitter<string> = new EventEmitter();

  /**
   * The query string of the component.
   */
  query: string = "";

  /**
   * The searching state of the component.
   */
  searching?: boolean;

  /**
   * The timeouts of the component.
   */
  timeouts: any = {};

  /**
   * The typing event of the component.
   */
  @Output() type: EventEmitter<string> = new EventEmitter();

  /**
   * Create a new instance of the component.
   */
  constructor(private cd: ChangeDetectorRef) {}

  /**
   * On component init.
   */
  ngOnInit(): void {}

  /**
   * On component destroy.
   */
  ngOnDestroy(): void {
    Object.keys(this.timeouts).forEach((k) => clearTimeout(this.timeouts[k]));
  }

  /**
   * Blur the input element.
   */
  blur(): void {
    this.input?.blur();
    setTimeout(() => this.blurred.next(), 250);
  }

  /**
   * Clear results.
   */
  clear(event?: any): void {
    this.timeouts["clear"] = setTimeout(
      () => {
        if (!event) {
          this.query = "";
        }
        this.close.next();
      },
      event ? 100 : 0
    );
  }

  /**
   * Focus the search input.
   */
  focus(e: Event): void {
    this.input?.focus();
  }

  /**
   * On blur event handler.
   */
  onBlur(): void {
    this.timeouts["onBlur"] = setTimeout(() => {
      this.searching = false;
      this.blurred.next();
      this.cd.detectChanges();
    }, 100);
  }

  /**
   * On focus event handler.
   */
  onFocus(): void {
    this.focused.next();
    this.searching = true;
    this.cd.detectChanges();
  }

  /**
   * Event handler for keydown events
   */
  onKeyDown(e: KeyboardEvent): void {
    if (["ArrowUp", "ArrowDown"].indexOf(e.key) >= 0) {
      e.preventDefault();
    }

    return;
  }

  /**
   * Event handler for keyup events
   */
  onKeyUp(e: KeyboardEvent): void {
    if (
      ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.key) >= 0
    ) {
      this.navigation.emit(e.key.replace("Arrow", "").toLowerCase());
      return;
    }

    if (e.key === "Enter") {
      this.enter.emit(true);

      return;
    }

    this.type.next(this.query);
  }
}
