import { InMemoryCache } from '@apollo/client/core';
import { relayStylePagination } from '@apollo/client/utilities';

export function cache() {
  return new InMemoryCache({
    possibleTypes: {
      ActivitySource: ["Post", "Review", "UserProduct"],
      Likeable: ["Comment", "Post", "Product", "UserProduct"],
      Shareable: ["Post", "Product", "Rig", "UserProduct"],
    },
    typePolicies: {
      Query: {
        fields: {
          activity: relayStylePagination(),
          followSuggestions: relayStylePagination(),
          likes: relayStylePagination(["likeable_id", "likeable_type"]),
          messageGroups: relayStylePagination(),
          messageGroupMessages: relayStylePagination(["message_group_id"]),
          posts: relayStylePagination(["orderBy", "trending"]),
          notifications: relayStylePagination(),
          productReviewSuggestions: relayStylePagination(),
          rigs: relayStylePagination(["user_id", "orderBy"]),
          userReviews: relayStylePagination(["id"]),
          users: relayStylePagination(["search", "user_type"]),
          userPosts: relayStylePagination(["id", "orderBy"]),
          userProducts: relayStylePagination(["rig_id", "orderBy"]),
        },
      },
      Post: {
        fields: {
          media: { merge: false },
        },
      },
      Product: {
        fields: {
          categories: { merge: false },
        },
      },
      User: {
        fields: {
          categories: { merge: false },
          followSuggestions: { merge: false },
        },
      },
      UserProduct: {
        fields: {
          media: { merge: false },
        },
      },
    },
  });
}
