<app-button
  (onClick)="click.next()"
  color="clear"
  [route]="route"
  size="small"
  title="Messages"
>
  <div class="relative">
    <i class="app-icon-envelope align-middle text-2xl"></i>

    <app-badge
      class="absolute -right-4 -top-1"
      *ngIf="unreadCount > 0"
      rounded="circle"
      [style.fontSize.px]="unreadCount >= 10 ? 8.5 : null"
      size="x-small"
    >
      <span>
        {{ unreadCount <= 9 ? unreadCount : "9+" }}
      </span>
    </app-badge>
  </div>
</app-button>
