<style>
  :host {
    display: block;
  }
</style>
<div
  class="product-image w-full"
  [class.rounded]="hasBorder"
  [class.border]="hasBorder"
  [class.overflow-hidden]="hasBorder"
  [ngClass]="{ 'has-image': product?.images?.length }"
  [title]="product?.name"
  [style.min-width.px]="width"
  [style.height.px]="width !== '100%' && aspect === '1:1' ? width : null"
>
  <div
    class="image"
    [ngClass]="{
      'aspect-w-4 aspect-h-3': aspect === '4:3',
      'aspect-w-1 aspect-h-1': aspect === '1:1',
      'aspect-w-16 aspect-h-9': aspect === '16:9'
    }"
  >
    <div class="overflow-hidden h-full flex items-center justify-center">
      <app-image
        *ngIf="src"
        [alt]="alt || product?.name"
        [id]="id"
        [imageSize]="imageSize"
        (loaded)="onLoaded($event)"
        [objectFit]="contain ? 'contain' : 'cover'"
        [src1]="src"
        [srcset]="srcset"
        [static]="true"
        [style.backgroundColor]="backgroundColor"
        [style.textIndent]="'-999999px'"
        [width]="width"
        [height]="height"
      ></app-image>
    </div>
  </div>
</div>
