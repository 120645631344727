<footer class="bg-black text-white">
  <div class="p-4 tracking-wider lg:p-8">
    <div class="grid grid-cols-12 gap-x-4">
      <div class="col-span-12 mb-4 md:col-span-6 lg:col-span-12 xl:col-span-4">
        <div class="mb-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill-rule="evenodd"
            stroke-linejoin="round"
            stroke-miterlimit="2"
            clip-rule="evenodd"
            viewBox="0 0 899 152"
            width="225"
            height="38"
          >
            <g fill="#fff">
              <path
                d="M151 15c0-8-6-15-15-15H15C7 0 0 7 0 15v121c0 9 7 15 15 15h121c9 0 15-6 15-15V15zM45 55a4 4 0 00-7 3v58a4 4 0 004 4h67a4 4 0 003-7L45 55zm-5-17a4 4 0 012-7 1219 1219 0 0141 1c5 1 9 2 13 5 3 2 6 5 8 9a29 29 0 014 12c0 11-6 19-14 24l-5-1-49-43z"
              />
              <path
                fill-rule="nonzero"
                d="M217 71h4c5 0 9-1 11-3s4-5 4-8c0-4-2-6-4-8s-6-3-11-3h-4v22zm51 49h-29l-22-34v34h-23V31h36l13 2a25 25 0 0115 15l2 11c0 6-2 12-5 16-4 4-8 7-15 9l28 36zM291 31h23v89h-23zM381 71h44v12a43 43 0 01-9 22l-10 8c-3 3-7 4-11 6a51 51 0 01-32-2 41 41 0 01-23-23c-2-6-4-12-4-18 0-7 2-13 4-19a40 40 0 0123-23c5-2 12-3 18-3 9 0 17 2 24 6 6 4 12 9 16 17l-21 9c-2-5-5-8-8-10s-7-3-11-3l-9 2-6 5-5 8-2 11 2 10 4 8 7 5c3 2 6 2 10 2h6l5-3 4-4 3-6h-19V71zM498 55l-7-4-7-2-7 2c-2 2-3 3-3 6l2 3 3 3 5 2 5 1c8 3 13 6 16 10 4 4 5 9 5 16l-2 11c-1 4-4 7-6 9-3 3-7 5-11 6-4 2-8 2-14 2-10 0-20-3-29-9l9-18 10 7 9 2c4 0 7-1 8-3 2-1 3-3 3-5l-1-4-2-2-4-2a136 136 0 00-14-6l-8-4-5-7-2-10 2-12a25 25 0 0115-14l12-2a53 53 0 0127 7l-9 17zM556 66h34V31h23v89h-23V84h-34v36h-23V31h23v35zM692 87l-10-30-10 30h20zm7 18h-34l-5 15h-25l34-89h26l34 89h-25l-5-15zM774 71h5c4 0 8-1 10-3 3-2 4-5 4-8 0-4-1-6-4-8-2-2-6-3-10-3h-5v22zm52 49h-29l-23-34v34h-23V31h36l13 2a25 25 0 0115 15l2 11c0 6-2 12-5 16s-8 7-15 9l29 36zM899 51h-28v15h28v19h-28v16h28v19h-51V31h51v20z"
              />
            </g>
          </svg>
        </div>

        <div class="hidden max-w-md text-sm md:block">
          RigShare is a music gear community where people can share and talk all
          things gear related. Create an account today to share your music gear
          and follow your friends & favorite musicians.
        </div>
      </div>

      <div
        *ngFor="let menuLink of menuLinks"
        class="col-span-12 mb-4 px-0 md:col-span-6 lg:col-span-3 xl:col-span-2"
      >
        <div class="flex flex-col">
          <div class="nav-item">
            <h4 class="m-0 px-3 py-2 font-bold text-white">
              {{ menuLink.text }}
            </h4>
          </div>

          <div *ngFor="let link of menuLink.children">
            <a
              *ngIf="link.route"
              class="group block px-2 py-2 text-blue-100 hover:text-neutral-900"
              [routerLink]="link.route"
            >
              <span class="px-1 group-hover:bg-blue-100">
                <i
                  *ngIf="link.icon"
                  class="align-middle"
                  [ngClass]="'app-icon-' + link.icon"
                ></i>
                {{ link.text }}
              </span>
            </a>

            <a
              *ngIf="link.href"
              class="group block px-2 py-2 text-blue-100 hover:text-neutral-900"
              [href]="link.href"
              [rel]="link.rel"
              [target]="link.target"
            >
              <span class="px-1 group-hover:bg-blue-100">
                <i
                  *ngIf="link.icon"
                  class="align-middle"
                  [ngClass]="'app-icon-' + link.icon"
                ></i>
                {{ link.text }}
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="legal block px-4 text-sm lg:flex lg:py-8">
    <div class="block lg:flex">
      <div class="nav-item">
        <a
          class="inline-block px-4 py-2 text-white hover:text-blue-100"
          [routerLink]="['/']"
        >
          &copy; {{ date.getFullYear() }} RigShare
        </a>
      </div>
      <div class="nav-item">
        <a
          class="inline-block px-4 py-2 text-white hover:text-blue-100"
          [routerLink]="['/terms']"
        >
          Terms
        </a>
      </div>

      <div class="nav-item">
        <a
          class="inline-block px-4 py-2 text-white hover:text-blue-100"
          [routerLink]="['/privacy']"
        >
          Privacy
        </a>
      </div>

      <div class="nav-item">
        <a
          class="inline-block px-4 py-2 text-white hover:text-blue-100"
          [routerLink]="['/community-guidelines']"
        >
          Community Guidelines
        </a>
      </div>

      <div class="nav-item">
        <a
          class="inline-block px-4 py-2 text-white hover:text-blue-100"
          [routerLink]="['/sitemap']"
        >
          Sitemap
        </a>
      </div>
    </div>

    <div class="flex">
      <div class="nav-item">
        <a
          class="inline-block px-4 py-2 text-white hover:text-blue-100"
          href="https://spacestud.io"
          target="_blank"
        >
          Made with <i class="app-icon-heart align-middle"></i> by Space Studio
        </a>
      </div>
    </div>
  </div>
</footer>
