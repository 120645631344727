import { NotificationModel } from '../../../models';
import { FollowRequestNotificationComponent } from '../notification/follow-request-notification.component';
import { NotificationComponent } from '../notification/notification.component';
import { RouterLink } from '@angular/router';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  NgIf,
  NgTemplateOutlet,
  NgSwitch,
  NgSwitchCase,
  NgSwitchDefault,
} from "@angular/common";

@Component({
  selector: "app-notification-item",
  templateUrl: "./notification-item.component.html",
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    NgTemplateOutlet,
    NgSwitch,
    NgSwitchCase,
    FollowRequestNotificationComponent,
    NgSwitchDefault,
    NotificationComponent,
  ],
})
export class NotificationItemComponent implements OnInit {
  /**
   * The action event of the notification.
   */
  @Output() action: EventEmitter<NotificationModel> = new EventEmitter();

  @Input() displayLink?: boolean = false;

  /**
   * If the notification is first.
   */
  @Input() isFirst?: boolean;

  /**
   * If the notification is last.
   */
  @Input() isLast?: boolean;

  /**
   * The notification of the component.
   */
  private _notification?: NotificationModel;

  @Input()
  get notification(): NotificationModel | undefined {
    return this._notification;
  }

  set notification(notification: any | undefined) {
    this._notification = new NotificationModel(notification);
  }

  /**
   * The notification component of the component.
   */
  @ViewChild(NotificationComponent, { static: false })
  notificationComponent?: NotificationComponent;

  /**
   * On component init.
   */
  ngOnInit(): void {}
}
