<app-dropdown
  buttonSize="small"
  (changed)="onToggleClick()"
  class="drop relative py-0"
  direction="right"
  size="medium"
  #toggle
  title="Notifications"
>
  <span text>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 32 32"
      class="app-icon-notification inline-block fill-current stroke-current"
    >
      <g>
        <path
          d="M6.393 12.398v-.005a9.611 9.611 0 0 1 6.386-9.053l.067-.021a3.202 3.202 0 1 1 6.322.019l.002-.019c3.78 1.35 6.437 4.899 6.437 9.069V22.005l4.804 3.202v1.601H1.589v-1.6l4.804-3.203v-9.607zm12.81 16.011a3.202 3.202 0 1 1-6.405 0h6.404z"
        ></path>
      </g>
    </svg>

    <span
      class="absolute right-[15%] top-0 flex items-center justify-center rounded-full bg-blue-500 px-2 py-1 text-xs text-white"
      *ngIf="unreadCount > 0"
      [style.fontSize.px]="unreadCount >= 10 ? 8.5 : null"
    >
      <span>
        {{ unreadCount <= 9 ? unreadCount : "9+" }}
      </span>
    </span>
  </span>

  <ng-container items>
    <div
      class="max-h-[55vh] overflow-y-auto overscroll-contain"
      #list
      (scroll)="markReadOnScroll($event)"
    >
      <ng-container
        *ngFor="
          let notification of notifications$ | async;
          let index = index;
          let first = first;
          let last = last;
          trackBy: trackByFn
        "
      >
        <app-notification-dropdown-item
          [attr.data-index]="index"
          [notification]="notification"
          [isFirst]="first"
          [isLast]="last"
          (action)="markRead($event)"
        >
        </app-notification-dropdown-item>
      </ng-container>

      <div *ngIf="notificationService?.pageInfo?.hasNextPage">
        <app-button
          aria-label="Load More"
          color="clear"
          expand="block"
          [loading]="loadingMore"
          (onClick)="getMoreNotifications($event)"
        >
          Load more
        </app-button>
      </div>
    </div>

    <div
      *ngIf="!(notifications$ | async)?.length"
      class="no-notifications p-3 text-center font-normal"
    >
      <p class="mb-0 text-xl">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 32 32"
          class="fill-current stroke-current"
        >
          <g>
            <path
              d="M6.393 12.398v-.005a9.611 9.611 0 0 1 6.386-9.053l.067-.021a3.202 3.202 0 1 1 6.322.019l.002-.019c3.78 1.35 6.437 4.899 6.437 9.069V22.005l4.804 3.202v1.601H1.589v-1.6l4.804-3.203v-9.607zm12.81 16.011a3.202 3.202 0 1 1-6.405 0h6.404z"
            ></path>
          </g>
        </svg>
      </p>

      <p class="mb-0 font-medium">No Notifications</p>

      <small class="text-neutral-500">
        You haven't received any notifications yet
      </small>
    </div>

    <app-dropdown-item
      class="block border-t text-right text-sm"
      [class.scrollable-footer]="((notifications$ | async)?.length || 0) >= 4"
      [hidden]="!(notifications$ | async)?.length"
      route="/notifications"
    >
      View All
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 32 32"
        class="inline-block fill-current stroke-current align-middle"
      >
        <g>
          <path
            d="M21.84 17l1.4-1.4-11.2-11.2-2.8 2.8 8.4 8.4-8.4 8.4 2.8 2.8 9.8-9.8z"
          ></path>
        </g>
      </svg>
    </app-dropdown-item>
  </ng-container>
</app-dropdown>
