import { Params, RouterLink } from '@angular/router';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { NgIf, NgClass, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: "app-dropdown-item",
    templateUrl: "./dropdown-item.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgTemplateOutlet,
        RouterLink,
    ],
})
export class DropdownItemComponent implements OnInit {
  /**
   * The classes of the dropdown item.
   */
  classes: { [key: string]: boolean } = {};

  /**
   * The click event emitter.
   */
  @Output() onClick: EventEmitter<Event> = new EventEmitter();

  /**
   * The color of the dropdown item.
   */
  @Input() color: "" | "danger" = "";

  /**
   * The disabled state of the dropdown item.
   */
  @Input() disabled: boolean | undefined = false;

  /**
   * The href of the dropdown item.
   */
  @Input() href?: string;

  /**
   * The query params of the button.
   */
  queryParams?: Params | null | undefined;

  /**
   * The router link of the button.
   */
  @Input() route: string | string[] | null | undefined = null;

  /**
   * The target of the link.
   */
  @Input() target: string = "";

  /**
   * Create a new instance of the component.
   */
  constructor(private cd: ChangeDetectorRef) {}

  /**
   * On component init.
   */
  ngOnInit() {
    this.classes = {
      "block w-full px-4 py-3 hover:bg-neutral-50 focus:bg-neutral-50 active:bg-neutral-50 ring-inset focus:ring-2 active:ring-2 focus:ring-blue-200 active:ring-blue-200 text-left font-normal border-0":
        true,
      "text-black hover:text-black": this.color === "",
      "text-red-600 hover:text-red-800": this.color === "danger",
    };
  }

  /**
   * The button click handler.
   */
  handleClick($event: Event) {
    this.onClick.next($event);
  }
}
