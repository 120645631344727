import { AuthenticationService } from './authentication/authentication.service';
import { ChatService } from './chat.service';
import { EventService } from './event.service';
import { BrandModel } from '../models/brand/brand.model';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { setUser } from '@sentry/angular-ivy';

@Injectable()
export class UserAuthorizationService implements OnDestroy {
  /**
   * If redirects should be allowed by the service.
   */
  allowRedirect = true;

  /**
   * The initialized state of the service.
   */
  initialized?: boolean;

  /**
   * The router instance of the serivce.
   */
  public get router(): Router {
    return this.injector.get(Router);
  }

  /**
   * The subscriptions of the service.
   */
  subs: any = {};

  /**
   * The timeouts of the component.
   */
  timeouts: { [key: string]: any } = {};

  /**
   * Create a new instance of the class.
   */
  constructor(
    private auth: AuthenticationService,
    private chat: ChatService,
    private event: EventService,
    private injector: Injector
  ) {
    this.init();
  }

  /**
   * On service destroy.
   */
  ngOnDestroy(): void {
    Object.keys(this.subs).forEach((k) => this.subs[k]?.unsubscribe());
    Object.keys(this.timeouts).forEach((k) => clearTimeout(this.timeouts[k]));
  }

  /**
   * Initialize the service.
   */
  init(): void {
    this.subs["loggedIn"] = this.event
      .listen("auth:loggedIn")
      .subscribe(() => this.onLogin());

    this.subs["loggedOut"] = this.event
      .listen("auth:loggedOut")
      .subscribe(() => this.onLogout());
    // TODO: find a better way to do this
    this.auth.check().then(() => {});
    this.auth.setUnAuthenticatedHandler((router: Router) =>
      this.authRequired(router)
    );
  }

  /**
   * Auth required handler.
   */
  authRequired(router: Router): void {
    if (this.router.url !== "/sign-in") {
      router.navigate(["/sign-in"], {
        queryParams: {
          auth_required: 1,
        },
      });
    }
  }

  /**
   * Check if the user should be redirected.
   */
  checkRedirect(): void {
    if (this.allowRedirect && this.auth.getRedirect()) {
      this.timeouts["checkRedirect"] = setTimeout(() => {
        const redirect = this.auth.pullRedirect();

        if (redirect) {
          this.router.navigateByUrl(redirect).then(
            () => {},
            () => {}
          );
        }
      }, 100);
    }
  }

  /**
   * Check if a user has brands.
   */
  hasBrands(): void {
    if (this.auth.user().brands && this.auth.user().brands.length > 0) {
      this.auth.user().can("view:brands");
    }
  }

  /**
   * Define a the user is an admin.
   */
  isAdmin(): void {
    if (
      this.auth.user().roles &&
      this.auth.user().roles.findIndex((role: any) => role.key === "admin") >= 0
    ) {
      this.auth.user().identify("admin");
    }
  }

  /**
   * Define a the user is a brand admin.
   */
  isBrandAdmin(): void {
    if (this.auth.user().roles) {
      this.auth.user().roles.forEach((role: any) => {
        if (role.key === "brand_admin") {
          this.auth.user().allow("manage:brand_users", role.brand_id, true);
          this.auth.user().identify("brand_admin");
        }
      });
    }
  }

  /**
   * Allow the user to edit their profile.
   */
  canEditProfile(): void {
    this.auth.user().allow("edit:user_profile", this.auth.user().id, true);

    if (this.auth.user().brands && this.auth.user().brands.length > 0) {
      this.auth.user().brands.forEach((brand: BrandModel) => {
        this.auth.user().allow("edit:brand_profile", brand.id, true);
      });
    }
  }

  /**
   * On login handler.
   */
  onLogin(): void {
    this.checkRedirect();

    const prototype = Object.getPrototypeOf(this);
    const methods = Object.getOwnPropertyNames(prototype).filter((p: any) => {
      const object: any = this;

      return typeof object[p] === "function";
    });

    methods
      .sort()
      .reverse()
      .forEach((method) => {
        if (
          method.startsWith("is") ||
          method.startsWith("can") ||
          method.startsWith("has")
        ) {
          const object: any = this;

          object[method]();
        }
      });

    setUser({
      email: this.auth.user()?.email,
      username: this.auth.user()?.username,
    });

    this.chat.identify();
    this.event.broadcast("authorization:init", (this.initialized = true));
  }

  /**
   * On logout handler.
   */
  onLogout(): void {
    setUser(null);
    this.chat.logout();
  }
}
