import { AlertService } from "./alert.service";
import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, Optional, PLATFORM_ID } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SystemService {
  /**
   * The timestamp of the last version check.
   */
  lastVersionCheck?: number;

  /**
   * The window has focus subject.
   */
  windowHasFocus$: Subject<boolean> = new Subject();

  /**
   * The focused state of the window.
   */
  windowHasFocus?: boolean;

  /**
   * Create a new instance of the service.
   */
  constructor(
    private alertService: AlertService,
    @Inject(PLATFORM_ID) private platformId: string,
    @Optional() private updates: SwUpdate
  ) {
    this.init();
  }

  /**
   * Initialize the service.
   */
  protected init() {
    if (isPlatformBrowser(this.platformId)) {
      this.observe();

      this.windowHasFocus = window.document.hasFocus();
    }
  }

  /**
   * Observe the browser state.
   */
  protected observe(): void {
    window.document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.windowHasFocus$.next((this.windowHasFocus = false));
      } else {
        this.windowHasFocus$.next((this.windowHasFocus = true));
        this.onWindowFocus();
      }
    });

    this.subscribeToUpdates();
  }

  /**
   * Prompt the user that there is an update available.
   */
  protected async promptUser(): Promise<boolean> {
    return await this.alertService.prompt(
      "A new version of RigShare is available.",
      {
        dismiss: false,
      }
    );
  }

  /**
   * Handle window focus.
   */
  protected onWindowFocus() {
    if (!this.updates?.isEnabled) {
      return;
    }

    const timestamp = Date.now();

    if (this.lastVersionCheck && timestamp - this.lastVersionCheck <= 30000) {
      return;
    }

    this.lastVersionCheck = timestamp;
    this.updates?.checkForUpdate();
  }

  /**
   * Subscribe to service worker updates.
   */
  protected subscribeToUpdates() {
    if (!this.updates?.isEnabled) {
      return;
    }

    this.updates?.versionUpdates.subscribe(async (event) => {
      switch (event.type) {
        case "VERSION_DETECTED":
          console.log(`Downloading new app version: ${event.version.hash}`);
          break;
        case "VERSION_READY":
          console.log(`Current app version: ${event.currentVersion.hash}`);
          console.log(
            `New app version ready for use: ${event.latestVersion.hash}`
          );

          this.updates?.activateUpdate();

          break;
        case "VERSION_INSTALLATION_FAILED":
          console.log(
            `Failed to install app version '${event.version.hash}': ${event.error}`
          );
          break;
      }

      // if (!this.lastVersionCheck) {
      //   // return document.location.reload();
      // }

      // if (await this.promptUser()) {
      //   try {
      //     await this.updates.activateUpdate();
      //     document.location.reload();
      //   } catch (error) {
      //     console.error(error);
      //   }
      // }
    });

    this.updates?.checkForUpdate();
  }
}
