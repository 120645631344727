import { cache } from "./cache";
import { WebSocketLink } from "./websocket-link";
import { AuthenticationService } from "../services";
import { EventService } from "../services/event.service";
import { CookieStorageService } from "../services/storage";
import { isPlatformBrowser } from "@angular/common";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, NgModule, PLATFORM_ID, Provider } from "@angular/core";
import { setContext } from "@apollo/client/link/context";
import { Apollo, ApolloModule } from "apollo-angular";
import { HttpBatchLink } from "apollo-angular/http";
import { firstValueFrom, Subscription } from "rxjs";
import {
  inject,
  InjectionToken,
  makeStateKey,
  TransferState,
} from "@angular/core";
import {
  DefaultContext,
  from,
  GraphQLRequest,
  InMemoryCache,
} from "@apollo/client/core";

const APOLLO_CACHE = new InjectionToken<InMemoryCache>("apollo-cache");
const STATE_KEY = makeStateKey<any>("apollo.state");

@NgModule({
  imports: [ApolloModule],
  exports: [ApolloModule],
  providers: GraphQLModule.providers(),
})
export abstract class GraphQLModule {
  readonly apollo: Apollo = inject(Apollo);
  auth: AuthenticationService = inject(AuthenticationService);
  @Inject(APOLLO_CACHE) cache: InMemoryCache = inject(APOLLO_CACHE);
  cookieStorage: CookieStorageService = inject(CookieStorageService);
  event: EventService = inject(EventService);
  http: HttpClient = inject(HttpClient);
  readonly httpLink: HttpBatchLink = inject(HttpBatchLink);
  platformId: Object = inject(PLATFORM_ID);
  subs: Subscription = new Subscription();
  readonly transferState: TransferState = inject(TransferState);
  xsrfPromise?: Promise<object> | null;

  /**
   * Create a new instance of the module.
   */
  constructor() {
    this.init();
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  /**
   * Create the apollo http link.
   */
  protected async createHttpLink() {
    const links = [];

    links.push(
      setContext(
        async (operation: GraphQLRequest, prevContext: DefaultContext) => {
          const headers = await this.getHeaders();
          prevContext.headers = new HttpHeaders({ ...headers });
          prevContext.credentials = "include";
          return prevContext;
        }
      )
    );

    if (isPlatformBrowser(this.platformId)) {
      links.push(
        new WebSocketLink(this.environment().websocketUrl, {
          authEndpoint: `${
            this.options().http.baseUrl
          }/graphql/subscriptions/auth`,
          auth: {
            withCredentials: true,
          },
          retryConnection: this.environment().production === true,
        })
      );
    }

    links.push(
      this.httpLink.create({
        uri: `${this.options().http.baseUrl}/graphql`,
        withCredentials: true,
      })
    );

    this.apollo.create({
      link: from(links),
      cache: this.cache,
    });
  }

  /**
   * The environment of the platform.
   */
  abstract environment(): any;

  /**
   * Get headers for the graphql request.
   */
  protected async getHeaders() {
    let headers = {};

    if (this.environment().platform === "mobile") {
      return headers;
    }

    if (!this.xsrfPromise) {
      this.xsrfPromise = this.retrieveXSRFToken();
    }

    headers = await this.xsrfPromise;

    this.xsrfPromise = null;

    return headers;
  }

  /**
   * Initialize the module.
   */
  async init() {
    await this.createHttpLink();
    this.setTransferState();
    this.watchEvents();
  }

  /**
   * The options of the platform.
   */
  abstract options(): any;

  /**
   * Return the base providers for the module.
   * @returns
   */
  static providers(): Provider[] {
    return [
      {
        provide: APOLLO_CACHE,
        useValue: cache(),
        deps: [TransferState],
      },
    ];
  }

  /**
   * Retrieve the XSRF Token.
   */
  protected async retrieveXSRFToken(): Promise<object> {
    if (typeof process !== "undefined" && process?.env?.XSRF_SIGNATURE) {
      return {
        "X-XSRF-SIGNATURE": process.env.XSRF_SIGNATURE,
      };
    }

    let token = await this.cookieStorage.get("XSRF-TOKEN");

    if (!token) {
      try {
        await firstValueFrom(this.http.get("sanctum/csrf-cookie"));
      } catch (error) {
        console.error(error);
      }

      token = await this.cookieStorage.get("XSRF-TOKEN");
    }

    if (!token) {
      return {};
    }

    return {
      "X-XSRF-TOKEN": token,
    };
  }

  /**
   * Set the browser and server transfer state.
   */
  setTransferState() {
    if (this.environment().platform === "mobile") {
      return;
    }

    if (this.transferState.hasKey(STATE_KEY)) {
      this.cache.restore(this.transferState.get<any>(STATE_KEY, null));
    } else {
      this.transferState.onSerialize(STATE_KEY, () => this.cache?.extract());
    }
  }

  /**
   * Watch events.
   */
  async watchEvents() {
    await this.auth.check();

    this.subs.add(
      this.event.listen("auth:loggedIn").subscribe(() => {
        if (isPlatformBrowser(this.platformId)) {
          this.cache.reset();
        }
      })
    );

    this.subs.add(
      this.event.listen("auth:loggedOut").subscribe(() => {
        this.apollo?.client.clearStore();
      })
    );
  }
}
