import { ConfigSerivce } from './config.service';
import { HttpService } from './http.service';
import { CommonConfig } from '../common-config';
import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor as Interceptor,
  HttpRequest,
} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class HttpInterceptor implements Interceptor {
  /**
   * Create a new instance of the interceptor.
   */
  constructor(
    @Inject("RIGSHARE_COMMON_CONFIG") protected config: CommonConfig,
    private configService: ConfigSerivce,
    @Inject(PLATFORM_ID) private platformId: Object,
    public http: HttpService
  ) {}

  /**
   * Intercept the http request.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = request.clone({
      url: this.http.getUrl(request.url),
    });

    if (
      request.url.startsWith(`${this.config.wordpressBaseUrl}/wp-json/wp/v2`) ||
      request.url.startsWith(
        `${this.config.wordpressBaseUrl}/wp-content/wp-json-static`
      )
    ) {
      return next.handle(
        request.clone({
          withCredentials: false,
        })
      );
    }

    if (this.configService.get("authentication.driver") === "token") {
      request = request.clone({
        headers: this.http.headers,
      });
    }

    if (this.configService.get("authentication.driver") === "session") {
      request = request.clone({
        headers: isPlatformServer(this.platformId)
          ? this.http.headersWithCookies()
          : this.http.headers,
        withCredentials: true,
      });
    }

    return next.handle(request);
  }
}
