import { AuthenticationService } from "./authentication/authentication.service";
import { EventService } from "./event.service";
import { ModalService } from "./modal.service";
import { Notification, NotificationsQuery, PageInfo } from "../graphql";
import { MarkNotificationsReadGQL } from "../graphql/notifications/mark-notifications-read.generated";
import { NotificationCreatedGQL } from "../graphql/notifications/notification-created.generated";
import { NotificationsGQL } from "../graphql/notifications/notifications.generated";
import { UnreadNotificationsCountGQL } from "../graphql/notifications/unread-notifications-count.generated";
import { UserModel } from "../models/user.model";
import { isPlatformBrowser } from "@angular/common";
import { Inject, Injectable, OnDestroy, PLATFORM_ID } from "@angular/core";
import { Apollo, QueryRef } from "apollo-angular";
import { firstValueFrom, Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class NotificationService implements OnDestroy {
  initialized?: boolean;
  /**
   * The listening state of notifications.
   */
  listening = false;

  /**
   * The loading state of the service.
   */
  loading: boolean = false;

  /**
   * The loading more state of the service.
   */
  loadingMore: boolean = false;

  /**
   * Reference to the notification graphql query.
   */
  notificationsQuery?: QueryRef<any>;

  /**
   * The notitications observable of the service.
   */
  notifications$?: Observable<Notification[]> | undefined;

  /**
   * The page info of the service.
   */
  pageInfo?: PageInfo;

  /**
   * The notifications to be marked as read.
   */
  read: any[] = [];

  /**
   * The queue of notifications to be marked as read.
   */
  readQueue: Subject<any> = new Subject();

  /**
   * The subscriptions of the service.
   */
  subs: any = {};

  /**
   * The count of unread notifications.
   */
  unreadCount = 0;

  /**
   * The unread count subkect.
   */
  $unreadCount: Subject<number> = new Subject();

  $update: Subject<void> = new Subject();

  /**
   * Create a new instance of the service.
   */
  constructor(
    private auth: AuthenticationService,
    protected apollo: Apollo,
    private event: EventService,
    protected markNotificationsRead: MarkNotificationsReadGQL,
    private modalService: ModalService,
    private notificationCreatedGQL: NotificationCreatedGQL,
    private notificationsGQL: NotificationsGQL,
    private unreadNotificationsGQL: UnreadNotificationsCountGQL,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.initEvents();
    }
  }

  /**
   * On service destroy.
   */
  ngOnDestroy(): void {
    Object.keys(this.subs).forEach((k) => this.subs[k]?.unsubscribe());
  }

  /**
   * Get more notifications from the API.
   */
  async getMoreNotifications() {
    this.loadingMore = true;

    await this.notificationsQuery?.fetchMore({
      variables: {
        after: this.pageInfo?.endCursor,
      },
    });

    this.loadingMore = false;
  }

  /**
   * Get notifications from the API.
   */
  getNotifications(params: any = null, refresh: boolean = false): void {
    this.loading = true;
    this.getUnreadCount();
    this.notificationsQuery = this.notificationsGQL.watch(params);

    this.notifications$ = this.notificationsQuery.valueChanges.pipe(
      map(({ data: { notifications } }) => {
        this.pageInfo = notifications.pageInfo;
        return notifications.edges.map((edge: any) => edge.node);
      }),
      distinctUntilChanged(),
      tap(() => {
        this.loading = false;
        this.$update.next();
      })
    );
  }

  /**
   * Get the number of unread notifications from the API.
   */
  async getUnreadCount(): Promise<void> {
    const { data } = await firstValueFrom(
      this.unreadNotificationsGQL.fetch(
        {},
        {
          fetchPolicy: "network-only",
        }
      )
    );

    this.setUnreadCount(data?.notifications?.pageInfo?.total || 0);
  }

  /**
   * Initialize the events of the service.
   */
  async initEvents(): Promise<void> {
    if (this.auth.user()) {
      this.initNotifications();
    }

    this.subs["auth:loggedIn"] = this.event
      .listen("auth:loggedIn")
      .subscribe(() => this.initNotifications());

    this.subs["auth:loggedOut"] = this.event
      .listen("auth:loggedOut")
      .subscribe(() => {
        if (this.subs["notifications"]) {
          this.subs["notifications"].unsubscribe();
        }

        this.unreadCount = 0;
        this.listening = false;
        this.initialized = false;
      });

    this.markReadListener();
  }

  /**
   * Get and listen to notifications.
   */
  async initNotifications(): Promise<void> {
    if (!this.initialized) {
      this.getNotifications();
      this.initialized = true;
    }

    if (!this.listening) {
      this.listenForNotifications(this.auth.user());
    }
  }

  /**
   * Listen for notifications.
   */
  listenForNotifications(user: UserModel): void {
    this.listening = true;

    this.subs["notifications"] = this.notificationCreatedGQL
      .subscribe({ user_id: user?.id || "" })
      .subscribe({
        next: ({ data }) => {
          // if (!data?.notificationCreated) {
          //   return;
          // }

          this.notificationsQuery?.updateQuery((prev) => {
            return {
              ...prev,
              notifications: {
                ...prev.notifications,
                edges: [
                  {
                    __typename: "Notification",
                    node: data?.notificationCreated,
                  },
                  ...prev.notifications.edges,
                ],
              },
            };
          });
        },
      });
  }

  /**
   * Mark all notifications as unread.
   */
  markAllRead(): void {
    this.setUnreadCount(0);

    this.markNotificationsRead.mutate().subscribe({
      next: ({ data }) => {
        this.read = [];
        this.setUnreadCount(data?.markNotificationsRead?.unread_count || 0);
        this.event.broadcast("notifications:updated");
      },
    });
  }

  /**
   * Mark notification as read.
   */
  async markRead(id: string): Promise<void> {
    this.read.push(id);
    this.readQueue.next(this.read);

    if (this.unreadCount >= 1) {
      this.unreadCount--;
    }
  }

  /**
   * Listener for notifications to be marked as read.
   */
  markReadListener() {
    this.subs["readQueue"] = this.readQueue
      .pipe(debounceTime(1000))
      .subscribe((ids) => {
        if (!ids.length) {
          return;
        }

        this.markNotificationsRead
          .mutate(
            { ids },
            {
              update: (cache) => {
                const query = <NotificationsQuery | null>cache.readQuery({
                  query: this.notificationsGQL.document,
                });

                if (!query) {
                  return;
                }

                cache.writeQuery({
                  query: this.notificationsGQL.document,
                  data: {
                    notifications: {
                      ...query.notifications,
                      edges: query.notifications.edges.map((edge) => {
                        if (!ids.includes(edge.node.id)) {
                          return edge;
                        }

                        return {
                          ...edge,
                          node: {
                            ...edge.node,
                            read_at: Date.now(),
                          },
                        };
                      }),
                    },
                  },
                });

                this.event.broadcast("notifications:updated");
              },
            }
          )
          .subscribe({
            next: ({ data }) => {
              this.read = [];
              this.setUnreadCount(
                data?.markNotificationsRead?.unread_count || 0
              );
            },
          });
      });
  }

  /**
   * Display a notification modal.
   */
  modal(data: {
    title: string;
    errorMessage?: string;
    infoMessage?: string;
    successMessage?: string;
    message?: string;
  }): Promise<any> {
    return this.modalService.open("notification", data);
  }

  /**
   * Reresh the notifications.
   */
  async refresh(): Promise<void> {
    await this.notificationsQuery?.refetch();
  }

  /**
   * Set the unread count.
   */
  setUnreadCount(count: number): void {
    this.unreadCount = count;
    this.$unreadCount.next(count);
  }
}
