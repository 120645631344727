import * as Types from '../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SearchProductsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  query?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type SearchProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductPaginator', data: Array<{ __typename?: 'Product', id: string, name?: string | null, slug?: string | null, brand?: { __typename?: 'Brand', id: string, name?: string | null, media?: Array<{ __typename?: 'Media', url?: string | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null, small?: string | null, medium?: string | null } | null }> | null } | null, media?: Array<{ __typename?: 'Media', id: string, type?: string | null, url?: string | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null, small?: string | null, medium?: string | null } | null }> | null }> } };

export const SearchProductsDocument = gql`
    query SearchProducts($first: Int = 15, $query: String) {
  products(search: $query, first: $first) {
    data {
      id
      name
      slug
      brand {
        id
        name
        media {
          sizes {
            id
            thumbnail
            small
            medium
          }
          url
        }
      }
      media {
        id
        type
        url
        sizes {
          id
          thumbnail
          small
          medium
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductsGQL extends Apollo.Query<SearchProductsQuery, SearchProductsQueryVariables> {
    document = SearchProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }