import { NgFor, SlicePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AlertComponent, AlertModel, AlertService } from 'rigshare-common';
import { Subscription } from 'rxjs';

@Component({
  selector: "app-alerts",
  templateUrl: "./alerts.component.html",
  standalone: true,
  imports: [NgFor, AlertComponent, SlicePipe],
})
export class AlertsComponent implements OnInit {
  /**
   * The alerts of the component.
   */
  alerts: AlertModel[] = [];

  /**
   * The type of al
   */
  @Input() type: string = "info";

  /**
   * The subscriptions of the component.
   */
  subs: Subscription = new Subscription();

  /**
   * Create a new intance of the component.
   */
  constructor(private alertService: AlertService) {}

  /**
   * On component init.
   */
  ngOnInit() {
    this.alerts = this.alertService.alerts;

    this.subs.add(
      this.alertService.new.subscribe((alert: AlertModel) => {
        this.alerts.push(alert);
        this.activateAlert(0);
      })
    );
  }

  /**
   * On component destroy.
   */
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /**
   * Activate an alert.
   */
  activateAlert(index: number): void {
    const alert = this.alerts[index];

    if (!this.alerts[index] || alert.activated) {
      return;
    }

    this.alerts[index].activated = true;

    if (alert.dismiss && typeof alert.dismiss === "number") {
      setTimeout(() => this.dismiss(index, alert), alert.dismiss);
    }
  }

  /**
   * Dismiss an alert.
   */
  dismiss(index: number, alert: AlertModel): void {
    this.alertService.remove(alert);
    this.alerts.splice(index, 1);
    this.activateAlert(0);
  }

  /**
   * Respond to an alert.
   */
  async respond(alert: AlertModel, value: any): Promise<void> {
    await this.alertService.response.next({
      alert: alert,
      value: value,
    });

    const index = this.alerts.findIndex((a) => a.id === alert.id);

    this.dismiss(index, alert);
  }

  /**
   * Method to track alert items.
   */
  tracking(index: number, alert: AlertModel) {
    return alert.id;
  }
}
