import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class ViewPortService {
  /**
   * Create a new instace of the service.
   */
  constructor(@Inject(PLATFORM_ID) private platformId: string) {
    this.init();
  }

  /**
   * The view ports of the service.
   */
  viewPorts = ["xs", "sm", "md", "lg", "xl"];

  /**
   * The width of the viewport.
   */
  width: number = 0;

  /**
   * Initialize the service.
   */
  init() {
    if (isPlatformBrowser(this.platformId)) {
      this.width = window.innerWidth;

      window.addEventListener(
        "resize",
        () => {
          this.width = window.innerWidth;
        },
        { passive: true }
      );
    }
  }

  /**
   * Check if a provided size is equal to or greather than.
   */
  is(size: string): boolean {
    if (!isPlatformBrowser(this.platformId) || !this.viewPortSize()) {
      return false;
    }

    return (
      this.viewPorts.indexOf(this.viewPortSize()) >=
      this.viewPorts.indexOf(size)
    );
  }

  /**
   * The size of the viewport.
   */
  viewPortSize(): string {
    if (this.width >= 1280) {
      return "xl";
    }

    if (this.width >= 1024) {
      return "lg";
    }

    if (this.width >= 768) {
      return "md";
    }

    if (this.width >= 640) {
      return "sm";
    }

    return "xs";
  }

  /**
   * Check if a provided size matches the current viewport.
   */
  when(size: string | string[]): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      return false;
    }

    if (Array.isArray(size) && this.viewPortSize()) {
      return size.indexOf(this.viewPortSize()) >= 0;
    }

    return new Boolean(this.viewPortSize()) && this.viewPortSize() === size;
  }
}
