import { CommonConfig } from '../common-config';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class CloudFlareAnalytics {
  /**
   * Create a new instance of the service.
   */
  constructor(
    @Inject("RIGSHARE_COMMON_CONFIG") protected config: CommonConfig
  ) {}

  /**
   * Track a page view.
   */
  event(name: string, data: any): void {
    //
  }

  /**
   * Initialize the service.
   */
  async init(): Promise<any> {
    await this.loadScript();
  }

  /**
   * Load the script needed by the service.
   */
  loadScript(): Promise<void> {
    return new Promise((resolve) => {
      if (!this.config.environment.cloudflareToken) {
        resolve();
        return;
      }
      const script = document.createElement("script");
      const scripts = document.getElementsByTagName("script")[0];
      scripts?.parentNode?.insertBefore(script, scripts);
      script.dataset.cfBeacon = `{"token": "${this.config.environment.cloudflareToken}"}`;
      script.defer = true;
      script.src = `https://static.cloudflareinsights.com/beacon.min.js`;
      resolve();
    });
  }
}
