<style>
  :host {
    position: relative;
    display: block;
    z-index: 400;
  }
</style>

<div [hidden]="!searching || !query">
  <div
    class="absolute inset-0 z-40 mt-2 w-full"
    [class.mt-2]="loading || results"
    [class.shadow-sm]="results && results.length"
  >
    <div
      class="h-auto max-h-[55vh] divide-y overflow-x-hidden rounded border bg-white"
      [hidden]="!query"
      #scrollArea
    >
      <ng-container *ngFor="let result of results; trackBy: trackBy">
        <!-- Users -->
        <a
          *ngIf="result.type === 'users'"
          [routerLink]="['/', result.data?.username]"
          #resultElements
          class="block p-3 text-neutral-600 hover:bg-neutral-100"
          [ngClass]="{
            'bg-neutral-100 ring ring-inset ring-blue-200': result.active,
            'bg-white': !result.active
          }"
          (click)="onSelected()"
          (mousedown)="clicking = true"
        >
          <div class="media flex items-center">
            <app-profile-image
              width="30"
              [model]="result.data"
              imageSize="thumbnail"
            >
            </app-profile-image>

            <div class="media-body ml-2 truncate">
              <p class="my-0 truncate font-medium">
                {{ result.data?.username }}
              </p>

              <span class="truncate text-neutral-600">
                {{ result.data?.name }}
              </span>
            </div>
          </div>
        </a>

        <!-- Hashtags -->
        <a
          [routerLink]="['/hashtags', result.data?.name]"
          #resultElements
          *ngIf="result.type === 'hashtags'"
          class="block p-3 text-neutral-600 hover:bg-neutral-100"
          [ngClass]="{
            'bg-neutral-100 ring ring-inset ring-blue-200': result.active,
            'bg-white': !result.active
          }"
          (click)="onSelected()"
          (mousedown)="clicking = true"
        >
          <div class="media flex items-center">
            <h3 class="px-2 text-neutral-600">#</h3>

            <div class="media-body ml-2 truncate">
              <p class="my-0 truncate font-medium">
                {{ result.data?.name }}
              </p>

              <span class="truncate text-neutral-600">
                {{ result.data?.posts_count.toLocaleString() }}
                <span [ngPlural]="result.data?.posts_count">
                  <ng-template ngPluralCase="=1">post</ng-template>
                  <ng-template ngPluralCase="other">posts</ng-template>
                </span>
              </span>
            </div>
          </div>
        </a>

        <!-- Products -->
        <a
          [routerLink]="['/products', result.data?.slug]"
          *ngIf="result.type === 'products'"
          #resultElements
          class="block p-3 text-neutral-600 hover:bg-neutral-100"
          [ngClass]="{
            'bg-neutral-100 ring ring-inset ring-blue-200': result.active,
            'bg-white': !result.active
          }"
          (click)="onSelected()"
          (mousedown)="clicking = true"
        >
          <div class="media flex items-center">
            <app-product-image
              width="30"
              [product]="result.data"
              imageSize="thumbnail"
              [hasBorder]="true"
            >
            </app-product-image>

            <div class="media-body ml-2 truncate">
              <p class="my-0 truncate font-medium">
                {{ result.data?.name }}
              </p>

              <span class="truncate text-neutral-600" *ngIf="result.data.brand">
                {{ result.data?.brand?.name }}
              </span>
            </div>
          </div>
        </a>
      </ng-container>

      <div
        class="p-2 py-3 text-center"
        [hidden]="loading || !searching || !results || results?.length"
      >
        <p class="font-medium">Can't find the gear your looking for?</p>

        <p class="mt-4 text-neutral-600">
          Send us a suggestion and we'll let you know when we add your gear to
          our database
        </p>

        <app-button class="mt-4" (onClick)="suggest()" size="small">
          Suggest Gear
        </app-button>
      </div>

      <div
        class="p-2 text-center"
        [hidden]="!loading || (results && results.length)"
      >
        <span
          appLoader
          loaderColor="dark"
          [loading]="searching || loading"
          [loaderWidth]="24"
        >
        </span>
      </div>
    </div>
  </div>
</div>
