import { NotificationInterface } from './notification.interface';
import { Component, Input, OnInit } from '@angular/core';
import { ButtonComponent } from '../../buttons/button/button.component';
import { NgIf, NgFor } from '@angular/common';
import { ProfileImageComponent } from '../../profile/profile-image/profile-image.component';

@Component({
    selector: "app-notification",
    templateUrl: "./notification.component.html",
    styleUrls: ["./notification.component.css"],
    standalone: true,
    imports: [
        ProfileImageComponent,
        NgIf,
        NgFor,
        ButtonComponent,
    ],
})
export class NotificationComponent implements OnInit, NotificationInterface {
  /**
   * The actions of the component.
   */
  actions?: any[];

  /**
   * The index of the notification.
   */
  @Input() index?: number;

  /**
   * Mark the notification read on view.
   */
  markReadOn = "view";

  /**
   * The notification of the component.
   */
  @Input() notification: any = null;

  /**
   * On component init.
   */
  ngOnInit(): void {}
}
