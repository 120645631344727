import { Component, Input, OnInit } from '@angular/core';
import { NgIf, NgClass, NgFor, SlicePipe } from '@angular/common';

@Component({
    selector: "app-input-container",
    templateUrl: "./input-container.component.html",
    styles: [],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgFor,
        SlicePipe,
    ],
})
export class InputContainerComponent implements OnInit {
  /**
   * The css classes of the input container.
   */
  @Input() classes: { [klass: string]: boolean } = {};

  /**
   * The errors of the input.first-letter
   */
  @Input() errors?: string[];

  /**
   * The label of the input.
   */
  @Input() label: string = "";

  constructor() {}

  ngOnInit(): void {}
}
