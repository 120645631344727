import { NotificationModel } from '../../../models';
import { DropdownItemComponent } from '../../dropdown/dropdown-item/dropdown-item.component';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-notification-dropdown-item",
  templateUrl: "./notification-dropdown-item.component.html",
  standalone: true,
  imports: [NgIf, DropdownItemComponent, NotificationItemComponent],
})
export class NotificationDropdownItemComponent implements OnInit {
  /**
   * Create a new instance of the component.
   *
   * @param cd
   */
  constructor(private cd: ChangeDetectorRef) {}

  /**
   * The action event of the notification.
   *
   * @return {EventEmitter}
   */
  @Output() action: EventEmitter<NotificationModel> = new EventEmitter();

  /**
   * If the notification is first.
   */
  @Input() isFirst?: boolean;

  /**
   * If the notification is last.
   */
  @Input() isLast?: boolean;

  /**
   * The notification of the component.
   */
  private _notification?: NotificationModel;

  @Input()
  get notification(): NotificationModel | undefined {
    return this._notification;
  }

  set notification(notification: any | undefined) {
    this._notification = new NotificationModel(notification);
  }

  /**
   * The notification component of the component.
   */
  @ViewChild(NotificationItemComponent, { static: false })
  notificationItemComponent?: NotificationItemComponent;

  /**
   * On component init.
   */
  ngOnInit(): void {}
}
