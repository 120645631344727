import { Injector, NgModule } from '@angular/core';

@NgModule()
export class ModelsModule {
  /**
   * The injector of the module.
   */
  static injector: Injector;

  /**
   * Create a new instance of the module.
   */
  constructor(injector: Injector) {
    ModelsModule.injector = injector;
  }
}
