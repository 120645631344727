<style>
  :host {
    display: block;
  }
</style>
<nav
  class="flex flex-wrap items-center justify-between border-b bg-white px-1 py-1 sm:px-2 lg:px-5"
  [class.opened]="menu?.opened"
>
  <div
    class="logo-container transition"
    [ngClass]="{
      'xl:flex-1': !search.searching,
      'w-0 overflow-hidden opacity-0 lg:w-auto lg:flex-1 lg:opacity-100':
        search.searching
    }"
  >
    <a
      class="inline-block p-2 align-middle"
      title="RigShare"
      routerLink="/"
      (click)="clickLogo()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill-rule="evenodd"
        stroke-linejoin="round"
        stroke-miterlimit="2"
        clip-rule="evenodd"
        viewBox="0 0 899 152"
        width="150"
        height="25"
        class="logo hidden md:inline-block"
      >
        <path
          d="M151 15c0-8-6-15-15-15H15C7 0 0 7 0 15v121c0 9 7 15 15 15h121c9 0 15-6 15-15V15zM45 55a4 4 0 00-7 3v58a4 4 0 004 4h67a4 4 0 003-7L45 55zm-5-17a4 4 0 012-7 1219 1219 0 0141 1c5 1 9 2 13 5 3 2 6 5 8 9a29 29 0 014 12c0 11-6 19-14 24l-5-1-49-43z"
        />
        <path
          fill-rule="nonzero"
          d="M217 71h4c5 0 9-1 11-3s4-5 4-8c0-4-2-6-4-8s-6-3-11-3h-4v22zm51 49h-29l-22-34v34h-23V31h36l13 2a25 25 0 0115 15l2 11c0 6-2 12-5 16-4 4-8 7-15 9l28 36zM291 31h23v89h-23zM381 71h44v12a43 43 0 01-9 22l-10 8c-3 3-7 4-11 6a51 51 0 01-32-2 41 41 0 01-23-23c-2-6-4-12-4-18 0-7 2-13 4-19a40 40 0 0123-23c5-2 12-3 18-3 9 0 17 2 24 6 6 4 12 9 16 17l-21 9c-2-5-5-8-8-10s-7-3-11-3l-9 2-6 5-5 8-2 11 2 10 4 8 7 5c3 2 6 2 10 2h6l5-3 4-4 3-6h-19V71zM498 55l-7-4-7-2-7 2c-2 2-3 3-3 6l2 3 3 3 5 2 5 1c8 3 13 6 16 10 4 4 5 9 5 16l-2 11c-1 4-4 7-6 9-3 3-7 5-11 6-4 2-8 2-14 2-10 0-20-3-29-9l9-18 10 7 9 2c4 0 7-1 8-3 2-1 3-3 3-5l-1-4-2-2-4-2a136 136 0 00-14-6l-8-4-5-7-2-10 2-12a25 25 0 0115-14l12-2a53 53 0 0127 7l-9 17zM556 66h34V31h23v89h-23V84h-34v36h-23V31h23v35zM692 87l-10-30-10 30h20zm7 18h-34l-5 15h-25l34-89h26l34 89h-25l-5-15zM774 71h5c4 0 8-1 10-3 3-2 4-5 4-8 0-4-1-6-4-8-2-2-6-3-10-3h-5v22zm52 49h-29l-23-34v34h-23V31h36l13 2a25 25 0 0115 15l2 11c0 6-2 12-5 16s-8 7-15 9l29 36zM899 51h-28v15h28v19h-28v16h28v19h-51V31h51v20z"
        />
      </svg>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill-rule="evenodd"
        stroke-linejoin="round"
        stroke-miterlimit="2"
        clip-rule="evenodd"
        viewBox="0 0 375 375"
        class="logo md:hidden"
        width="32"
        height="32"
      >
        <path
          d="M375 38c0-21-17-38-37-38H38C17 0 0 17 0 38v300c0 20 17 37 38 37h300c20 0 37-17 37-37V38z"
        />
        <g fill="#fff">
          <path
            d="M112 136a11 11 0 00-18 8v143a11 11 0 0011 11h165a11 11 0 007-19L112 136zM98 95a10 10 0 017-18 3024 3024 0 01101 2c12 2 22 6 31 12 9 5 16 13 21 23a73 73 0 018 30c0 26-13 47-34 58-3 2-8 2-11-1L98 95z"
          />
        </g>
      </svg>
    </a>
  </div>

  <div class="flex flex-1 flex-col justify-center px-2 lg:px-0">
    <header-search
      class="mx-auto block w-full lg:max-w-md"
      [ngClass]="{
        'w-[65vw] max-w-md': !search.searching,
        'max-w-full': search.searching
      }"
      #search
      [large]="viewport.is('lg')"
      (navigation)="searchDropdown.navigate($event)"
      (enter)="searchDropdown.selectActive($event)"
      (type)="searchDropdown.performSearch($event)"
      (focused)="searchDropdown.setSearching(true)"
      (blurred)="searchDropdown.setSearching(false)"
      (close)="searchDropdown.clear()"
    >
    </header-search>

    <app-header-search-dropdown
      class="mx-auto block w-full lg:max-w-md"
      (close)="search.blur()"
      #searchDropdown
      (select)="search.clear()"
    >
    </app-header-search-dropdown>
  </div>

  <div
    class="flex justify-end transition lg:hidden"
    [ngClass]="{
      'xl:flex-1': !search.searching,
      'w-0 overflow-hidden opacity-0': search.searching
    }"
  >
    <button
      [attr.aria-expanded]="menu?.opened ? 'true' : 'false'"
      aria-label="Toggle navigation"
      class="collapsed relative border-0 px-3 text-2xl text-neutral-700"
      [class.searching]="search.searching"
      (click)="menu?.toggle()"
      type="button"
      #toggler
    >
      <i class="app-icon-menu align-middle" [hidden]="menu?.opened"></i>
      <i class="app-icon-close align-middle" [hidden]="!menu?.opened"></i>
      <span
        *ngIf="auth.user() && displayHasNotifications"
        class="absolute right-0 top-0 inline-block h-px w-px rounded-full bg-blue-500 p-1"
        [hidden]="menu?.opened"
      >
      </span>
    </button>
  </div>

  <app-header-menu class="w-full lg:w-auto xl:flex-1"></app-header-menu>
</nav>
