import {
  NgClass,
  NgFor,
  NgIf,
  NgPlural,
  NgPluralCase
  } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SearchHashtagsGQL } from 'rigshare-common';
import { SearchProductsGQL } from 'rigshare-common';
import { SearchUsersGQL } from 'rigshare-common';
import { HashtagModel } from 'rigshare-common';
import { UserModel } from 'rigshare-common';
import { ModalService } from 'rigshare-common';
import { ProductModel } from 'rigshare-common';
import {
  combineLatest,
  Observable,
  Subject,
  Subscription
  } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  LoaderDirective,
  ButtonComponent,
  ProductImageComponent,
  ProfileImageComponent,
} from "rigshare-common";

@Component({
  selector: "app-header-search-dropdown",
  templateUrl: "./header-search-dropdown.component.html",
  styleUrls: ["./header-search-dropdown.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    RouterLink,
    NgClass,
    ProfileImageComponent,
    NgPlural,
    NgPluralCase,
    ProductImageComponent,
    ButtonComponent,
    LoaderDirective,
  ],
})
export class HeaderSearchDropdownComponent implements OnDestroy {
  /**
   * The active result
   */
  activeResult: any;

  /**
   * The clicking state of the user.
   */
  clicking?: boolean;

  /**
   * The close event of the component.
   */
  @Output() close: EventEmitter<void> = new EventEmitter();

  /**
   * The loading state of the component.
   */
  loading = false;

  /**
   * The query used for search.
   */
  query?: string;

  /**
   * The results of the component.
   */
  results: any = null;

  /**
   * The result elements.
   */
  @ViewChildren("resultElements") resultElements?: QueryList<any>;

  /**
   * The scroll area of the componet
   */
  @ViewChild("scrollArea", { static: false }) scrollArea?: ElementRef;

  /**
   * The searching state of the component.
   */
  searching?: boolean;

  /**
   * The search subject of the component.
   */
  searchSubject: Subject<string> = new Subject();

  /**
   * The select event of the component.
   */
  @Output() select: EventEmitter<void> = new EventEmitter();

  /**
   * The subscriptions of the component.
   */
  subs: any = {};

  /**
   * The subscription of the component.
   */
  subscription: Subscription = new Subscription();

  /**
   * Create a new instance of the component.
   */
  constructor(
    private cd: ChangeDetectorRef,
    private modalService: ModalService,
    private searchHashtagsGQL: SearchHashtagsGQL,
    private searchProductsGQL: SearchProductsGQL,
    private searchUsersGQL: SearchUsersGQL
  ) {}

  /**
   * On component destroy.
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  /**
   * Adjust the scroll position.
   */
  adjustScrollPosition(index: number): void {
    const scroller = this.scrollArea?.nativeElement;
    const elements = this.resultElements?.toArray();

    if (elements?.[index]) {
      const element = elements[index].nativeElement;
      const top = element.offsetTop;
      scroller.scroll(0, top);
      this.cd.markForCheck();
    }
  }

  /**
   * Clear the results.
   */
  clear(close: boolean = true): void {
    if (close) {
      this.close.next();
    }

    this.results = null;
    this.loading = false;
    this.clicking = false;
    this.suspendSearch();
    this.cd.markForCheck();
  }

  /**
   * Navigate the items.
   */
  navigate(direction: any): void {
    if (!this.results) {
      return;
    }
    const activeIndex = this.results.findIndex((result: any) => result.active);
    this.results.forEach((result: any) => (result.active = false));
    let newIndex = activeIndex >= 0 ? activeIndex : -1;

    if (direction === "up" || direction === "down") {
      newIndex = direction === "up" ? newIndex - 1 : newIndex + 1;
      newIndex = newIndex < 0 ? this.results.length - 1 : newIndex;
      newIndex = newIndex >= this.results.length ? 0 : newIndex;

      if (this.results[newIndex]) {
        this.results[newIndex].active = true;
      }

      this.adjustScrollPosition(newIndex);
    }
  }

  /**
   * Action to take when an item is selected.
   */
  onSelected(): void {
    this.clicking = true;
    this.select.next();
    this.setSearching(false);
  }

  /**
   * Perform the search.
   */
  performSearch(query: string): void {
    if (!this.subs.searchSubject) {
      this.subs.searchSubject = this.searchSubject
        .pipe(debounceTime(200))
        .subscribe((q) => {
          this.search(q);
        });
    }

    this.searchSubject.next(query);
  }

  /**
   * Prepare the results data.
   */
  prepareResults([hashtags, products, users]: any): any[] {
    return [
      ...hashtags.map((h: HashtagModel) => ({
        type: "hashtags",
        data: h,
      })),
      ...products.map((p: ProductModel) => ({
        type: "products",
        data: p,
      })),
      ...users.map((u: UserModel) => ({
        type: "users",
        data: u,
      })),
    ];
  }

  /**
   * Search for a location.
   */
  async search(q: string): Promise<void> {
    this.loading = true;
    this.query = q;
    this.cd.detectChanges();

    this.suspendSearch();

    if (!this.query) {
      this.clear(false);
      return;
    }

    this.searching = true;

    // Merge multiple searches
    this.subs.search = combineLatest([
      await this.searchHashtags(this.query),
      await this.searchProducts(this.query),
      await this.searchUsers(this.query),
    ]).subscribe((results) => {
      this.results = this.prepareResults(results);
      this.loading = false;
      this.cd.markForCheck();
    });

    this.subscription.add(this.subs.search);
  }

  /**
   * Query the api for products.
   */
  searchHashtags(query: string): Observable<HashtagModel[] | undefined> {
    return this.searchHashtagsGQL
      .fetch({ query: query, first: 5, includePostCount: true })
      .pipe(
        map(({ data }) => {
          return data?.hashtags?.data?.map((h: any) => new HashtagModel(h));
        })
      );
  }

  /**
   * Query the api for products.
   */
  searchProducts(query: string): Observable<ProductModel[] | undefined> {
    return this.searchProductsGQL.fetch({ query: query, first: 5 }).pipe(
      map(({ data }) => {
        return data?.products?.data?.map((p: any) => new ProductModel(p));
      })
    );
  }

  /**
   * Query the api for products.
   */
  searchUsers(query: string): Observable<UserModel[] | undefined> {
    return this.searchUsersGQL.fetch({ perPage: 10, query }).pipe(
      map(({ data }) => {
        return data?.users?.edges?.map((edge: any) => new UserModel(edge.node));
      })
    );
  }

  /**
   * Select the active result.
   */
  selectActive(event: any): void {
    if (!this.results) {
      return;
    }

    const activeIndex = this.results.findIndex((result: any) => result.active);
    const elements = this.resultElements?.toArray();
    let element;

    if (activeIndex >= 0) {
      element = elements?.[activeIndex];
    } else {
      element = elements?.[0];
    }

    if (element) {
      element.nativeElement.click();
    }

    this.clear();
  }

  /**
   * Set the searching state of the component.
   */
  setSearching(value: boolean): void {
    requestAnimationFrame(() => {
      if (!this.clicking) {
        this.searching = value;
        this.clicking = false;
        this.cd.markForCheck();
      }
    });
  }

  /**
   * Suggest a product.
   */
  suggest(): void {
    this.modalService.open("product-suggestion");
    this.onSelected();
  }

  /**
   * Suspend the search if it is running.
   */
  suspendSearch() {
    if (!this.subs.search) {
      return;
    }

    this.subscription.remove(this.subs.search);
    this.subs.search.unsubscribe();
  }

  /**
   * Method to track the results by.
   */
  trackBy(index: number, result: any): string {
    return result.type + "-" + result.data.id;
  }
}
