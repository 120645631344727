import * as Types from '../types';

import { gql } from 'apollo-angular';
import { NotificationFieldsFragmentDoc } from './notification-fields.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type NotificationCreatedSubscriptionVariables = Types.Exact<{
  user_id: Types.Scalars['ID'];
}>;


export type NotificationCreatedSubscription = { __typename?: 'Subscription', notificationCreated?: { __typename?: 'Notification', created_at?: any | null, data?: any | null, id?: string | null, read_at?: any | null, type?: string | null, sender?: { __typename?: 'User', id: string, name?: string | null, username?: string | null, media?: Array<{ __typename?: 'Media', id: string, type?: string | null, url?: string | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null } | null }> | null } | null, user?: { __typename?: 'User', id: string, name?: string | null, username?: string | null, media?: Array<{ __typename?: 'Media', id: string, type?: string | null, url?: string | null, sizes?: { __typename?: 'MediaSizes', id?: string | null, thumbnail?: string | null } | null }> | null } | null } | null };

export const NotificationCreatedDocument = gql`
    subscription NotificationCreated($user_id: ID!) {
  notificationCreated(user_id: $user_id) {
    ...NotificationFields
  }
}
    ${NotificationFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class NotificationCreatedGQL extends Apollo.Subscription<NotificationCreatedSubscription, NotificationCreatedSubscriptionVariables> {
    document = NotificationCreatedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }