import * as Types from '../types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SearchHashtagsQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  query?: Types.InputMaybe<Types.Scalars['String']>;
  includePostCount?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type SearchHashtagsQuery = { __typename?: 'Query', hashtags: { __typename?: 'HashtagPaginator', data: Array<{ __typename?: 'Hashtag', id: string, name?: string | null, posts_count?: number | null }> } };

export const SearchHashtagsDocument = gql`
    query SearchHashtags($first: Int = 15, $query: String, $includePostCount: Boolean = false) {
  hashtags(active: true, search: $query, first: $first) {
    data {
      id
      name
      posts_count @include(if: $includePostCount)
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchHashtagsGQL extends Apollo.Query<SearchHashtagsQuery, SearchHashtagsQueryVariables> {
    document = SearchHashtagsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }