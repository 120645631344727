import { HeaderMenuComponent } from "../header-menu/header-menu.component";
import { HeaderSearchDropdownComponent } from "../header-search-dropdown/header-search-dropdown.component";
import { HeaderSearchComponent } from "../header-search/header-search.component";
import { Component, ElementRef, ViewChild } from "@angular/core";
import {
  AuthenticationService,
  EventService,
  ViewPortService,
} from "rigshare-common";
import { RouterLink } from "@angular/router";
import { NgClass, NgIf } from "@angular/common";
@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    standalone: true,
    imports: [
        NgClass,
        RouterLink,
        HeaderSearchComponent,
        HeaderSearchDropdownComponent,
        NgIf,
        HeaderMenuComponent,
    ],
})
export class HeaderComponent {
  /**
   * The display state of having notifications.
   */
  get displayHasNotifications(): boolean {
    return (
      (this.menu?.notificationUnreadCount || 0) > 0 ||
      (this.menu?.messageUnreadCount || 0) > 0
    );
  }

  /**
   * The menu component.
   */
  @ViewChild(HeaderMenuComponent, { static: true }) menu?: HeaderMenuComponent;

  /**
   * The header search component.
   */
  @ViewChild(HeaderSearchComponent, { static: false })
  search?: HeaderSearchComponent;

  /**
   * The header search dropdown component.
   */
  @ViewChild(HeaderSearchDropdownComponent, { static: false })
  searchDropdown?: HeaderSearchDropdownComponent;

  /**
   * The toggler element.
   */
  @ViewChild("toggler", { static: false }) toggler?: ElementRef;

  /**
   * Create a new instance of the component.
   */
  constructor(
    public auth: AuthenticationService,
    public event: EventService,
    public viewport: ViewPortService
  ) {}

  /**
   * Action to perform when the logo is clicked.
   */
  clickLogo(): void {
    this.event.broadcast("refresh:soft");
    this.menu?.close();
  }
}
