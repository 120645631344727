import { isPlatformServer } from "@angular/common";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { CommonConfig, CookieStorageService } from "rigshare-common";
import { firstValueFrom, from, Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpClient,
  HttpResponse,
} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class XsrfInterceptor implements HttpInterceptor {
  /**
   * Create a new instance of the inteceptor.
   */
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject("RIGSHARE_COMMON_CONFIG") protected config: CommonConfig,
    protected cookieStorage: CookieStorageService,
    protected http: HttpClient
  ) {}

  /**
   * Intercept the out going request and ensure a XSRF token exists. If not,
   * request a new one before performing the request.
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // If the request is not to the API, do not intercept.
    if (
      request.url.startsWith(`${this.config.wordpressBaseUrl}/wp-json/wp/v2`) ||
      request.url.startsWith(
        `${this.config.wordpressBaseUrl}/wp-content/wp-json-static`
      )
    ) {
      return next.handle(
        request.clone({
          withCredentials: false,
        })
      );
    }

    if (isPlatformServer(this.platformId) && process?.env?.XSRF_SIGNATURE) {
      request = request.clone({
        headers: request.headers
          .set("X-XSRF-SIGNATURE", process.env.XSRF_SIGNATURE)
          // TODO: This needs to be dynamic
          .set("Origin", "https://rigshare.test:4200"),
        withCredentials: true,
      });

      return next.handle(request);
    }

    if (request.url.endsWith("sanctum/csrf-cookie")) {
      return next.handle(request);
    }

    return from(this.cookieStorage.get("XSRF-TOKEN"))
      .pipe(
        switchMap(async (token) => {
          if (token) {
            return token;
          }

          return await this.getXSRFToken();
        })
      )
      .pipe(
        switchMap((token) => this.requestWithXSRFToken(request, next, token))
      );
  }

  /**
   * Get the XSRF token before performing the request.
   */
  async getXSRFToken() {
    try {
      await this.cookieStorage.remove("XSRF-TOKEN");
      await firstValueFrom(this.http.get("sanctum/csrf-cookie"));
      let token = this.retrieveToken();

      if (token) {
        return token;
      }

      throw new Error("XSRF token could not be retrieved.");
    } catch (error) {
      // throw error;
    }
  }

  /**
   * Execute the request with the XSRF token.
   */
  requestWithXSRFToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: string
  ): Observable<HttpEvent<any>> {
    return next
      .handle(
        request.clone({
          headers: request.headers.set("X-XSRF-TOKEN", token),
        })
      )
      .pipe(
        catchError((response: HttpResponse<any>) => {
          if (response.status === 419) {
            return from(this.getXSRFToken()).pipe(
              switchMap((token) =>
                next.handle(
                  request.clone({
                    headers: request.headers.set("X-XSRF-TOKEN", token),
                  })
                )
              )
            );
          }

          return throwError(response);
        })
      );
  }

  /**
   * Retrieve getting the token a few times before giving up.
   */
  retrieveToken(): Promise<any | void> {
    const getToken = async (timeout: number): Promise<any> => {
      return new Promise(async (resolve) => {
        setTimeout(
          async () => resolve(await this.cookieStorage.get("XSRF-TOKEN")),
          timeout
        );
      });
    };

    return new Promise(async (resolve) => {
      let token;

      for (let i = 0; i < 3; i++) {
        token = await getToken(i * 50);

        if (token) {
          return resolve(token);
        }
      }

      resolve(false);
    });
  }
}
