import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf, NgClass } from '@angular/common';

@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        RouterLink,
        NgClass,
    ],
})
export class FooterComponent {
  /**
   * The current date.
   */
  date: Date = new Date();

  /**
   * The links of the footer menu.
   */
  menuLinks: any[] = [
    {
      text: "Discover",
      children: [
        {
          text: "Gear",
          route: "/gear",
        },
        {
          text: "Hashtags",
          route: "/hashtags",
        },
        {
          text: "Brands",
          route: "/brands",
        },
      ],
    },
    {
      text: "Company",
      children: [
        {
          text: "About",
          route: "/about",
        },
        {
          text: "Blog",
          route: "/blog",
        },
        {
          text: "Contact Us",
          route: "/contact",
        },
        {
          text: "Shop",
          href: "https://shop.rigshare.com",
        },
      ],
    },
    {
      text: "Communities",
      children: [
        {
          text: "For Musicians",
          route: "/rigshare-for-musicians",
        },
        {
          text: "For Brands",
          route: "/rigshare-for-brands",
        },
      ],
    },
    {
      text: "Follow Us",
      children: [
        {
          text: "Instagram",
          icon: "instagram",
          href: "https://instagram.com/rigshare",
          target: "_blank",
          rel: "noopener",
        },
        {
          text: "Twitter",
          icon: "twitter",
          href: "https://twitter.com/rigshare",
          target: "_blank",
          rel: "noopener",
        },
        {
          text: "Facebook",
          icon: "facebook",
          href: "https://facebook.com/rigshare",
          target: "_blank",
          rel: "noopener",
        },

        {
          text: "YouTube",
          icon: "youtube",
          href: "https://www.youtube.com/channel/UCxVb33dXyFeSZzgylB7AKUw",
          target: "_blank",
          rel: "noopener",
        },
      ],
    },
  ];
}
