import { authResolveGuard } from 'rigshare-common';

export const routes = [
  {
    path: "",
    canActivate: [authResolveGuard],
    loadComponent: () =>
      import("./pages/home/home.component").then((mod) => mod.HomeComponent),
  },
  {
    path: "about",
    loadComponent: () =>
      import("./pages/page/page.component").then((mod) => mod.PageComponent),
  },
  {
    path: "blog",
    loadChildren: () =>
      import("./pages/blog/blog.module").then((m) => m.BlogModule),
  },
  {
    path: "brands",
    loadComponent: () =>
      import("./pages/brands/brands.component").then((m) => m.BrandsComponent),
  },
  {
    path: "brands/:brandId",
    loadChildren: () =>
      import("./pages/brand/brand.module").then((m) => m.BrandModule),
  },
  {
    path: "cancellation-policies",
    loadComponent: () =>
      import("./pages/page/page.component").then((mod) => mod.PageComponent),
  },
  {
    path: "careers",
    loadComponent: () =>
      import("./pages/page/page.component").then((mod) => mod.PageComponent),
  },
  {
    path: "categories",
    loadChildren: () =>
      import("./pages/categories/categories.module").then(
        (m) => m.CategoriesModule
      ),
  },
  {
    path: "community-guidelines",
    loadComponent: () =>
      import("./pages/page/page.component").then((mod) => mod.PageComponent),
  },
  {
    path: "connect",
    loadChildren: () =>
      import("./pages/connect/connect.module").then((m) => m.ConnectModule),
  },
  {
    path: "contact",
    loadChildren: () =>
      import("./pages/contact/contact.module").then((m) => m.ContactModule),
  },
  {
    path: "email/verify",
    loadChildren: () =>
      import("./pages/email/verify/verify.module").then((m) => m.VerifyModule),
  },
  {
    path: "search",
    loadChildren: () =>
      import("./pages/search/search.module").then((m) => m.SearchModule),
  },
  {
    path: "forgot-password",
    loadChildren: () =>
      import("./pages/forgot-password/forgot-password.module").then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: "gear",
    loadChildren: () =>
      import("./pages/gear/gear.module").then((m) => m.GearModule),
  },
  {
    path: "hashtags",
    loadChildren: () =>
      import("./pages/hashtags/hashtags.module").then((m) => m.HashtagsModule),
  },
  {
    path: "invites/:id",
    loadChildren: () =>
      import("./pages/invite/invite.module").then((m) => m.InviteModule),
  },
  {
    path: "messages",
    loadChildren: () =>
      import("./pages/messages/messages.module").then((m) => m.MessagesModule),
  },
  {
    path: "modals",
    loadChildren: () =>
      import("./components/modal/modal.module").then((m) => m.ModalModule),
    outlet: "modal",
  },
  {
    path: "notifications",
    loadChildren: () =>
      import("./pages/notifications/notifications.module").then(
        (m) => m.NotificationsModule
      ),
  },
  {
    path: "posts/:postId",
    loadChildren: () =>
      import("./pages/post/post.module").then((m) => m.PostModule),
  },
  {
    path: "press",
    loadComponent: () =>
      import("./pages/page/page.component").then((mod) => mod.PageComponent),
  },
  {
    path: "privacy",
    loadComponent: () =>
      import("./pages/page/page.component").then((mod) => mod.PageComponent),
  },
  {
    path: "products/:productId",
    loadChildren: () =>
      import("./pages/product/product.module").then((m) => m.ProductModule),
  },
  {
    path: "products/:productId/musicians",
    loadChildren: () =>
      import("./pages/musicians/musicians.module").then(
        (m) => m.MusiciansModule
      ),
  },
  {
    path: "products/:productId/reviews",
    loadChildren: () =>
      import("./pages/reviews/reviews.module").then((m) => m.ReviewsModule),
  },
  {
    path: "products/:productId/reviews/:reviewId",
    loadChildren: () =>
      import("./pages/review/review.module").then((m) => m.ReviewModule),
  },
  {
    path: "products/:productId/videos",
    loadChildren: () =>
      import("./pages/videos/videos.module").then((m) => m.VideosModule),
  },
  {
    path: "reviews",
    loadChildren: () =>
      import("./pages/reviews/reviews.module").then((m) => m.ReviewsModule),
  },
  {
    path: "reviews/:reviewId",
    loadChildren: () =>
      import("./pages/review/review.module").then((m) => m.ReviewModule),
  },
  {
    path: "rigshare-for-artists",
    loadComponent: () =>
      import("./pages/page/page.component").then((mod) => mod.PageComponent),
  },
  {
    path: "rigshare-for-brands",
    loadComponent: () =>
      import("./pages/page/page.component").then((mod) => mod.PageComponent),
  },
  {
    path: "rigshare-for-musicians",
    loadComponent: () =>
      import("./pages/page/page.component").then((mod) => mod.PageComponent),
  },
  {
    path: "reset-password/:token",
    loadChildren: () =>
      import("./pages/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: "search",
    loadChildren: () =>
      import("./pages/search/search.module").then((m) => m.SearchModule),
  },
  {
    path: "sign-in",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "sign-up",
    loadChildren: () =>
      import("./pages/registration/registration.module").then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: "settings",
    loadChildren: () =>
      import("./pages/settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "sitemap",
    loadComponent: () =>
      import("./pages/page/page.component").then((mod) => mod.PageComponent),
  },
  {
    path: "terms",
    loadComponent: () =>
      import("./pages/page/page.component").then((mod) => mod.PageComponent),
  },
  {
    path: "trending",
    loadChildren: () =>
      import("./pages/trending/trending.module").then((m) => m.TrendingModule),
  },
  {
    path: "not-found",
    loadComponent: () =>
      import("./pages/not-found/not-found.component").then(
        (m) => m.NotFoundComponent
      ),
  },
  {
    path: ":username",
    loadChildren: () =>
      import("./pages/profile/profile.module").then((m) => m.ProfileModule),
  },
  {
    path: "**",
    loadComponent: () =>
      import("./pages/not-found/not-found.component").then(
        (m) => m.NotFoundComponent
      ),
  },
];
