import { version } from './version';

export const environment = {
  cloudflareToken: 'e3fce22628894401b41ad92a37a917f2',
  gaId: "G-3Z73GQ9Q94",
  platform: "web",
  production: true,
  staging: false,
  url: "https://rigshare.com",
  version: version,
  websocketUrl:
    "wss://jkg3mc4ds8.execute-api.us-east-1.amazonaws.com/production",
  wordpressBaseUrl: "https://wp.rigshare.com",
};

export const options: any = {
  authentication: {
    endpoints: {
      check: "auth/user",
      getUser: "auth/user",
      forgotPassword: "auth/password/email",
      resetPassword: "auth/password/reset",
      login: "auth/login",
      logout: "auth/logout",
      register: "auth/register",
    },
  },
  debug: false,
  events: {
    baseUrl: "https://live.rigshare.com",
  },
  http: {
    baseUrl: "https://api.rigshare.com",
    headers: {
      Accept: "application/json",
    },
  },
  storage: {
    name: "rigShareStorage",
  },
};
