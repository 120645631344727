import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: "app-badge,[appBadge]",
    templateUrl: "./badge.component.html",
    styles: [],
    standalone: true,
    imports: [NgClass],
})
export class BadgeComponent {
  /**
   * The classes that control the apperance of the badge.
   */
  classesMap: { [type: string]: string } = {
    circle: "rounded-full",
    rounded: "rounded",
    pill: "rounded-xl",
    primary: "bg-blue-500 text-white",
    secondary: "bg-neutral-600 text-white",
    dark: "bg-neutral-700 text-white",
    light: "bg-neutral-400 text-neutral-900",
    "x-small": "text-xs",
    small: "text-sm",
    medium: "text-base",
    large: "text-lg",
  };

  /**
   * How the badge should be rounded.
   */
  @Input() rounded: "none" | "circle" | "pill" | "rounded" = "rounded";

  /**
   * Return the style class for the badge.
   */
  styleClass: any;

  /**
   * The type of the badge.
   */
  @Input() size: "x-small" | "small" | "medium" | "large" = "medium";

  /**
   * The type of the badge.
   */
  @Input() type: "primary" | "secondary" | "dark" | "light" = "primary";

  /**
   * On component init.
   */
  ngOnInit() {
    this.setClasses();
  }

  /**
   * Set the classes of the component.
   */
  setClasses() {
    this.styleClass = {
      [this.classesMap[this.rounded]]: true,
      [this.classesMap[this.size]]: true,
      [this.classesMap[this.type || ""]]: this.type ? true : false,
    };
  }
}
