import { CommonConfig } from '../common-config';
import { isPlatformBrowser } from '@angular/common';
import {
  Inject,
  Injectable,
  OnDestroy,
  PLATFORM_ID
  } from '@angular/core';

@Injectable({
  providedIn: "root",
})
export class ChatService implements OnDestroy {
  /**
   * Create a new instance of the service.
   */
  constructor(
    @Inject("RIGSHARE_COMMON_CONFIG") protected config: CommonConfig,
    @Inject(PLATFORM_ID) private platformId: string
  ) {
    this.init();
  }

  /**
   * The intiialize state of the service.
   */
  initialized?: boolean;

  /**
   * The load promise of the service.
   */
  load?: Promise<void>;

  /**
   * On service destroy.
   */
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
    }
  }

  /**
   * Initialize the chat.
   */
  async init(): Promise<void> {
    this.load = new Promise(async (resolve) => {
      if (
        isPlatformBrowser(this.platformId) &&
        (this.config.environment.staging || this.config.environment.production)
      ) {
        this.initialized = true;
      }

      resolve();
    });
  }

  /**
   * Close the chat.
   */
  async close(): Promise<void> {
    await this.load;
  }

  /**
   * Fill the chat form.
   */
  async fill(data: any = {}): Promise<void> {
    await this.load;

    return;
  }

  /**
   * Identify the user of the chat.
   */
  async identify(): Promise<void> {
    await this.load;
  }

  /**
   * Clear the current session.
   */
  async logout(): Promise<void> {
    await this.load;
  }

  /**
   * Navigate to a page in the chat..
   */
  async navigate(route: string): Promise<void> {
    await this.load;
  }

  /**
   * Open the chat.
   */
  async open(): Promise<void> {}

  /**
   * Setup the chat service to report a problem.
   */
  async reportProblem(): Promise<void> {
    await this.fill({ subject: "Report a problem" });
    await this.open();
    await this.navigate("/ask/message");
  }

  /**
   * Toggle the chat.
   */
  async toggle(): Promise<void> {
    await this.load;
  }
}
