<div class="search-bar mb-0 transition relative z-10">
  <app-input
    autocomplete="off"
    type="text"
    #input
    (onKeydown)="onKeyDown($event)"
    (onKeyup)="onKeyUp($event)"
    [(ngModel)]="query"
    (onBlur)="onBlur()"
    (onFocus)="onFocus()"
    [class.has-text]="query && query.length"
  ></app-input>

  <button
    *ngIf="searching"
    class="px-3 py-2 clear text-neutral-600 outline-none"
    (click)="clear()"
    aria-label="Close"
  >
    <i class="app-icon-close align-middle"></i>
  </button>

  <span
    class="placeholder pointer-events-none"
    [ngClass]="{
      'align-middle': !searching && !query,
      'align-left !w-auto left-3': searching || query
    }"
    (click)="focus($event)"
  >
    <i class="app-icon-search align-middle mr-1"></i>
    <span
      *ngIf="!query"
      class="text-neutral-600"
      [class.text-neutral-500]="searching"
    >
      {{ searching && large ? "Search hashtags, users & products" : "Search" }}
    </span>
  </span>
</div>
